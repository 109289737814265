import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FreightQuotaContentSchedulesDTM } from 'monetary/models/dtm';
import { CustomModal } from 'shipment-operations/view/components';
import { EmptyStateForPages } from 'app-wrapper/view/components';

import {
  Footer,
  Header,
  RollOption,
} from './components';
import {
  Content,
  Warning,
  WarningIcon,
  WarningText,
  Spinner,
  EmptyImage,
} from './RollShipmentDrawer.styled';
import noContentSRC from './no-content.png';

interface RollShipmentDrawerComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  init: (shipmentId: string, isOpen: boolean) => void;
  onClear: () => void;
  shipmentId: string;
  errorMessage: string;
  schedules: FreightQuotaContentSchedulesDTM[];
}

export const RollShipmentDrawerComponent: FC<RollShipmentDrawerComponentProps> = ({
  isOpen,
  isLoading,
  onClose,
  onClear,
  shipmentId,
  init,
  errorMessage,
  schedules,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init(shipmentId, isOpen);

    return () => {
      onClear();
    };
  }, [shipmentId, isOpen]);

  return isOpen ? (
    <CustomModal
      onClose={onClose}
      title={t('Roll Shipment')}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Content>
            {errorMessage ? (
              <Warning>
                <WarningIcon />
                <WarningText>
                  {errorMessage}
                </WarningText>
              </Warning>
            ) : null}

            <Header />

            {schedules.length ? (
              <>
                {schedules.map((schedule) => (
                  <RollOption key={`RollShipmentDrawerComponent_${schedule.id}`} scheduleId={Number(schedule.id)} />
                ))}
              </>
            ) : (
              <EmptyStateForPages
                primaryIcon={<EmptyImage src={noContentSRC} alt="" />}
                headerText={t('Unfortunately, there are no other schedules available at this moment.')}
              />
            )}
          </Content>

          <Footer />
        </>
      )}
    </CustomModal>
  ) : null;
};
