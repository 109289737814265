import {
  IsOptional, IsString, IsEnum, IsDefined,
} from 'class-validator';
import { Type } from 'class-transformer';

import { DateDtm, IDateDTM } from 'app-wrapper/models/dtm';
import { ELocationType } from 'app-wrapper/types/LocationType';
import { BaseDTM } from 'proto/BaseDTM';

export interface IWaypointDTM {
  name: string | null
  address: string
  countryCode?: string
  estimatedDate?: IDateDTM
  realDate?: IDateDTM
  type: ELocationType
  code?: string
  city?: string;
  countryName?: string;
}

export class WaypointDTM extends BaseDTM<IWaypointDTM> {
  @IsString()
  @IsOptional()
  name: string | null;

  @IsString()
  @IsDefined()
  address: string;

  @IsString()
  @IsOptional()
  countryCode: string;

  @IsOptional()
  @Type(() => DateDtm)
  estimatedDate?: DateDtm;

  @IsOptional()
  @Type(() => DateDtm)
  realDate?: DateDtm;

  @IsDefined()
  @IsEnum(ELocationType)
  type: ELocationType;

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  countryName?: string;

  utcOffset() {
    return this.estimatedDate?.offset;
  }
}
