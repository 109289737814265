import React, { memo } from 'react';

import {
  InfoCardContainer,
  InfoCardData,
  InfoCardIconBG,
  InfoCardTitle,
  InfoCardValue,
} from './InfoCard.styled';

interface InfoCardComponentProps {
  title: string | React.ReactElement
  value: string | React.ReactElement
  icon?: React.ReactElement
  isTextBigger?: boolean;
}

export const InfoCardComponent = memo(({
  title,
  value,
  icon,
  isTextBigger,
}: InfoCardComponentProps) => (
  <InfoCardContainer>
    { icon ? (
      <InfoCardIconBG>
        {icon}
      </InfoCardIconBG>
    )
      : null }
    <InfoCardData>
      <InfoCardTitle>{title}</InfoCardTitle>
      <InfoCardValue isBig={isTextBigger}>{value}</InfoCardValue>
    </InfoCardData>
  </InfoCardContainer>
));
