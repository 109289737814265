import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { ContainersTableComponent } from './ContainersTable.component';

const ContainersTableContainer = ({ isCommon = false }: { isCommon?: boolean }) => {
  const isLoading = useSelector(R.selectors.shipmentTracker.getIsLoadingPage);
  const isLoadingData = useSelector(R.selectors.shipmentTracker.getIsLoading);
  const error = useSelector(R.selectors.shipmentTracker.getIsError);
  const containers = useSelector(R.selectors.shipmentTracker.getContainersForTable);
  const isUpdatedDates = useSelector(R.selectors.shipmentTracker.getIsUpdatedDates);
  const isLoadingUpdate = useSelector(R.selectors.shipmentTracker.getIsLoadingUpdate);
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);
  const showUntrackedWarning = useSelector(R.selectors.shipmentTracker.getIsShowUntrackedWarning);
  const isDrayageShipment = useSelector(R.selectors.shipment.getIsDrayageShipment);

  return (
    <ContainersTableComponent
      isCommon={isCommon}
      isLoading={isLoading || isLoadingData}
      error={error}
      containers={containers}
      fetchData={UC.shipmentTracker.loadContainers}
      onChangeDate={UC.shipmentTracker.onChangeDate}
      onResetChanges={UC.shipmentTracker.onResetChanges}
      onPostDates={UC.shipmentTracker.onPostDates}
      isUpdatedDates={isUpdatedDates}
      isLoadingUpdate={isLoadingUpdate}
      trackerAvailability={permissions.trackerAvailability}
      trackerWarningsAvailability={permissions.trackerWarningsAvailability}
      openLinkContainer={appUC.drawer.openLinkContainer}
      onUnlinkContainer={UC.shipmentTracker.onUnlinkContainer}
      showUntrackedWarning={showUntrackedWarning}
      isDrayage={isDrayageShipment}
    />
  );
};

export {
  ContainersTableContainer as ContainersTable,
};
