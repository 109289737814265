import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const PaymentsTradeStatusWrap = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusWrap',
}))`
  ${divFlex}

  margin-left: 10px;
`;

export const PaymentsTradeStatusContent = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusContent',
}))`
  ${divFlex}
  align-items: center;

  padding: 0 8px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1Dot5};
`;

export const PaymentsTradeStatusItem = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusItem',
}))`
  ${divFlex}
  align-items: center;
`;

export const PaymentsTradeStatusItemIcon = styled.div.attrs((props: {
  isActive: boolean
  svgColor: string
  'data-class'?: string
}) => ({
  isActive: props?.isActive,
  svgColor: props?.svgColor,
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusItem',
}))`
  ${divFlex}
  align-items: center;

  margin-left: 4px;
  svg {
    color: ${({ theme, isActive }) => (!isActive ? theme?.themesColors?.neutralBranding6 : theme?.themesColors?.secondaryDot45)};
    ${({ svgColor }) => svgColor && `color: ${svgColor}`};
  }
`;

export const PaymentsTradeStatusItemText = styled.div.attrs((props: {
  isActive: boolean
  'data-class'?: string
}) => ({
  isActive: props?.isActive,
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusItem',
}))`
  ${divFlex}
  align-items: center;

  margin-left: 4px;
  color: ${({ theme, isActive }) => (!isActive ? theme?.themesColors?.neutralBranding6 : theme?.themesColors?.secondaryDot45)};
  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
`;
