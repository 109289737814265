import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { normalizationCost, validateDecimalFraction } from 'app-wrapper/utils';

import { WaypointDTM } from 'shipment-operations/models/dtm';

import { getCarrierSCACNamesRFQ } from 'monetary/constants';

import {
  Container,
  ContainerWrap,
  Actions,
  Button,
  CarrierInfo,
  CarrierInfoRow,
  CarrierShipIcon,
  Text,
  DividerText,
  TextBold,
  TextGrey,
  DestinationInfo,
  DestinationInfoRow,
  DestinationInfoCol,
  DividerIcon,
  ShipmentTotalInfo,
  ShipmentTotalInfoRow,
  ShipmentTotalItem,
  WeightIcon,
  NavigatorIcon,
  GorizontalMenuIcon,
} from './Footer.styled';

interface FooterComponentProps {
  voyageDuration: string;
  currentStep: number;
  origin: WaypointDTM | null;
  destination: WaypointDTM | null;
  agreeAndBook: () => void;
  goSecondStep: () => void;
  containersAmount: number;
  totalWeight: number;
  totalVolume: number;
  totalCost: string;
  nameSCAC: string;
  isLoading: boolean;
  isAgreeAndBookDisabled: boolean;
  isContentUpdating: boolean;
  goBookingDetailsStep: () => void;
  goPickupAndDeliveryStep: () => void;
  saveSuppliers: () => void;
}

const formatNumber = (amount: number) => {
  if (Number.isNaN(amount)) {
    return 0;
  }

  const formatted = String(amount.toFixed(3));
  const [unit, divisional] = formatted.split('.');
  const [divisional1, divisional2, divisional3] = divisional;
  const numericDivisional = Number(`${divisional1}${divisional2}${divisional3}`);

  return numericDivisional ? `${Number(unit)}.${divisional}` : unit;
};

export const FooterComponent: FC<FooterComponentProps> = ({
  currentStep,
  voyageDuration,
  origin,
  destination,
  totalWeight,
  totalVolume,
  totalCost,
  nameSCAC,
  isLoading,
  containersAmount,
  isContentUpdating,
  goBookingDetailsStep,
  goPickupAndDeliveryStep,
  isAgreeAndBookDisabled,
  agreeAndBook,
  goSecondStep,
  saveSuppliers,
}) => {
  const { t } = useTranslation();

  const originName = useMemo(() => (origin ? origin.address : ''), [origin]);
  const originDate = useMemo(() => (origin && origin.estimatedDate ? origin.estimatedDate.getFormatDMMM() : ''), [origin]);
  const destinationName = useMemo(() => (destination ? destination.address : ''), [destination]);
  const destinationDate = useMemo(() => (destination && destination.estimatedDate ? destination.estimatedDate.getFormatDMMM() : ''), [destination]);

  const nameTitle: string = getCarrierSCACNamesRFQ(nameSCAC);

  return (
    <Container>
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          <CarrierInfo>
            <CarrierInfoRow>
              <TextBold>
                {nameTitle}
              </TextBold>

              <DividerText>
                /
              </DividerText>

              <TextBold>
                $ {
                  normalizationCost(validateDecimalFraction(`${Number(totalCost || 0)}`, 15, 2, { forcePrecision: true }), {
                    toFixed: 2,
                    thousandthSeparatorComma: true,
                  })
                }
              </TextBold>
            </CarrierInfoRow>

            <CarrierInfoRow>
              <CarrierShipIcon />

              <TextGrey>
                {t('CY-CY')}
                {t(' ')}
                {t('FCL')}
                {t(' ')}
                {voyageDuration}
              </TextGrey>
            </CarrierInfoRow>
          </CarrierInfo>

          <DestinationInfo>
            <DestinationInfoCol>
              <Text>{originName}</Text>

              <DestinationInfoRow>
                <TextGrey>
                  {t('ETD')}
                </TextGrey>

                <Text
                  style={{
                    marginLeft: '4px',
                  }}
                >
                  {originDate}
                </Text>
              </DestinationInfoRow>
            </DestinationInfoCol>

            <DividerIcon />

            <DestinationInfoCol>
              <Text>{destinationName}</Text>

              <DestinationInfoRow>
                <TextGrey>
                  {t('ETA')}
                </TextGrey>

                <Text
                  style={{
                    marginLeft: '4px',
                  }}
                >
                  {destinationDate}
                </Text>
              </DestinationInfoRow>
            </DestinationInfoCol>
          </DestinationInfo>

          <ShipmentTotalInfo>
            <Text>
              {t('Shipment Total')}
            </Text>

            <ShipmentTotalInfoRow>
              <ShipmentTotalItem>
                <GorizontalMenuIcon />

                <TextGrey>
                  × {containersAmount} cont
                </TextGrey>
              </ShipmentTotalItem>

              <ShipmentTotalItem>
                <WeightIcon />

                <TextGrey>
                  {formatNumber(totalWeight)} kg
                </TextGrey>
              </ShipmentTotalItem>

              <ShipmentTotalItem>
                <NavigatorIcon />

                <TextGrey>
                  {formatNumber(totalVolume)} cbm
                </TextGrey>
              </ShipmentTotalItem>
            </ShipmentTotalInfoRow>
          </ShipmentTotalInfo>

          <Actions>
            {currentStep === 1 ? (
              <Button disabled={isContentUpdating} onClick={goPickupAndDeliveryStep}>
                {t('Next')}
              </Button>
            ) : null}

            {currentStep === 2 ? (
              <>
                <Button
                  onClick={goBookingDetailsStep}
                  type="default"
                >
                  {t('Back')}
                </Button>

                <Button onClick={saveSuppliers}>
                  {t('Next')}
                </Button>
              </>
            ) : null}

            {currentStep === 3 ? (
              <>
                <Button
                  onClick={goSecondStep}
                  type="default"
                >
                  {t('Back')}
                </Button>

                <Button disabled={isContentUpdating || isAgreeAndBookDisabled} onClick={agreeAndBook}>
                  {t('Agree and Book')}
                </Button>
              </>
            ) : null}
          </Actions>
        </ContainerWrap>
      )}
    </Container>
  );
};
