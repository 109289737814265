import { RouteNames } from 'app-wrapper/constants';

interface IGetKeyMenuByPathOptionsAltPath {
  originalUrl: string;
  alternativeUrl: string;
  hasId?: boolean;
}

interface IGetKeyMenuByPathOptions {
  altPath?: IGetKeyMenuByPathOptionsAltPath[];
}

export const getKeyMenuByPath = (path: string, options?: IGetKeyMenuByPathOptions): string => {
  const {
    altPath,
  } = options || {};
  const getIncludesCustomersPage = (value: string) => {
    const { length } = RouteNames.CUSTOMERS_INTERNAL(0);

    return value && value.includes(RouteNames.CUSTOMERS_INTERNAL(0).slice(0, length - 2)) ? value : '';
  };

  const getIncludesCustomQuoteByIdPage = (value: string) => {
    const { length } = RouteNames.CUSTOM_QUOTE_REQUEST(0);

    return value && value.includes(RouteNames.CUSTOM_QUOTE().slice(0, length - 2)) ? value : '';
  };

  const getIncludesRFRQuoteByIdPage = (value: string) => {
    const { length } = RouteNames.RATES_FREIGHT_BY_ID(0);

    return value && value.includes(RouteNames.RATES_FREIGHT_LIST().slice(0, length - 2)) ? value : '';
  };

  const getIncludesAlternativePath = (defaultPath: string, altValues?: IGetKeyMenuByPathOptionsAltPath[]) => {
    let newPath = defaultPath;

    altValues?.forEach(({ originalUrl, alternativeUrl, hasId }) => {
      if (originalUrl !== defaultPath) {
        return;
      }

      if (hasId) {
        const { length } = alternativeUrl;

        newPath = path && path.includes(originalUrl.slice(0, length - 2)) ? path : newPath;
        return;
      }

      newPath = alternativeUrl && alternativeUrl.includes(path) ? path : newPath;
    });

    return newPath;
  };

  switch (path) {
    case RouteNames.OVERVIEW():
      return '1';
    case RouteNames.COMMAND_CENTER():
      return '2';
    case getIncludesAlternativePath(RouteNames.COMMAND_CENTER_TASKS(), altPath):
      return '2_1';
    case getIncludesAlternativePath(RouteNames.COMMAND_CENTER_TASKS_ALERTS(), altPath):
      return '2_2';
    case RouteNames.COMMAND_CENTER_NOTIFICATION():
      return '2_3';
    case RouteNames.FREIGHT_QUOTE():
      return 'sub1_1';
    case RouteNames.ALL_REQUESTED_QUOTES():
      return 'sub1_2';
    case RouteNames.CUSTOM_QUOTE():
      return 'sub1_3';
    case getIncludesCustomQuoteByIdPage(path):
      return 'sub1_3';
    case RouteNames.HISTORY_REQUESTED_QUOTES():
      return 'sub1_4';
    case RouteNames.SHIPMENTS():
      return '3';
    case RouteNames.SHIPMENTS_ACTIVE():
      return '3_2';
    case RouteNames.SHIPMENTS_COMPLETED():
      return '3_3';
    case RouteNames.SHIPMENTS_CANCELLED():
      return '3_4';
    case RouteNames.SHIPMENTS_WAITING_APPROVAL():
      return '3_5';
    case RouteNames.BOOKING_NEW():
      return 'sub3_1';
    case RouteNames.CONTACT_BOOK():
      return '4';
    case RouteNames.CUSTOMERS():
      return '6';
    case getIncludesCustomersPage(path):
      return '6';
    case RouteNames.FINANCES_INVOICES():
      return 'sub5_1_sub1_1';
    case RouteNames.FINANCES_AP_INVOICES():
      return 'sub5_1_sub1_3';
    case RouteNames.FINANCES_PAYMENTS():
      return 'sub5_1_sub1_2';
    case RouteNames.FINANCES_AP_PAYMENTS():
      return 'sub5_1_sub1_4';
    case RouteNames.RATES_UPLOAD():
      return 'sub2_1';
    case RouteNames.CONTRACTS():
      return 'sub2_3';
    case getIncludesRFRQuoteByIdPage(path):
      return 'sub2_2';
    default:
      return '1';
  }
};
