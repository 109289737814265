import { paths } from 'api-contracts/dev/task-service/v1/schema';
import { ENotificationCriticality, ENotificationDomain, ENotificationStatus } from 'app-wrapper/constants';
import {
  ICommandCenterTasksFiltersResponse,
  ICommandCenterTasksResponse,
  IGetCommandCenterTasksAllStatsContract,
  IShipmentCostChangeTaskResponse,
} from 'app-wrapper/models/contracts';
import {
  ICommandCenterNotificationsResponse,
  ICommandCenterNotificationsViewedResponse,
} from 'app-wrapper/models/contracts/commandCenterNotifications.contract';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM,
  CommandCenterGetTasksDTM,
  DateDtm,
  INotificationTaskContentDTM,
  NotificationTaskContentActionsDTM,
  NotificationTaskContentAssigneeDTM,
  NotificationTaskContentDTM,
  NotificationTaskContentViewedDTM,
  NotificationTaskDTM,
  ShipmentCostChangeTaskMetadataDTM,
  TasksFiltersDTM,
  TasksFiltersOrganizationDTM,
} from 'app-wrapper/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { ServerError } from 'app-wrapper/types/ServerError';
import { AxiosError } from 'axios';
import moment from 'moment';
import { ETasksType } from 'shipment-operations/constants';

import { ShipmentAllStatsDTM } from 'shipment-operations/models/dtm';
import { v4 as uuidv4 } from 'uuid';

export class CommandCenterService {
  private baseUrl = '/task-service/api/v1/tasks';

  private baseUrlNotifications = '/task-service/api/v1/notifications';

  public getShipmentCostChangesTaskMetadataById = async (taskId: string): Promise<ShipmentCostChangeTaskMetadataDTM | null> => {
    const responseRaw = await apiWorker.requestGet<IShipmentCostChangeTaskResponse>(`${this.baseUrl}/${taskId}`);
    const { metadata } = responseRaw.data;

    if (metadata?.taskType !== ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK) {
      return null;
    }

    return ShipmentCostChangeTaskMetadataDTM.fromPlain({
      ...metadata,
      taskType: metadata?.taskType || '',
      shipmentId: metadata?.shipmentId || '',
      shipmentName: metadata?.shipmentName || '',
      chargeChanges: JSON.parse(metadata.chargeChanges),
    });
  }

  public getOrganizationStats = async (params: CommandCenterGetTasksDTM) => {
    let result: ShipmentAllStatsDTM | undefined;

    if (params.dueDateTo === 'undefined' || params.dueDateFrom === 'undefined') {
      return result;
    }

    const response = await apiWorker.requestGet<IGetCommandCenterTasksAllStatsContract>('/task-service/api/v1/organizations/current/stats', {
      params: {
        page: params.page,
        size: params.size,
        status: params.query,
        criticality: params.criticality?.join(','),
        sort: params.sort,
        includeEmptyDueDate: params.includeEmptyDueDate,
        'dueDate.to': params.dueDateTo,
        'dueDate.from': params.dueDateFrom,
        'completedAt.from': params.completedAtFrom,
        'completedAt.to': params.completedAtTo,
        'metadata[shipmentId]': params.shipmentId,
        domains: params.domain?.trim(),
        'targetAudience.organizationIds': params?.targetAudienceOrganizationIds?.join(',') || undefined,
        'targetAudience.userEmails': params?.targetAudienceUserEmails?.join(',') || undefined,
        'objectReference.organizationIds': params?.objectReferenceOrganizationIds?.join(',') || undefined,
        'objectReference.paymentIds': params?.objectReferencePaymentIds?.join(',') || undefined,
        'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds?.join(',') || undefined,
        'objectReference.shipmentIds': params?.objectReferenceShipmentIds?.join(',') || undefined,
        'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
        'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
      },
    }, true);

    const { data } = response;

    return ShipmentAllStatsDTM.fromPlain({
      objectReference: data.objectReference && {
        id: data.objectReference.id,
        type: data.objectReference.type,
        shipmentId: data.objectReference.shipmentId,
      },
      taskStats: {
        todo: data?.taskStats.todo,
        done: data?.taskStats.done,
        expiring: data?.taskStats.expiring,
        overdue: data?.taskStats.overdue,
        alerts: data?.taskStats.alerts,
        highTodo: data?.taskStats.highTodo,
        highExpiring: data?.taskStats.highExpiring,
        highOverdue: data?.taskStats.highOverdue,
      },
      notificationStats: {
        unread: data?.notificationStats.unread,
        read: data?.notificationStats.read,
        unreadCritical: data?.notificationStats.unreadCritical,
        unreadRegular: data?.notificationStats.unreadRegular,
      },
    });
  }

  public getTasks = async (params: CommandCenterGetTasksDTM) => {
    let result: NotificationTaskDTM | null = null;
    let response: ICommandCenterTasksResponse | null = null;

    if (params.dueDateTo === 'undefined' || params.dueDateFrom === 'undefined') {
      return result;
    }

    try {
      const rawResponse = await apiWorker.requestGet<ICommandCenterTasksResponse>(`${this.baseUrl}`, {
        params: {
          page: params.page,
          size: params.size,
          status: params.query,
          criticality: params.criticality?.join(','),
          sort: params.sort,
          includeEmptyDueDate: params.includeEmptyDueDate,
          'dueDate.to': params.dueDateTo,
          'dueDate.from': params.dueDateFrom,
          'completedAt.from': params.completedAtFrom,
          'completedAt.to': params.completedAtTo,
          'metadata[shipmentId]': params.shipmentId,
          domains: params.domain?.trim(),
          'targetAudience.organizationIds': params?.targetAudienceOrganizationIds?.join(',') || undefined,
          'targetAudience.userEmails': params?.targetAudienceUserEmails?.join(',') || undefined,
          'objectReference.organizationIds': params?.objectReferenceOrganizationIds?.join(',') || undefined,
          'objectReference.paymentIds': params?.objectReferencePaymentIds?.join(',') || undefined,
          'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds?.join(',') || undefined,
          'objectReference.shipmentIds': params?.objectReferenceShipmentIds?.join(',') || undefined,
          'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
          'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
          'assignee.userAssigned': params?.assigneeUserAssigned || undefined,
        },
      });

      response = rawResponse.data;

      if (response) {
        result = NotificationTaskDTM.fromPlain({
          totalPages: response.totalPages || undefined,
          totalElements: response.totalElements || undefined,
          content: response.content.map((item) => NotificationTaskContentDTM.fromPlain({
            id: `${item.id}` || undefined,
            customId: uuidv4(),
            createdAt: item.createdAt ? DateDtm.fromPlain({
              date: item.createdAt,
              offset: moment.parseZone(item.createdAt).utcOffset(),
            }) : undefined,
            completedAt: item.completedAt ? DateDtm.fromPlain({
              date: item.completedAt,
              offset: moment.parseZone(item.completedAt).utcOffset(),
            }) : undefined,
            createdBy: item.createdBy || undefined,
            title: item.title,
            description: item.description || undefined,
            criticality: item.criticality,
            status: item.status,
            domain: item.domain || undefined,
            dueDate: item.dueDate ? DateDtm.fromPlain({
              date: item.dueDate,
              offset: moment.parseZone(item.dueDate).utcOffset(),
            }) : undefined,
            assignee: {
              type: item?.assignee?.type || undefined,
              sub: item?.assignee?.user?.sub || undefined,
              enabled: item?.assignee?.user?.enabled || undefined,
              email: item?.assignee?.user?.email || undefined,
              phone: item?.assignee?.user?.phone || undefined,
              firstName: item?.assignee?.user?.firstName || undefined,
              lastName: item?.assignee?.user?.lastName || undefined,
            },
            assigneeOrganizationId: item?.assignee?.organization?.id,
            assigneeOrganizationName: item?.assignee?.organization?.name,
            assigneeUsers: item?.assignee?.users?.map((itemUser) => NotificationTaskContentAssigneeDTM.fromPlain({
              sub: itemUser?.sub || undefined,
              enabled: itemUser?.enabled || undefined,
              email: itemUser?.email || undefined,
              phone: itemUser?.phone || undefined,
              firstName: itemUser?.firstName || undefined,
              lastName: itemUser?.lastName || undefined,
            })),
            organizationId: item.organizationId || undefined,
            objectReference: {
              id: item?.objectReference?.id || undefined,
              shipmentId: item?.objectReference?.shipmentId || undefined,
              rateRequestId: item?.objectReference?.rateRequestId || undefined,
              organizationId: item?.objectReference?.organizationId || undefined,
              type: item?.objectReference?.type || undefined,
            },
            targetAudience: {
              type: item?.targetAudience?.type || undefined,
              id: item?.targetAudience?.id || undefined,
              organizationIds: item?.targetAudience?.organizationIds || undefined,
              organizationId: item?.targetAudience?.organizationId || undefined,
            },
            actions: item.actions?.map((itemAction) => NotificationTaskContentActionsDTM.fromPlain({
              type: itemAction?.type || undefined,
              id: itemAction?.id || undefined,
              title: itemAction?.title,
              link: itemAction?.link || undefined,
            })),
            metadata: {
              taskType: item?.metadata?.taskType || undefined,
              shipmentId: item?.metadata?.shipmentId || undefined,
              shipmentName: item?.metadata?.shipmentName || undefined,
            },
          })),
        });
      }

      const errors = result?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ: error', errors, result);
      }
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw new Error('CommandCenterService: getTasks');
      }

      if (error?.response?.data?.message) {
        throw error?.response?.data?.message;
      }
      if (error?.response?.data?.details) {
        throw error?.response?.data?.details[0];
      }

      throw e;
    }

    return result;
  }

  async postTask(params: paths['/api/v1/tasks']['post']['requestBody']['content']['application/json']) {
    const rawResponse = await apiWorker.requestPostBySchema(`${this.baseUrl}` as '/api/v1/tasks', params);

    const response = rawResponse.data;

    return NotificationTaskContentDTM.fromPlain({
      ...response,
      id: String(response.id),
      createdAt: response.createdAt ? DateDtm.fromPlain(response.createdAt) : undefined,
      completedAt: response.completedAt ? DateDtm.fromPlain(response.completedAt) : undefined,
      criticality: response.criticality as ENotificationCriticality,
      status: response.status as ENotificationStatus,
      domain: response.domain as ENotificationDomain,
      dueDate: response.dueDate ? DateDtm.fromPlain(response.dueDate) : undefined,
    });
  }

  public getTasksFilters = async (params?: CommandCenterGetTasksDTM) => {
    let result: TasksFiltersDTM | undefined;
    let response: ICommandCenterTasksFiltersResponse | undefined;

    const rawResponse = await apiWorker.requestGet<ICommandCenterTasksFiltersResponse>(`${this.baseUrl}/filters`, {
      params: params && {
        page: params.page,
        size: params.size,
        status: params.query,
        sort: params.sort,
        includeEmptyDueDate: params.includeEmptyDueDate,
        criticality: params.criticality?.join(','),
        'dueDate.to': params.dueDateTo,
        'dueDate.from': params.dueDateFrom,
        'metadata[shipmentId]': params.shipmentId,
        domains: params.domain?.trim(),
        'targetAudience.organizationIds': params?.targetAudienceOrganizationIds,
        'targetAudience.userEmails': params?.targetAudienceUserEmails,
        'objectReference.organizationIds': params?.objectReferenceOrganizationIds,
        'objectReference.paymentIds': params?.objectReferencePaymentIds,
        'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds,
        'objectReference.shipmentIds': params?.objectReferenceShipmentIds,
        'assignee.organizationIds': params?.assigneeOrganizationIds,
        'assignee.userEmails': params?.assigneeUserEmails,
      },
    });

    if (rawResponse.data) {
      response = rawResponse.data;
    }

    if (response) {
      result = TasksFiltersDTM.fromPlain({
        createdAt: {
          from: response?.createdAt?.from,
          to: response?.createdAt?.to,
        },
        completedAt: {
          from: response.createdAt.from,
          to: response.createdAt.to,
        },
        criticality: response.criticality || [],
        domains: response.domains || [],
        dueDate: {
          from: response.createdAt.from,
          to: response.createdAt.to,
        },
        targetAudience: {
          organizations: response?.targetAudience?.organizations?.map((item) => TasksFiltersOrganizationDTM.fromPlain(item)),
          users: response?.targetAudience?.users?.map((item) => NotificationTaskContentAssigneeDTM.fromPlain(item)),
        },
        objectReference: {
          organizations: response?.objectReference?.organizations?.map((item) => TasksFiltersOrganizationDTM.fromPlain(item)),
          paymentIds: response?.objectReference?.paymentIds || [],
          rateRequestIds: response?.objectReference?.rateRequestIds || [],
          shipmentIds: response?.objectReference?.shipmentIds || [],
        },
        assignee: {
          organizations: response?.assignee?.organizations?.map((item) => TasksFiltersOrganizationDTM.fromPlain(item)),
          users: response?.assignee?.users?.map((item) => NotificationTaskContentAssigneeDTM.fromPlain(item)),
        },
      });
    }

    const errors = result?.validate();

    if (errors?.length) {
      console.error('DTM valid RFQ: error', errors, result);
    }

    return result;
  }

  public putTask = async (task: INotificationTaskContentDTM) => {
    const requestBody = {
      id: `${task.id}` || undefined,
      title: task.title,
      description: task.description || undefined,
      criticality: task.criticality,
      domain: task.domain || undefined,
      organizationId: task.organizationId || undefined,
      view: !!task.view || undefined,
      status: task.status,
      dueDate: task.dueDate?.getDateISO(),
      objectReference: {
        id: task?.objectReference?.id || undefined,
        shipmentId: task?.objectReference?.shipmentId || undefined,
        type: task?.objectReference?.type || undefined,
      },
      targetAudience: {
        type: task?.targetAudience?.type || undefined,
        id: task?.targetAudience?.id || undefined,
        organizationIds: task?.targetAudience?.organizationIds || undefined,
      },
      actions: task.actions?.map((itemAction) => NotificationTaskContentActionsDTM.fromPlain({
        type: itemAction?.type || undefined,
        id: itemAction?.id || undefined,
        title: itemAction?.title,
        link: itemAction?.link,
      })),
      metadata: {
        taskType: task?.metadata?.taskType || undefined,
        shipmentId: task?.metadata?.shipmentId || undefined,
      },
      assignee: task?.assignee ? {
        type: task?.assignee.type,
        users: task?.assigneeUsers,
        organization: {
          id: task.assigneeOrganizationId,
          name: task.assigneeOrganizationName,
        },
      } : undefined,
    };

    await apiWorker.requestPut(`${this.baseUrl}/${task.id}`, requestBody);
  }

  public getNotifications = async (params: CommandCenterGetCommandCenterGetNotificationsDTM) => {
    let result: NotificationTaskDTM | null = null;
    let response: ICommandCenterNotificationsResponse | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<ICommandCenterNotificationsResponse>(`${this.baseUrlNotifications}`, {
        params: {
          page: params.page,
          size: params.size,
          status: params.query,
          sort: params.sort,
          'dueDate.to': params.dueDateTo,
          'dueDate.from': params.dueDateFrom,
          'createdAt.to': params.createdAtTo,
          'createdAt.from': params.createdAtFrom,
          criticality: params?.criticality?.join(',') || undefined,
          viewed: params.viewed,
          query: params.query,
          domains: params.domain,
          'metadata[shipmentId]': params.shipmentId,
          'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
          'targetAudience.organizationIds': params?.assigneeAudienceOrganizationIds?.join(',') || undefined,
          'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
        },
      });

      response = rawResponse.data;

      if (response) {
        result = NotificationTaskDTM.fromPlain({
          totalPages: response.totalPages || undefined,
          totalElements: response.totalElements || undefined,
          content: response.content.map((item) => NotificationTaskContentDTM.fromPlain({
            id: `${item.id}` || undefined,
            customId: uuidv4(),
            createdAt: item.createdAt ? DateDtm.fromPlain({
              date: item.createdAt,
              offset: moment.parseZone(item.createdAt).utcOffset(),
            }) : undefined,
            createdBy: item.createdBy || undefined,
            title: item.title,
            description: item.description || undefined,
            criticality: item.criticality,
            domain: item.domain || undefined,
            dueDate: item.dueDate ? DateDtm.fromPlain({
              date: item.dueDate,
              offset: moment.parseZone(item.dueDate).utcOffset(),
            }) : undefined,
            organizationId: item.organizationId || undefined,
            view: !!item.view || undefined,
            viewId: `${item.view?.id || ''}`,
            objectReference: {
              id: item?.objectReference?.id || undefined,
              shipmentId: item?.objectReference?.shipmentId || undefined,
              type: item?.objectReference?.type || undefined,
            },
            targetAudience: {
              type: item?.targetAudience?.type || undefined,
              id: item?.targetAudience?.id || undefined,
            },
            actions: item.actions?.map((itemAction) => NotificationTaskContentActionsDTM.fromPlain({
              type: itemAction?.type || undefined,
              id: itemAction?.id || undefined,
              title: itemAction?.title,
            })),
            metadata: {
              taskType: item?.metadata?.taskType || undefined,
              shipmentId: item?.metadata?.shipmentId || undefined,
              shipmentName: item?.metadata?.shipmentName || undefined,
            },
          })),
        });
      }

      const errors = result?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ: error', errors, result);
      }
    } catch (e) {
      throw new Error('CommandCenterService: getTasks');
    }

    return result;
  }

  public setNotificationsViewed = async (id: string) => {
    if (!id) throw new Error('CommandCenterService: setNotificationsViewed (id is null)');
    let result: NotificationTaskContentViewedDTM | undefined;
    let response: ICommandCenterNotificationsViewedResponse | undefined;

    try {
      const rawResponse = await apiWorker.requestPost<ICommandCenterNotificationsViewedResponse>(`${this.baseUrlNotifications}/${id}/views`);

      response = rawResponse.data;

      result = NotificationTaskContentViewedDTM.fromPlain({
        id: response.id,
        viewedAt: response.viewedAt,
        viewedBy: response.viewedBy,
      });
    } catch (e) {
      throw new Error('CommandCenterService: setNotificationsViewed');
    }

    return result;
  }

  public setNotificationsUnviewed = async (notificationId: string, viewId: string) => {
    if (!notificationId || !viewId) throw new Error('CommandCenterService: setNotificationsUnviewed (id is null)');

    try {
      await apiWorker.requestDelete(`${this.baseUrlNotifications}/${notificationId}/views/${viewId}`);
    } catch (e) {
      throw new Error('CommandCenterService: setNotificationsUnviewed');
    }
  }

  public setAllNotificationsViewed = async (shipmentId?: string) => {
    try {
      await apiWorker.requestPost(`${this.baseUrlNotifications}/views`, null, {
        params: {
          'metadata[shipmentId]': shipmentId || undefined,
        },
      });
    } catch (e) {
      throw new Error('CommandCenterService: setAllNotificationsViewed');
    }
  }
}
