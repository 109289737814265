import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { R as appR } from 'app-wrapper/repository';
import { AddressSectionComponent } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components';

export const AddressSection = React.memo(() => {
  const address = useSelector(R.selectors.bookingWizard.getCargoReceiverAddress);
  const isRequiredErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoReceiverRequiredErrorVisible);
  const countriesList = useSelector(appR.selectors.countriesList.getCountriesList);
  const isOriginPartner = useSelector(R.selectors.bookingWizard.getIsOriginPartner);

  return (
    <AddressSectionComponent
      addressLine1={address?.address1}
      setAddressLine1={UC.bookingWizard.setCargoReceiverAddressField('address1')}
      addressLine2={address?.address2}
      setAddressLine2={UC.bookingWizard.setCargoReceiverAddressField('address2')}
      country={address?.country}
      setCountry={UC.bookingWizard.setCargoReceiverAddressField('country')}
      countryState={address?.state}
      setCountryState={UC.bookingWizard.setCargoReceiverAddressField('state')}
      city={address?.city}
      setCity={UC.bookingWizard.setCargoReceiverAddressField('city')}
      postalCode={address?.postalCode}
      setPostalCode={UC.bookingWizard.setCargoReceiverAddressField('postalCode')}
      isRequiredErrorVisible={isRequiredErrorVisible}
      countries={countriesList}
      isOriginPartner={isOriginPartner}
    />
  );
});
