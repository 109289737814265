import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { DrayageTransportationTabsComponent } from './DrayageTransportationTabs.component';

const DrayageTransportationTabsContainer = memo(() => {
  const drayagePermissions = useSelector(R.selectors.shipment.getTransportationDrayagePermissions);

  return (
    <DrayageTransportationTabsComponent
      openAddCharge={UC.shipmentActiveCharge.openTransportationActiveCharge}
      addChargesExportPermissions={drayagePermissions.addChargeExportCharges}
      addChargesImportPermissions={drayagePermissions.addChargeImportCharges}
      trackerAndChargesVisibility={drayagePermissions.chargesAndTrackerVisibility}
    />
  );
});

export { DrayageTransportationTabsContainer as DrayageTransportationTabs };
