import message from 'antd/es/message';
import i18n from 'app-wrapper/i18n/i18n';
import { DocumentUploaderDrawerStore } from 'app-wrapper/repository/mobxStores/DocumentUploaderDrawerStore';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { InputMobxUseCase } from 'app-wrapper/usecases/InputMobx.useCase';
import { BaseController, controller } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';

@controller
export class DocumentUploaderDrawerController extends BaseController<DocumentUploaderDrawerStore> {
  onLoadedPage() {
    super.onLoadedPage();
    this.currentStore?.setLoading(true);

    this.currentStore?.setForm({
      file: new InputStore(undefined),
    });

    this.currentStore?.setLoading(false);
  }

  file() {
    return new InputMobxUseCase(
      this,
      this.currentStore?.state.form.file as InputStore<ContainerDocumentDTM>,
    );
  }

  closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  async updateContainer() {
    if (this.currentStore?.hasError) {
      return;
    }

    try {
      // newContainer = await R.services.shipmentContainers.putContainer(
      //   this.shipmentId(),
      //   container,
      // );
    } finally {
      this.currentStore?.setSubmitting(false);
    }

    message.success(i18n.t('Saved successfully'));
    this.closeDrawer();
  }

  public setDocument = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.shipmentDocumentsAll.setAdditionalDocument(document));
  };

  public ddownloadDocument = (shipmentId: string) => {
    const document = R.selectors.shipmentDocumentsAll.getAdditionalDocument(this.store.getState());

    if (document) {
      this.downloadDocument(shipmentId, document.response.id, document.response.name);
    }
  };

  public downloadDocument = async (shipmentId: string, documentId: number, documentName: string) => {
    try {
      R.services.shipmentDocument.getShipmentDocument(+shipmentId, documentId, documentName);
    } catch (e) {
      console.error('ShipmentDocumentsAllController CONTROLLER: downloadDocument');
    }
  }
}
