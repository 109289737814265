import { DateDtm } from 'app-wrapper/models/dtm';
import {
  IsNumber,
  IsOptional,
  IsEnum,
  IsDefined,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { EDrayageLoadingSide } from 'shipment-operations/constants';

export interface IShipmentScheduleDTM {
  id: number;
  drayageSide: EDrayageLoadingSide;
  cargoReadyDate?: DateDtm;
  earliestPickupDate?: DateDtm;
  latestPickupDate?: DateDtm;
}

export class ShipmentScheduleDTM extends BaseDTM<IShipmentScheduleDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsEnum(EDrayageLoadingSide)
  drayageSide: EDrayageLoadingSide;

  @IsOptional()
  @Type(() => DateDtm)
  cargoReadyDate?: DateDtm;

  @IsOptional()
  @Type(() => DateDtm)
  earliestPickupDate?: DateDtm;

  @IsOptional()
  @Type(() => DateDtm)
  latestPickupDate?: DateDtm;
}
