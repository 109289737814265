import {
  IsArray,
  IsDefined,
  IsEnum,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import _capitalize from 'lodash/upperFirst';
import { Type } from 'class-transformer';
import moment from 'moment/moment';
import { BaseDTM } from 'proto/BaseDTM';

import {
  BillOfLadingTypeNames,
  MBLCommentType,
  MBLLocationType,
  MBLReferenceType,
  MBLChargeIndicatorType,
  MBLType,
  EShippingPartyTypes,
  MBLChargeIndicatorTypeNames,
  ChargeCodeDesignationNames,
  ChargeCodeDesignation,
  TRouteLegPhase,
} from 'shipment-operations/constants';
import {
  BillOfLadingDTM,
  CargoDTM,
  ContainerCargoShortItemDTM,
  ContainerDTM,
  ContainerWithCargoDTM,
  DocumentsDistributionDTM,
  IBillOfLadingDTM,
  ICargoDTM,
  IContainerCargoShortItemDTM,
  IContainerInputDTM,
  IContainerWithCargoDTM,
  TemperatureControlDTM,
  ITemperatureControlDTM,
  TransportationOverviewDTM,
  ChargeDTM,
  PaymentTermsDTM,
} from 'shipment-operations/models/dtm/index';
import { DateDtm, IDateDTM } from 'app-wrapper/models/dtm';
import i18n from 'app-wrapper/i18n/i18n';

interface IMBLLocationDTM {
  code: string;
  name: string;
  countryName: string | null;
  countryCode: string | null;
  type: MBLLocationType;
  dateTime: string | null;
}

export class MBLLocationDTM extends BaseDTM<IMBLLocationDTM> {
  @IsString()
  @IsDefined()
  code: string;

  @IsString()
  @IsDefined()
  name: string;

  @IsString()
  @IsOptional()
  countryName: string | null;

  @IsString()
  @IsOptional()
  countryCode: string | null;

  @IsDefined()
  @IsEnum(MBLLocationType)
  type: MBLLocationType;

  @IsOptional()
  @IsString()
  dateTime: string | null;

  public getLocationName = () => `${this.name}${this.countryCode ? `, ${this.countryCode}` : ''}`;
}

interface IMBLShippingPartyCompanyDTM {
  name: string;
}

class MBLShippingPartyCompanyDTM extends BaseDTM<IMBLShippingPartyCompanyDTM> {
  @IsDefined()
  @IsString()
  name: string;
}

interface IMBLShippingPartyContactDTM {
  name: string;
  phone: string;
  email: string;
}

class MBLShippingPartyContactDTM extends BaseDTM<IMBLShippingPartyContactDTM> {
  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  phone: string;

  @IsDefined()
  @IsString()
  email: string;
}

interface IMBLShippingPartyAddressDTM {
  addresses: string[];
}

class MBLShippingPartyAddressDTM extends BaseDTM<IMBLShippingPartyAddressDTM> {
  @IsDefined()
  @IsArray()
  addresses: string[];
}

interface IMBLShippingPartyDTM {
  role: EShippingPartyTypes;
  company?: IMBLShippingPartyCompanyDTM;
  contact?: IMBLShippingPartyContactDTM;
  address?: IMBLShippingPartyAddressDTM;
}

export class MBLShippingPartyDTM extends BaseDTM<IMBLShippingPartyDTM> {
  @IsDefined()
  @IsEnum(EShippingPartyTypes)
  role: EShippingPartyTypes;

  @IsOptional()
  @Type(() => MBLShippingPartyCompanyDTM)
  company?: MBLShippingPartyCompanyDTM;

  @IsOptional()
  @Type(() => MBLShippingPartyContactDTM)
  contact?: MBLShippingPartyContactDTM;

  @IsOptional()
  @Type(() => MBLShippingPartyAddressDTM)
  address?: MBLShippingPartyAddressDTM;
}

interface IMBLReferenceDTM {
  reference: string;
  type: MBLReferenceType;
}

class MBLReferenceDTM extends BaseDTM<IMBLReferenceDTM> {
  @IsDefined()
  @IsString()
  reference: string;

  @IsDefined()
  @IsString()
  type: MBLReferenceType;
}

interface IMBLCommentDTM {
  comment: string;
  type: MBLCommentType;
}

class MBLCommentDTM extends BaseDTM<IMBLCommentDTM> {
  @IsDefined()
  @IsString()
  comment: string;

  @IsDefined()
  @IsEnum(MBLCommentType)
  type: MBLCommentType;
}

interface IMBLTransportationDTM {
  transportName: string;
  transportNumber: string;
  carrierScac: string;
  locations: IMBLLocationDTM[];
}

class MBLTransportationDTM extends BaseDTM<IMBLTransportationDTM> {
  @IsDefined()
  @IsString()
  transportName: string;

  @IsDefined()
  @IsString()
  transportNumber: string;

  @IsDefined()
  @IsString()
  carrierScac: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLLocationDTM)
  locations: MBLLocationDTM[];
}

interface IMBLChargeAmountDTM {
  currency: string;
  value: string;
}

class MBLChargeAmountDTM extends BaseDTM<IMBLChargeAmountDTM> {
  @IsDefined()
  @IsString()
  currency: string;

  @IsDefined()
  @IsString()
  value: string;
}

interface IMBLChargeCategoryDTM {
  indicator: string;
  type: string;
  value: string;
}

class MBLChargeCategoryDTM extends BaseDTM<IMBLChargeCategoryDTM> {
  @IsDefined()
  @IsString()
  indicator: string;

  @IsDefined()
  @IsString()
  type: string;

  @IsDefined()
  @IsString()
  value: string;
}

interface IMBLChargeDTM {
  amount: IMBLChargeAmountDTM;
  category: IMBLChargeCategoryDTM;
}

class MBLChargeDTM extends BaseDTM<IMBLChargeDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => MBLChargeAmountDTM)
  amount: MBLChargeAmountDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => MBLChargeCategoryDTM)
  category: MBLChargeCategoryDTM;
}

export interface IMasterBillOfLadingDTM {
  type: MBLType;
  shippedOnBoardDate: string;
  references: IMBLReferenceDTM[];
  comments: IMBLCommentDTM[];
  transportations: IMBLTransportationDTM[];
  shipmentParties: IMBLShippingPartyDTM[];
  blLocations: IMBLLocationDTM[];
  containers: IContainerInputDTM[];
  cargos: ICargoDTM[];
  charges: IMBLChargeDTM[];
  temperatureControl: ITemperatureControlDTM | null;
}

export class MasterBillOfLadingDTM extends BaseDTM<IMasterBillOfLadingDTM> {
  @IsDefined()
  @IsEnum(MBLType)
  type: MBLType;

  @IsDefined()
  @IsString()
  shippedOnBoardDate: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLReferenceDTM)
  references: MBLReferenceDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLCommentDTM)
  comments: MBLCommentDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLTransportationDTM)
  transportations: MBLTransportationDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLShippingPartyDTM)
  shipmentParties: MBLShippingPartyDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLLocationDTM)
  blLocations: MBLLocationDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContainerDTM)
  containers: ContainerDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => CargoDTM)
  cargos: CargoDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLChargeDTM)
  charges: MBLChargeDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => TemperatureControlDTM)
  temperatureControl: TemperatureControlDTM | null;
}

interface IMBLViewChargeDTM {
  name: string;
  type: string;
  currency: string;
  price: string;
}

export class MBLViewChargeDTM extends BaseDTM<IMBLViewChargeDTM> {
  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  type: string;

  @IsDefined()
  @IsString()
  currency: string;

  @IsDefined()
  @IsString()
  price: string;
}

interface IHBLViewChargeDTM {
  name: string;
  phase: string;
  code: string;
  designation: ChargeCodeDesignation;
  paymentTerm: string;
  currency: string;
  price: string;
}

export class HBLViewChargeDTM extends BaseDTM<IHBLViewChargeDTM> {
  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  phase: string;

  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  paymentTerm: string;

  @IsDefined()
  @IsString()
  currency: string;

  @IsDefined()
  @IsString()
  price: string;

  @IsEnum(ChargeCodeDesignation)
  designation: ChargeCodeDesignation;

  static getCombinedCharge = (charges: ChargeDTM[]) => ChargeDTM.fromPlain({
    ...charges[0],
    buyTotalCost: charges.reduce((acc, next) => acc + next.buyTotalCost, 0),
  });

  static getHBLViewCharges = (charges: ChargeDTM[], paymentTerms: PaymentTermsDTM) => {
    const actualCharges = charges.filter(({ applied }) => applied);
    const codeToChargesMap: Record<string, ChargeDTM[]> = {};
    const finalCharges: ChargeDTM[] = [];

    actualCharges.forEach((charge) => {
      const { code } = charge.chargeCode;

      if (codeToChargesMap[code]) {
        codeToChargesMap[code].push(charge);
      } else {
        codeToChargesMap[code] = [charge];
      }
    });

    Object.keys(codeToChargesMap).forEach((chargeCode) => {
      const localCharges = codeToChargesMap[chargeCode];
      const originalDescriptionToChargesMap: Record<string, ChargeDTM[]> = {};

      localCharges.forEach((charge) => {
        const key = charge.chargeCode?.description ? charge.chargeCode.description : 'none';

        if (originalDescriptionToChargesMap[key]) {
          originalDescriptionToChargesMap[key].push(charge);
        } else {
          originalDescriptionToChargesMap[key] = [charge];
        }
      });

      Object.values(originalDescriptionToChargesMap).forEach((_charges) => {
        const freightCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.FREIGHT);
        const originCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.ORIGIN);
        const destinationCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.DESTINATION);

        if (freightCharges.length) {
          finalCharges.push(this.getCombinedCharge(freightCharges));
        }

        if (originCharges.length) {
          finalCharges.push(this.getCombinedCharge(originCharges));
        }

        if (destinationCharges.length) {
          finalCharges.push(this.getCombinedCharge(destinationCharges));
        }
      });
    });

    const transformedCharges = finalCharges.map((charge) => HBLViewChargeDTM.transformChargeDTMToHBLViewChargeDTM(charge, paymentTerms));
    const origin = transformedCharges.filter(({ designation }) => designation === ChargeCodeDesignation.ORIGIN);
    const freight = transformedCharges.filter(({ designation }) => designation === ChargeCodeDesignation.FREIGHT);
    const destination = transformedCharges.filter(({ designation }) => designation === ChargeCodeDesignation.DESTINATION);

    return [
      ...origin.filter(({ code }) => code === 'DRAY'),
      ...origin.filter(({ code }) => code !== 'DRAY'),
      ...freight.filter(({ code }) => code === 'FRT'),
      ...freight.filter(({ code }) => code !== 'FRT'),
      ...destination.filter(({ code }) => code === 'DRAY'),
      ...destination.filter(({ code }) => code !== 'DRAY'),
    ];
  }

  static transformChargeDTMToHBLViewChargeDTM = (charge: ChargeDTM, paymentTerms: PaymentTermsDTM): HBLViewChargeDTM => {
    const {
      metadata,
      chargeCode,
      designation,
      subjectTo,
      buyTotalCost,
      currency,
    } = charge;
    const { code } = chargeCode;
    let name = chargeCode.description;

    if (code === 'MSC' && metadata && metadata.originalDescription) {
      name = metadata.originalDescription;
    }

    let paymentTerm = '';

    if (designation === ChargeCodeDesignation.ORIGIN) {
      paymentTerm = paymentTerms.origin;
    } else if (designation === ChargeCodeDesignation.FREIGHT) {
      paymentTerm = paymentTerms.freight;
    } else if (designation === ChargeCodeDesignation.DESTINATION) {
      paymentTerm = paymentTerms.destination;
    }

    let price = String(buyTotalCost.toFixed(2));

    if (subjectTo === 'INCLUDED') {
      price = i18n.t('Included');
    }

    return HBLViewChargeDTM.fromPlain({
      name: _capitalize(name),
      code: chargeCode.code,
      phase: ChargeCodeDesignationNames[designation as ChargeCodeDesignation],
      designation: designation as ChargeCodeDesignation,
      paymentTerm,
      currency,
      price,
    });
  }
}

export interface IMasterBillOfLadingViewDTM {
  freightPaymentLocation: string;
  contactDetails: string[];
  bookingReference: string;
  contractReference: string;
  carrierScac: string;
  mainVessel: string;
  voyage: string;
  shippingParties: IMBLShippingPartyDTM[];
  billOfLading: IBillOfLadingDTM | null;
  placeOfDeliveryName: string;
  portOfLoadingName: string;
  portOfDischargeName: string;
  placeOfReceiptName: string;
  routingInstructions: string;
  references: IMBLReferenceDTM[];
  charges: IMBLChargeDTM[];
  blReleaseLocation: string;
  blReleaseDate: IDateDTM | null;
  shippedOnBoardDate: IDateDTM | null;
  clauses: IMBLCommentDTM[];
  additionalNotes: string;
  termsAndConditions: string;
  containers: IContainerWithCargoDTM[];
  cargos: ICargoDTM[];
  allCargoItems: IContainerCargoShortItemDTM[];
  temperatureControl: ITemperatureControlDTM | null;
}

export class MasterBillOfLadingViewDTM extends BaseDTM<IMasterBillOfLadingViewDTM> {
  @IsDefined()
  @IsString()
  freightPaymentLocation: string;

  @IsDefined()
  @IsArray()
  contactDetails: string[];

  @IsDefined()
  @IsString()
  bookingReference: string;

  @IsDefined()
  @IsString()
  carrierScac: string;

  @IsDefined()
  @IsString()
  contractReference: string;

  @IsDefined()
  @IsString()
  mainVessel: string;

  @IsDefined()
  @IsString()
  voyage: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLShippingPartyDTM)
  shippingParties: MBLShippingPartyDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => BillOfLadingDTM)
  billOfLading: BillOfLadingDTM | null;

  @IsDefined()
  @IsString()
  placeOfDeliveryName: string;

  @IsDefined()
  @IsString()
  portOfLoadingName: string;

  @IsDefined()
  @IsString()
  portOfDischargeName: string;

  @IsDefined()
  @IsString()
  placeOfReceiptName: string;

  @IsDefined()
  @IsString()
  routingInstructions: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLReferenceDTM)
  references: MBLReferenceDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLChargeDTM)
  charges: MBLChargeDTM[];

  @IsDefined()
  @IsString()
  blReleaseLocation: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  blReleaseDate: DateDtm | null;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  shippedOnBoardDate: DateDtm | null;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MBLCommentDTM)
  clauses: MBLCommentDTM[];

  @IsDefined()
  @IsString()
  additionalNotes: string;

  @IsDefined()
  @IsString()
  termsAndConditions: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContainerWithCargoDTM)
  containers: ContainerWithCargoDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => CargoDTM)
  cargos: CargoDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContainerCargoShortItemDTM)
  allCargoItems: ContainerCargoShortItemDTM[];

  @IsDefined()
  @ValidateNested()
  @Type(() => TemperatureControlDTM)
  temperatureControl: TemperatureControlDTM | null;

  public getClausesItems = () => this.clauses.map(({ comment }) => comment);

  public getShippedDateItems = () => [
    {
      title: i18n.t('Shipped Date'),
      subtitle: this.shippedOnBoardDate ? this.shippedOnBoardDate.getDateAsMomentWithOffset().format('D MMM YYYY, HH:mm\'') : '',
    },
  ];

  public getBillOfLadingReleaseItems = () => [
    {
      title: i18n.t('Location'),
      subtitle: this.blReleaseLocation || '-',
    },
    {
      title: i18n.t('Release Date'),
      subtitle: this.blReleaseDate ? this.blReleaseDate?.getDateAsMomentWithOffset().format('D MMM YYYY, HH:mm\'') : '-',
    },
  ];

  public getChargesInformationItems = () => {
    const charges = [
      ...this.charges.filter(({ category }) => category.indicator === MBLChargeIndicatorType.PREPAID),
      ...this.charges.filter(({ category }) => category.indicator === MBLChargeIndicatorType.COLLECT),
    ];

    return charges.map(({ category, amount }) => MBLViewChargeDTM.fromPlain({
      name: category.value ? category.value : category.type.split(/(?=[A-Z])/).join(' '),
      type: MBLChargeIndicatorTypeNames[category.indicator as MBLChargeIndicatorType],
      currency: amount.currency,
      price: amount.value,
    }));
  }

  public getReferencesInformationItems = () => {
    const transactionReferenceNumber = this.references.find(({ type }) => type === MBLReferenceType.TransactionReferenceNumber);

    return [
      {
        title: i18n.t('Transaction Reference Number'),
        subtitle: transactionReferenceNumber ? transactionReferenceNumber.reference : '',
      },
    ];
  }

  public getRoutingInformationItems = () => [
    {
      title: i18n.t('Place of Receipt'),
      subtitle: this.placeOfReceiptName,
    },
    {
      title: i18n.t('Port of Load'),
      subtitle: this.portOfLoadingName,
    },
    {
      title: i18n.t('Port of Discharge'),
      subtitle: this.portOfDischargeName,
    },
    {
      title: i18n.t('Place of Delivery'),
      subtitle: this.placeOfDeliveryName,
    },
  ];

  public getBillOfLadingInformationItems = () => {
    const { billOfLading } = this;

    if (!billOfLading) {
      return [
        {
          title: i18n.t('Bill of Lading Details'),
          subtitle: '',
        },
        {
          title: i18n.t('Overall Number of Originals BOLs'),
          subtitle: '',
        },
        {
          title: i18n.t('Overall Number of Copy BOLs'),
          subtitle: '',
        },
      ];
    }

    const calculateBillingItemsSum = (items: DocumentsDistributionDTM[], isFreighted: boolean) => {
      const targetItems = items.filter(({ freighted }) => freighted === isFreighted);

      return targetItems.reduce((prev, next) => prev + Number(next.amount), 0);
    };

    const originalsFreightedSum = calculateBillingItemsSum(billOfLading.originals, true);
    const originalsUnfreightedSum = calculateBillingItemsSum(billOfLading.originals, false);
    const copiesFreightedSum = calculateBillingItemsSum(billOfLading.copies, true);
    const copiesUnfreightedSum = calculateBillingItemsSum(billOfLading.copies, false);
    const type = billOfLading?.type;

    return [
      {
        title: i18n.t('Bill of Lading Details'),
        subtitle: type ? BillOfLadingTypeNames[type] : '',
      },
      {
        title: i18n.t('Overall Number of Originals BOLs'),
        subtitle: `${originalsFreightedSum} ${i18n.t('Freighted')} ${originalsUnfreightedSum} ${i18n.t('Unfreighted')}`,
      },
      {
        title: i18n.t('Overall Number of Copy BOLs'),
        subtitle: `${copiesFreightedSum} ${i18n.t('Freighted')} ${copiesUnfreightedSum} ${i18n.t('Unfreighted')}`,
      },
    ];
  }

  public getShippingPartiesInformation = () => {
    const { shippingParties } = this;
    const consignee = shippingParties.find(({ role }) => role === EShippingPartyTypes.CONSIGNEE);
    const shipper = shippingParties.find(({ role }) => role === EShippingPartyTypes.SHIPPER);
    const notifyParty = shippingParties.find(({ role }) => role === EShippingPartyTypes.NOTIFY_PARTY);

    return {
      consignee,
      shipper,
      notifyParty,
    };
  }

  public getCarrierInformationItems = () => {
    const { bookingReference, contractReference, carrierScac } = this;

    return [
      {
        title: i18n.t('Name'),
        subtitle: carrierScac,
      },
      {
        title: i18n.t('Booking Reference'),
        subtitle: bookingReference,
      },
      {
        title: i18n.t('Contract Reference'),
        subtitle: contractReference,
      },
    ];
  }

  public getTransportInformationItems = () => {
    const { mainVessel, voyage } = this;

    return [
      {
        title: i18n.t('Main Vessel'),
        subtitle: mainVessel,
      },
      {
        title: i18n.t('Voyage'),
        subtitle: voyage,
      },
    ];
  }

  static fromMasterBillOfLadingDTM = (masterBillOfLading: MasterBillOfLadingDTM, overview: TransportationOverviewDTM | null, billOfLading?: BillOfLadingDTM): MasterBillOfLadingViewDTM => {
    const { contactDetails, freightPaymentLocation } = MasterBillOfLadingViewDTM.getCompanyInformation(masterBillOfLading, overview);
    const { bookingReference, contractReference, carrierScac } = MasterBillOfLadingViewDTM.getCarrierInformation(masterBillOfLading);
    const { mainVessel, voyage } = MasterBillOfLadingViewDTM.getTransportInformation(masterBillOfLading);
    const {
      portOfDischargeName,
      portOfLoadingName,
      placeOfReceiptName,
      placeOfDeliveryName,
    } = MasterBillOfLadingViewDTM.getRoutingInformation(masterBillOfLading);
    const routingInstructions = MasterBillOfLadingViewDTM.getRoutingInstructions(masterBillOfLading);
    const { blReleaseDate, blReleaseLocation } = MasterBillOfLadingViewDTM.getBillOfLadingReleaseInformation(masterBillOfLading);
    const shippedOnBoardDate = MasterBillOfLadingViewDTM.getShippedOnBoardDate(masterBillOfLading);
    const additionalNotes = MasterBillOfLadingViewDTM.getAdditionalNotes(masterBillOfLading);
    const termsAndConditions = MasterBillOfLadingViewDTM.getTermsAndConditions(masterBillOfLading);
    const { cargos, allCargoItems, containers } = MasterBillOfLadingViewDTM.getTablesInformation(masterBillOfLading);

    return MasterBillOfLadingViewDTM.fromPlain({
      freightPaymentLocation,
      contactDetails,
      bookingReference,
      contractReference,
      mainVessel,
      carrierScac,
      voyage,
      shippingParties: masterBillOfLading.shipmentParties,
      billOfLading: billOfLading || null,
      placeOfDeliveryName,
      portOfLoadingName,
      portOfDischargeName,
      placeOfReceiptName,
      routingInstructions,
      references: masterBillOfLading.references || [],
      charges: masterBillOfLading.charges || [],
      blReleaseLocation,
      blReleaseDate,
      shippedOnBoardDate,
      clauses: masterBillOfLading.comments.filter(({ type }) => type === MBLCommentType.BlClause),
      additionalNotes,
      termsAndConditions,
      containers,
      cargos,
      allCargoItems,
      temperatureControl: masterBillOfLading.temperatureControl,
    });
  }

  static getCarrierInformation = (masterBillOfLading: MasterBillOfLadingDTM) => {
    let bookingReference = '';
    let contractReference = '';
    let carrierScac = '';
    const { references, transportations } = masterBillOfLading;
    const bookingReferenceObj = references.find(({ type }) => type === MBLReferenceType.BookingNumber);
    const contractReferenceObj = references.find(({ type }) => type === MBLReferenceType.ContractNumber);
    const transportation = transportations[0];

    if (transportation) {
      carrierScac = transportation.carrierScac;
    }

    if (bookingReferenceObj && bookingReferenceObj.reference) {
      bookingReference = bookingReferenceObj.reference;
    }

    if (contractReferenceObj && contractReferenceObj.reference) {
      contractReference = contractReferenceObj.reference;
    }

    return {
      bookingReference,
      contractReference,
      carrierScac,
    };
  }

  static getCompanyInformation = (masterBillOfLading: MasterBillOfLadingDTM, overview: TransportationOverviewDTM | null) => {
    let freightPaymentLocation = '';
    const contactDetails: string[] = [];
    const { blLocations } = masterBillOfLading;
    const location = blLocations ? blLocations.find(({ type }) => type === MBLLocationType.FreightPaymentLocation) : undefined;

    if (location) {
      freightPaymentLocation = location.getLocationName();
    }

    if (overview) {
      const { contactName, emailList, phoneList } = overview;

      contactDetails.push(contactName);

      if (phoneList[0]) {
        contactDetails.push(phoneList[0]);
      }

      if (emailList[0]) {
        contactDetails.push(emailList[0]);
      }
    }

    return {
      freightPaymentLocation,
      contactDetails,
    };
  }

  static getTransportInformation = (masterBillOfLading: MasterBillOfLadingDTM) => {
    let mainVessel = '';
    let voyage = '';
    const { transportations } = masterBillOfLading;
    const transportation = transportations[0];

    if (transportation.transportName) {
      mainVessel = transportation.transportName;
    }

    if (transportation.transportNumber) {
      voyage = transportation.transportNumber;
    }

    return {
      mainVessel,
      voyage,
    };
  }

  static getRoutingInformation = (masterBillOfLading: MasterBillOfLadingDTM) => {
    let placeOfDeliveryName = '';
    let portOfLoadingName = '';
    let portOfDischargeName = '';
    let placeOfReceiptName = '';
    const transportation = masterBillOfLading.transportations[0];

    if (transportation) {
      const { locations } = transportation;

      const placeOfDelivery = locations.find(({ type }) => type === MBLLocationType.PlaceOfDelivery);
      const portOfLoading = locations.find(({ type }) => type === MBLLocationType.PortOfLoading);
      const portOfDischarge = locations.find(({ type }) => type === MBLLocationType.PortOfDischarge);
      const placeOfReceipt = locations.find(({ type }) => type === MBLLocationType.PlaceOfReceipt);

      if (placeOfDelivery) {
        placeOfDeliveryName = placeOfDelivery.getLocationName();
      }

      if (portOfLoading) {
        portOfLoadingName = portOfLoading.getLocationName();
      }

      if (portOfDischarge) {
        portOfDischargeName = portOfDischarge.getLocationName();
      }

      if (placeOfReceipt) {
        placeOfReceiptName = placeOfReceipt.getLocationName();
      }
    }

    return {
      placeOfDeliveryName,
      portOfLoadingName,
      portOfDischargeName,
      placeOfReceiptName,
    };
  }

  static getRoutingInstructions = (masterBillOfLading: MasterBillOfLadingDTM) => {
    const { comments } = masterBillOfLading;
    const routingInstructionComments = comments.filter(({ type }) => type === MBLCommentType.RoutingInstructions);

    return routingInstructionComments.length ? routingInstructionComments.map(({ comment }) => comment).join('. ') : '';
  }

  static getBillOfLadingReleaseInformation = (masterBillOfLading: MasterBillOfLadingDTM) => {
    let blReleaseLocation = '';
    let blReleaseDate: DateDtm | null = null;
    const { blLocations } = masterBillOfLading;
    const blReleaseLocationObj = blLocations ? blLocations.find(({ type }) => type === MBLLocationType.BillOfLadingRelease) : undefined;

    if (blReleaseLocationObj) {
      blReleaseLocation = blReleaseLocationObj.getLocationName();
    }

    if (blReleaseLocationObj && blReleaseLocationObj.dateTime) {
      blReleaseDate = MasterBillOfLadingViewDTM.formatMBLDateStringToDateDTM(blReleaseLocationObj.dateTime);
    }

    return {
      blReleaseLocation,
      blReleaseDate,
    };
  }

  static getShippedOnBoardDate = (masterBillOfLading: MasterBillOfLadingDTM) => {
    const { shippedOnBoardDate } = masterBillOfLading;

    return shippedOnBoardDate ? MasterBillOfLadingViewDTM.formatMBLDateStringToDateDTM(shippedOnBoardDate) : null;
  }

  static getAdditionalNotes = (masterBillOfLading: MasterBillOfLadingDTM) => {
    const notes: string[] = [];
    const { comments } = masterBillOfLading;
    const generalComment = comments.find(({ type }) => type === MBLCommentType.General);
    const blRemark = comments.find(({ type }) => type === MBLCommentType.BillOfLadingRemarks);

    if (generalComment) {
      notes.push(generalComment.comment);
    }

    if (blRemark) {
      notes.push(blRemark.comment);
    }

    return notes.join('. ');
  }

  static getTermsAndConditions = (masterBillOfLading: MasterBillOfLadingDTM) => {
    const { comments } = masterBillOfLading;
    const tocComments = comments.filter(({ type }) => type === MBLCommentType.TermsAndConditions);

    return tocComments.length ? tocComments.map(({ comment }) => comment).join('. ') : '';
  }

  static getTablesInformation = (masterBillOfLading: MasterBillOfLadingDTM) => {
    const { containers, cargos } = masterBillOfLading;
    const allCargoItems = containers.reduce((prev, next) => [...prev, ...next.cargoItems], [] as IContainerCargoShortItemDTM[])
      .map((item) => ContainerCargoShortItemDTM.fromPlain(item));
    const containersWithCargos = containers.map((container) => (ContainerWithCargoDTM.fromPlain({
      ...container,
      cargoItems: container.cargoItems.map((cargoItem) => {
        const cargo = cargos.find(({ id = 0 }) => +cargoItem.cargoId === id) as CargoDTM;

        return {
          ...cargoItem,
          cargo,
        };
      }),
    })));

    return {
      allCargoItems,
      cargos,
      containers: containersWithCargos,
    };
  }

  static formatMBLDateStringToDateDTM = (dateString: string) => {
    if (dateString.length === 8) {
      return DateDtm.fromPlain({
        date: dateString,
        offset: moment.parseZone(dateString).utcOffset(),
      });
    }

    const yymmddString = dateString.slice(0, 8);
    const hhmmString = dateString.slice(8, 12);
    const formattedDateString = `${yymmddString}T${hhmmString}`;

    return DateDtm.fromPlain({
      date: formattedDateString,
      offset: moment.parseZone(formattedDateString).utcOffset(),
    });
  }
}
