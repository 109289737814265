import { createSelector } from 'reselect';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';

import { RootState } from 'app-wrapper/store';
import { shipmentSelectors } from 'shipment-operations/repository/store/Shipment';
import { userOrganizationDataSelectors } from 'user-management/repository/store/UserOrganizationData';
import { ChargeCodeDTM, ContainerForTableDTM } from 'shipment-operations/models/dtm';
import {
  accessorialSubTypes,
  ACTIVE,
  AHF,
  commonContainersTypesLong, ContainerReeferTypes, ContainerUsualTypes,
  PermissionAttributePolicy,
  SCF,
  seaLoadType,
  seaMode,
} from 'shipment-operations/constants';
import { EOrganizationMemberRole } from 'user-management/constants';
import { chargeCodeOccurrence, chargeCodeType, EFreightIncotermsTrade } from 'monetary/constants';

const localState = (state: RootState) => state.shipmentActiveCharge;

const getFullListChargesState = createSelector(
  localState,
  (state) => state.fullListCharges,
);

const getIsLoadingCompanies = createSelector(
  localState,
  (state) => state.shipmentParties.isLoadingCompanies,
);

const getIsLoadingPaymentTerms = createSelector(
  localState,
  (state) => state.isLoadingPaymentTerms,
);

const getFullListLoading = createSelector(
  getFullListChargesState,
  getIsLoadingCompanies,
  getIsLoadingPaymentTerms,
  (state, loadingCompanies, loadingPaymentTerms) => state.isLoading || loadingCompanies || loadingPaymentTerms,
);

const getFullListError = createSelector(
  getFullListChargesState,
  (state) => state.error,
);

const getFullListCharges = createSelector(
  getFullListChargesState,
  (state) => state.charges,
);

const filteredCharges = createSelector(
  getFullListCharges,
  shipmentSelectors.getOriginActiveChargePermissions,
  shipmentSelectors.getDestinationActiveChargePermissions,
  shipmentSelectors.getIsOriginPartner,
  shipmentSelectors.getIsDestinationPartner,
  userOrganizationDataSelectors.getUserOrganisationRole,
  (charges, permissionsOrigin, permissionsDestination, isOriginPartner, isDestinationPartner, mainRole) => {
    const filtered = charges.filter((item: ChargeCodeDTM) => seaMode.includes(item.mode)
      && seaLoadType.includes(item.loadType)
      && item.status === ACTIVE
      && ![SCF, AHF].includes(item.code));

    if (mainRole === EOrganizationMemberRole.admin) {
      return filtered.filter((item: ChargeCodeDTM) => item.type === chargeCodeType.FEE);
    }

    if (((permissionsOrigin === PermissionAttributePolicy.WRITE) && !isOriginPartner) || ((permissionsDestination === PermissionAttributePolicy.WRITE) && !isDestinationPartner)) {
      return filtered.filter((item: ChargeCodeDTM) => item.occurrence === chargeCodeOccurrence.ADDITIONAL || item.type === chargeCodeType.FEE);
    }

    return filtered;
  },
);

const filteredChargesAccessorial = createSelector(
  filteredCharges,
  (charges) => charges.filter((item: ChargeCodeDTM) => seaMode.includes(item.mode) && seaLoadType.includes(item.loadType) && item.status === 'ACTIVE' && accessorialSubTypes.includes(item.subType)),
);

const getChargesForDropdownAccessorial = createSelector(
  filteredChargesAccessorial,
  (charges) => charges.map((item: ChargeCodeDTM) => ({
    value: item.code,
    label: item.description,
  })),
);

const getChargesForDropdown = createSelector(
  filteredCharges,
  (charges) => charges.map((item: ChargeCodeDTM) => ({
    value: item.code,
    label: item.description,
  })),
);

const getChargesForDrayage = createSelector(
  getFullListCharges,
  (charges) => charges.map((item: ChargeCodeDTM) => ({
    value: item.code,
    label: item.description,
  })),
);

const getActiveCharge = createSelector(
  localState,
  (state) => state.activeCharge,
);

const getActiveChargeApplyTo = createSelector(
  getActiveCharge,
  (charge) => charge.applyTo,
);

const getActiveChargeMeasureBy = createSelector(
  getActiveCharge,
  (charge) => charge.measureBy,
);

const getActiveChargeDesignation = createSelector(
  getActiveCharge,
  (charge) => charge.designation,
);

const getActiveChargeCurrency = createSelector(
  getActiveCharge,
  (charge) => charge.currency,
);

const getActiveChargeSelectedChargeCode = createSelector(
  getActiveCharge,
  (charge) => charge.chargeCode,
);

const getActiveChargeSelectedChargeTitle = createSelector(
  getActiveCharge,
  (charge) => charge.chargeCode.description,
);

const getActiveChargeSelectedChargeMeasure = createSelector(
  getActiveCharge,
  (charge) => charge.measureBy,
);

const getActiveChargeSelectedChargeType = createSelector(
  getActiveCharge,
  (charge) => charge.chargeCode.type,
);

const getActiveChargeAPCostPerUnit = createSelector(
  getActiveCharge,
  (charge) => charge.APCostPerUnit,
);

const getActiveChargeAPNumberOfUnits = createSelector(
  getActiveCharge,
  (charge) => charge.APNumberOfUnits,
);

const getActiveChargeARNumberOfUnits = createSelector(
  getActiveCharge,
  (charge) => charge.ARNumberOfUnits,
);

const getActiveChargeARCostPerUnit = createSelector(
  getActiveCharge,
  (charge) => charge.ARCostPerUnit,
);

const getActiveChargeTotalAR = createSelector(
  getActiveChargeARNumberOfUnits,
  getActiveChargeARCostPerUnit,
  (quantity, cost) => {
    if (!quantity || !cost) {
      return 0;
    }
    return quantity * cost;
  },
);

const getActiveChargeTotalAP = createSelector(
  getActiveChargeAPNumberOfUnits,
  getActiveChargeAPCostPerUnit,
  (quantity, cost) => {
    if (!quantity || !cost) {
      return 0;
    }
    return quantity * cost;
  },
);

const getAPBudgetEdit = createSelector(
  getActiveCharge,
  (charge) => charge?.apBudget?.totalCost,
);

const getARBudgetEdit = createSelector(
  getActiveCharge,
  (charge) => charge?.arBudget?.totalCost,
);

const getAPBalanceEdit = createSelector(
  getActiveChargeTotalAP,
  getAPBudgetEdit,
  (total, budget = 0) => total - budget,
);

const getARBalanceEdit = createSelector(
  getActiveChargeTotalAR,
  getARBudgetEdit,
  (total, budget = 0) => total - budget,
);

const getActiveChargeProfit = createSelector(
  getActiveChargeTotalAR,
  getActiveChargeTotalAP,
  (ARTotal, APTotal) => ARTotal - APTotal,
);

const getActiveChargeErrors = createSelector(
  getActiveCharge,
  (charge) => charge.errors,
);

const getActiveChargeErrorCharge = createSelector(
  getActiveCharge,
  (charge) => charge.errorCharge,
);

const getActiveChargeContainers = createSelector(
  getActiveCharge,
  (charge) => charge.containers,
);

const getActiveChargeSavedContainer = createSelector(
  getActiveCharge,
  (charge) => charge.savedContainer,
);

const preparedContainers = createSelector(
  getActiveChargeContainers,
  (containers) => containers.map((item) => ({
    title: item.type,
    value: item.id,
    key: item.id,
  })),
);

const uniqContainers = createSelector(
  preparedContainers,
  (containers) => uniqBy((item) => item.title, containers),
);

const getActiveChargeSelectedContainers = createSelector(
  getActiveCharge,
  (charge) => charge.selectedContainers,
);

const getActiveChargeSelectedContainerType = createSelector(
  getActiveCharge,
  (charge) => charge.selectedContainerType,
);

const parentTree = createSelector(
  uniqContainers,
  getActiveChargeSelectedContainerType,
  (containers, containerType) => containers.map((item) => (ContainerForTableDTM.fromPlain({
    ...item,
    title: commonContainersTypesLong[item.title as ContainerUsualTypes | ContainerReeferTypes],
    key: `${item.title}`,
    value: `${item.title}`,
    children: [],
    disabled: containerType ? (item.title !== containerType) : false,
  }))),
);

const preparedContainersChildren = createSelector(
  getActiveChargeContainers,
  getActiveChargeSelectedContainerType,
  (containers, containerType) => containers.map((item) => (ContainerForTableDTM.fromPlain({
    title: item.number ? item.number : item.id,
    type: item.type,
    value: item.id,
    key: item.id,
    disabled: containerType ? (item.type !== containerType) : false,
  }))),
);

const gropedContainers = createSelector(
  preparedContainersChildren,
  (containers) => groupBy((item) => item.type, containers),
);

const preparedTreeData = createSelector(
  parentTree,
  gropedContainers,
  (parent, containers) => parent.map((item) => ({
    ...item,
    children: containers[item.key],
  })),
);

const getActiveChargeSelectedContainersError = createSelector(
  getActiveCharge,
  (charge) => charge.selectedContainersError,
);

const getActiveChargeDescription = createSelector(
  getActiveCharge,
  (charge) => charge.description,
);

const getActiveChargeIsLoadingCreate = createSelector(
  getActiveCharge,
  (charge) => charge.isLoadingCreate,
);

const getActiveChargeDocuments = createSelector(
  getActiveCharge,
  (charge) => charge.documents,
);

const getActiveChargeRateId = createSelector(
  getActiveCharge,
  (charge) => charge.rateId,
);

const getActiveChargeSuccessCreation = createSelector(
  getActiveCharge,
  (charge) => charge.successCreate,
);

const getActiveChargeDisableDesignation = createSelector(
  getActiveCharge,
  (charge) => charge.isDisableDesignation,
);

const getDeletedCharge = createSelector(
  localState,
  (state) => state.deletedChargeId,
);

const getIsLoadingDelete = createSelector(
  localState,
  (state) => state.isLoadingDelete,
);

const getActiveChargeId = createSelector(
  getActiveCharge,
  (charge) => charge.id,
);

const isFilledInfo = createSelector(
  getActiveChargeApplyTo,
  getActiveChargeMeasureBy,
  getActiveChargeSelectedChargeTitle,
  getActiveChargeDesignation,
  (applyTo, measureBy, charge, designation) => (!charge || !applyTo || !measureBy || !designation),
);

const getCompanies = createSelector(
  localState,
  (state) => state.shipmentParties.companies,
);

const getCompaniesForSelect = createSelector(
  getCompanies,
  (companies) => companies.map((item) => ({
    value: item.id,
    label: item.company?.name,
  })),
);

const getSelectedVendorCompanyId = createSelector(
  localState,
  (state) => state.shipmentParties.selectedVendorCompany,
);

const getSelectedVendorCompanyValue = createSelector(
  getCompanies,
  getSelectedVendorCompanyId,
  (companies, id) => companies.find((item) => item.company?.id === id)?.company?.name,
);

const getSelectedVendorCompany = createSelector(
  getCompanies,
  getSelectedVendorCompanyId,
  (companies, id) => companies.find((item) => item.company?.id === id),
);

const getSelectedCustomerCompanyId = createSelector(
  localState,
  (state) => state.shipmentParties.selectedCustomerCompany,
);

const getSelectedCustomerCompanyValue = createSelector(
  getCompanies,
  getSelectedCustomerCompanyId,
  (companies, id) => companies?.find((item) => item.company?.id === id)?.company?.name,
);

const getSelectedCustomerCompany = createSelector(
  getCompanies,
  getSelectedCustomerCompanyId,
  (companies, id) => companies.find((item) => item.company?.id === id),
);

const getGlobalLoadingData = createSelector(
  localState,
  (state) => state.globalLoadingData,
);

const getIsDocumentUploading = createSelector(
  localState,
  (state) => state.isDocumentUploading,
);

const getIsDeleteSuccess = createSelector(
  localState,
  (state) => state.deleteChargeSuccess,
);

const getIsExportTradeType = createSelector(
  localState,
  (state) => state.tradeType === EFreightIncotermsTrade.EXPORT,
);

export const shipmentActiveChargeSelectors = {
  getFullListLoading,
  getFullListError,
  getFullListCharges,
  getActiveChargeApplyTo,
  getActiveChargeCurrency,
  getChargesForDropdown,
  getActiveChargeSelectedChargeTitle,
  getActiveChargeSelectedChargeMeasure,
  getActiveChargeAPCostPerUnit,
  getActiveChargeAPNumberOfUnits,
  getActiveChargeARNumberOfUnits,
  getActiveChargeARCostPerUnit,
  getActiveChargeTotalAR,
  getActiveChargeTotalAP,
  getActiveChargeProfit,
  getActiveChargeErrors,
  getActiveChargeErrorCharge,
  preparedTreeData,
  getActiveChargeSelectedContainers,
  getActiveChargeSelectedContainersError,
  getActiveChargeSelectedChargeCode,
  getActiveChargeDescription,
  getActiveChargeIsLoadingCreate,
  getActiveChargeContainers,
  getActiveChargeSelectedChargeType,
  getActiveChargeMeasureBy,
  getActiveChargeDesignation,
  getActiveChargeId,
  isFilledInfo,
  getActiveChargeSelectedContainerType,
  getActiveChargeSavedContainer,
  getAPBudgetEdit,
  getARBudgetEdit,
  getAPBalanceEdit,
  getARBalanceEdit,
  getCompaniesForSelect,
  getSelectedVendorCompany,
  getSelectedCustomerCompany,
  getSelectedCustomerCompanyId,
  getSelectedVendorCompanyId,
  getSelectedVendorCompanyValue,
  getSelectedCustomerCompanyValue,
  getGlobalLoadingData,
  getActiveChargeDocuments,
  getIsDocumentUploading,
  getActiveChargeDisableDesignation,
  getCompanies,
  getDeletedCharge,
  getIsLoadingDelete,
  getActiveChargeSuccessCreation,
  getIsDeleteSuccess,
  getChargesForDropdownAccessorial,
  getActiveChargeRateId,
  getIsExportTradeType,
  getChargesForDrayage,
};
