import React from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { R } from 'shipment-operations/repository';
import {
  CutoffComponent,
} from './Cutoff.component';

export const CutoffContainer = observer(() => {
  const schedule = useSelector(R.selectors.shipmentTrackerRoutes.getSchedule);
  const terminalReleaseDate = schedule?.arrivalTime
    ? schedule?.arrivalTime.addDays(3).getDateDDMMMHHmm()
    : undefined;
  return (
    <CutoffComponent
      terminalReleaseDate={terminalReleaseDate}
      customsReleaseDate={terminalReleaseDate}
    />
  );
});
