import React from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ChangeRequestDrawerComponent,
} from 'shipment-operations/view/drawers/ChangeRequestDrawer/ChangeRequestDrawer.component';
import { ChangeRequestDrawerController } from 'shipment-operations/controllers/ChangeRequestDrawerController';
import { ChangeRequestStore } from 'shipment-operations/repository/mobxStores';

export const ChangeRequestDrawerContainer = observer(() => {
  const controller = useCurrentController<ChangeRequestDrawerController>();
  const store = useCurrentControllerStore<ChangeRequestStore>();

  return (
    <ChangeRequestDrawerComponent
      isLoading={store.state.isLoading}
      isSubmitting={store.state.isSubmitting}
      onClose={controller.closeDrawer}
      onSave={controller.submitForm}
      openFileUploaderDrawer={controller.openFileUploaderDrawer}
    />
  );
});
