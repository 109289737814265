import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { CargoDTM } from 'shipment-operations/models/dtm';
import { AlertBlock } from 'app-wrapper/view/components';
import {
  Commodity,
  CommodityHeader,
  CommodityRowsHeaderTitle,
  Warning,
  WarningIcon,
  WarningText,
  CommodityHeaderAlert,
} from 'shipment-operations/view/components/BookingDrawer/components/Commodity/Commodity.styled';

import {
  CommodityRowsHeader,
  CommodityRowsHeaderNoHazmat,
} from './Commodity.styled';
import { CommodityRow } from './components';

interface CommodityComponentProps {
  cargos: CargoDTM[]
  shouldHaveHazmats: boolean
  isHaveLimit?: boolean
  isShowAccountLimit?: boolean
  isHazmatErrorVisible: boolean
  hsCodeBEValidationMessage: string
  isPending: boolean
  isContainersValueWarningVisible: boolean;
  containersTotalValue: number,
}

export const CommodityComponent: FC<CommodityComponentProps> = ({
  cargos,
  isHazmatErrorVisible,
  isHaveLimit,
  isShowAccountLimit,
  hsCodeBEValidationMessage,
  shouldHaveHazmats,
  isPending,
  isContainersValueWarningVisible,
  containersTotalValue,
}) => {
  const { t } = useTranslation();

  const headerContent = useMemo(() => (
    <>
      <CommodityRowsHeaderTitle>
        {t('H.S. or Commodity Name *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Description')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Pack *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('QTY *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Weight kg *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Volume cbm')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Value *')}
      </CommodityRowsHeaderTitle>
    </>
  ), [t]);

  const alertText = useMemo(() => {
    if (isHaveLimit) {
      return t('You have outstanding credit balance that exceeds your current credit limit. If the balance is not  reduced before this shipment is confirmed, we will ask you to pay upfront for the shipment within the next 3 days after it’s confirmation. You will be notified once the corresponding invoice is available.');
    }

    return t('Please provide the payment for the shipment within the next 3 days after it’s confirmed. You will be notified once the corresponding invoice is available.');
  }, [isHaveLimit]);

  return isPending ? (
    <Skeleton />
  ) : (
    <Commodity>
      <CommodityHeader>
        {t('Commodity')}
      </CommodityHeader>

      {!!isShowAccountLimit && (
        <CommodityHeaderAlert>
          <AlertBlock type="warning" message={alertText} />
        </CommodityHeaderAlert>
      )}

      {isHazmatErrorVisible ? (
        <Warning>
          <WarningIcon />

          <WarningText>
            {t('At least one cargo must be filled with hazmat information')}
          </WarningText>
        </Warning>
      ) : null}

      {isContainersValueWarningVisible ? (
        <Warning
          style={{
            marginBottom: 16,
          }}
        >
          <WarningIcon />

          <WarningText>
            {t('Exceeded Limit of Commodity Value. You need to manually confirm booking with exceeded limit')}
            &nbsp;
            (${containersTotalValue.toFixed(2)}).
          </WarningText>
        </Warning>
      ) : null}

      {hsCodeBEValidationMessage ? (
        <Warning>
          <WarningIcon />

          <WarningText>
            {hsCodeBEValidationMessage}
          </WarningText>
        </Warning>
      ) : null}

      {shouldHaveHazmats ? (
        <CommodityRowsHeader>
          {headerContent}
        </CommodityRowsHeader>
      ) : (
        <CommodityRowsHeaderNoHazmat>
          {headerContent}
        </CommodityRowsHeaderNoHazmat>
      )}

      {cargos.map(((cargo) => (
        <CommodityRow
          key={String(cargo.id)}
          cargoId={cargo.id ? cargo.id : 0}
        />
      )))}
    </Commodity>
  );
};
