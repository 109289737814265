import React, { memo } from 'react';

import { PageComponent } from 'app-wrapper/view/routes/components';
import { createDocumentUploaderDrawerStore } from 'app-wrapper/repository/mobxStores/DocumentUploaderDrawerStore';
import {
  DocumentUploaderDrawerContainer,
} from 'app-wrapper/view/drawers/DocumentUploaderDrawer/DocumentUploaderDrawer.container';
import { DocumentUploaderDrawerController } from 'app-wrapper/controllers/DocumentUploaderDrawerController';

export const DocumentUploaderDrawerPage = memo(() => (
  <PageComponent
    ControllerClass={DocumentUploaderDrawerController}
    StoreFactory={() => {
      const store = createDocumentUploaderDrawerStore();
      return store;
    }}
  >
    <DocumentUploaderDrawerContainer />
  </PageComponent>
));
