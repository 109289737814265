import React, {
  ComponentClass,
  FC,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import momentBusiness from 'moment-business-days';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RadioButton } from 'app-wrapper/view/components/ant/Radio';

import {
  AlertBlock, Row, Col,
  RadioGroup,
} from 'app-wrapper/view/components';
import {
  useWindowSize,
} from 'app-wrapper/hooks';

import { FreightFromLocationStateDatePortDTM, FreightFromLocationStateDTM } from 'monetary/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';

import {
  AddressBlockOriginContainer,
} from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlockOrigin.container';
import {
  AddressBlockDestinationContainer,
} from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlockDestination.container';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { DRAYAGE_FEATURE_FLAG } from 'app-wrapper/constants/featureFlags';
import {
  AddressBlockRangePicker,
  RowAddress,
  RowAddressNotCollapsed,
  RowAddressBoth,
  RowAddressMsg,
  AddressBlockIncludeRelatedPort,
  AddressBlockIncludeRelatedPortCheckbox,
  AddressBlockIncludeRelatedPortText,
  AddressBlockRadioGroup,
  AddressBlockDatePicker,
} from './AddressBlock.styled';

interface IRelatedTarget {
  offsetParent?: {
    offsetParent?: {
      className?: string[]
    }
  }
}

export interface IAddressBlockComponentProps {
  isAllDisabled?: boolean
  isCollapsed?: boolean
  isIncludeRelatedPort?: boolean
  onChangeOriginTypeToPort: (isPort: boolean) => void
  onChangeIsIncludeRelatedPort: (isPort: boolean) => void
  onChangeDestinationTypeToPort: (isPort: boolean) => void
  origin?: FreightFromLocationStateDTM
  destination?: FreightFromLocationStateDTM
  onChangePortDateOrigin: (props: FreightFromLocationStateDatePortDTM) => void
  onChangePortDateDestination: (props: FreightFromLocationStateDatePortDTM) => void
  originErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  destinationErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  isSubmitVisited?: boolean
  onFocusPortDateOrigin: () => void
  onBlurPortDateOrigin: () => void
  onFocusPortDateDestination: () => void
  onBlurPortDateDestination: () => void
}

export const AddressBlockComponent: FC<IAddressBlockComponentProps> = ((props) => {
  const { isFullMediaWidth } = useWindowSize();
  const { t } = useTranslation();
  const isDrayageEnabled = useFeatureFlagEnabled(DRAYAGE_FEATURE_FLAG);

  const {
    isAllDisabled,
    isCollapsed,
    isIncludeRelatedPort,
    onChangeOriginTypeToPort,
    onChangeIsIncludeRelatedPort,
    onChangeDestinationTypeToPort,
    origin,
    originErrors,
    destination,
    destinationErrors,
    onChangePortDateOrigin,
    onChangePortDateDestination,
    isSubmitVisited,
    onFocusPortDateOrigin,
    onBlurPortDateOrigin,
    onFocusPortDateDestination,
    onBlurPortDateDestination,
  } = props;
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (isCollapsed) {
      setIsResponsive(false);
    } else {
      // old changed
      setIsResponsive(true);
    }
  }, [isCollapsed]);

  const originDateRef = useRef<
    InstanceType<ComponentClass<RangePickerProps>>
  >(null);
  const departureDateRef = useRef<
    InstanceType<ComponentClass<RangePickerProps>>
  >(null);

  const addWorkingDays = (date: Moment, days: number) => {
    let newDate = date.clone();
    const Saturday = momentBusiness().day('Saturday').weekday();
    const Sunday = momentBusiness().day('Sunday').weekday();

    if (newDate.day() === Saturday || newDate.day() === Sunday) {
      newDate = newDate.nextBusinessDay();
    }

    newDate = momentBusiness(newDate).businessAdd(days);

    // Saturday
    if (newDate.clone().add(1, 'd').day() === Saturday) {
      newDate = newDate.add(1, 'd');
    }
    // Sunday
    if (newDate.clone().add(1, 'd').day() === Sunday) {
      newDate = newDate.add(1, 'd');
    }

    return newDate;
  };

  const disabledDateDeparture = useCallback((current: moment.Moment): boolean => {
    const minDate = origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate) : moment().add(4, 'days');

    if (destination?.isPort && destination?.datePort?.earliestDate) {
      const minDateSecond = origin?.datePort?.earliestDate
        ? moment(origin?.datePort?.earliestDate)
        : moment(destination?.datePort?.earliestDate).subtract(1, 'day');
      const maxDate = moment(destination?.datePort?.earliestDate).add(34, 'days');

      return current && (current < minDateSecond.endOf('day') || (current > maxDate.endOf('day')));
    }

    if (!destination?.isPort) {
      const maxDays = origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate).add(0, 'd') : addWorkingDays(moment(), 3);
      return current && (current < maxDays.endOf('day'));
    }

    return current && current < minDate.endOf('day');
  }, [origin?.datePort?.earliestDate, destination?.datePort?.earliestDate]);
  const disabledDate = useCallback((current: moment.Moment): boolean => {
    if (origin?.isPort && origin?.datePort?.earliestDate) {
      const minDateSecond = moment(origin?.datePort?.earliestDate);
      const maxDate = moment(origin?.datePort?.earliestDate).add(34, 'days');

      return current && (current < minDateSecond.subtract(1, 'day').endOf('day') || (current > maxDate.endOf('day')));
    }

    if (!origin?.isPort) {
      const maxDays = addWorkingDays(moment(), 2);
      return current && (current < maxDays.endOf('day'));
    }

    return current && (current < moment().add(3, 'days').endOf('day'));
  }, [origin?.datePort?.earliestDate, origin?.isPort]);

  const originOnCalendarChange = useCallback(
    (dates, dateStrings) => {
      if (origin?.isPort) {
        onChangePortDateOrigin(FreightFromLocationStateDatePortDTM.fromPlain({
          earliestDate: dateStrings[0] || '',
          latestDate: dateStrings[1] || '',
        }));
      } else {
        onChangePortDateOrigin(FreightFromLocationStateDatePortDTM.fromPlain({
          earliestDate: dateStrings || '',
        }));
      }
    },
    [onChangePortDateOrigin, origin?.isPort],
  );

  const departureOnCalendarChange = useCallback(
    (dates, dateStrings) => {
      if (destination?.isPort) {
        onChangePortDateDestination(FreightFromLocationStateDatePortDTM.fromPlain({
          earliestDate: dateStrings[0] || '',
          latestDate: dateStrings[1] || '',
        }));
      } else {
        onChangePortDateDestination(FreightFromLocationStateDatePortDTM.fromPlain({
          earliestDate: dateStrings || '',
        }));
      }
    },
    [onChangePortDateDestination, destination?.isPort],
  );

  const originTypeToPortOnChangeHandler = useCallback(
    (e) => {
      onChangeOriginTypeToPort(e.target.value === '1');
    },
    [],
  );

  const onChangeIsIncludeRelatedPortHandler = useCallback(
    (e: CheckboxChangeEvent) => {
      onChangeIsIncludeRelatedPort(e.target.checked);
    },
    [],
  );

  const departureTypeToPortOnChangeHandler = useCallback(
    (e) => {
      onChangeDestinationTypeToPort(e.target.value === '1');
    },
    [],
  );

  const isErrorDateOrigin = !!originErrors.datePort?.error?.message
    && ((originErrors.datePort?.error?.isVisited
      && destinationErrors.datePort?.error?.isVisited)
      || isSubmitVisited);

  const isErrorDateDestination = useMemo(
    () => !!destinationErrors.datePort?.error?.message
      && ((originErrors.datePort?.error?.isVisited
        && destinationErrors.datePort?.error?.isVisited)
        || isSubmitVisited),
    [originErrors.datePort?.error, destinationErrors.datePort?.error, isSubmitVisited],
  );

  const onBlurPortDateOriginHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const relatedTarget = event?.relatedTarget as IRelatedTarget;

      if (relatedTarget === null
        || !relatedTarget?.offsetParent?.offsetParent?.className?.includes('AddressBlockRangePickerOrigin')) {
        onBlurPortDateOrigin();
      }
    },
    [onBlurPortDateOrigin],
  );

  const sizeSelect: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);

  const firsDateComponent = useMemo(() => (
    origin?.isPort ? (
      <AddressBlockRangePicker
        className="AddressBlockRangePickerOrigin"
        isError={isErrorDateOrigin}
        value={[origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate) : null, origin?.datePort?.latestDate ? moment(origin?.datePort?.latestDate) : null]}
        placeholder={[`${t('Earliest date')} *`, `${t('Latest date')}`]}
        disabledDate={disabledDate}
        onFocus={onFocusPortDateOrigin}
        onBlur={onBlurPortDateOriginHandler}
        ref={originDateRef}
        size={sizeSelect}
        onCalendarChange={originOnCalendarChange}
        disabled={isAllDisabled}
        getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
      />
    )
      : (
        <AddressBlockDatePicker
          className="AddressBlockDatePickerOrigin"
          isError={isErrorDateOrigin}
          value={origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate) : null}
          placeholder={t('Cargo ready date *')}
          disabledDate={disabledDate}
          onChange={originOnCalendarChange}
          onFocus={onFocusPortDateOrigin}
          onBlur={onBlurPortDateOriginHandler}
          size={sizeSelect}
          disabled={isAllDisabled}
        />
      )
  ), [
    origin?.isPort,
    origin?.datePort?.earliestDate,
    origin?.datePort?.latestDate,
    isAllDisabled,
    isErrorDateOrigin,
    disabledDate, originOnCalendarChange, t]);

  const onBlurPortDateDestinationHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const relatedTarget = event?.relatedTarget as IRelatedTarget;

      if (relatedTarget === null
        || !relatedTarget?.offsetParent?.offsetParent?.className?.includes('AddressBlockRangePickerDestination')) {
        onBlurPortDateDestination();
      }
    },
    [onBlurPortDateDestination],
  );

  const destinationDateComponent = useMemo(() => (
    destination?.isPort ? (
      <AddressBlockRangePicker
        className="AddressBlockRangePickerDestination"
        isError={isErrorDateDestination}
        value={[destination?.datePort?.earliestDate ? moment(destination?.datePort?.earliestDate) : null, destination?.datePort?.latestDate ? moment(destination?.datePort?.latestDate) : null]}
        placeholder={[`${t('Earliest date')}`, `${t('Latest date')}`]}
        disabledDate={disabledDateDeparture}
        size={sizeSelect}
        ref={departureDateRef}
        onFocus={onFocusPortDateDestination}
        onBlur={onBlurPortDateDestinationHandler}
        onCalendarChange={departureOnCalendarChange}
        disabled={isAllDisabled}
        getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
      />
    ) : (
      <AddressBlockDatePicker
        className="AddressBlockDatePickerDestination"
        isError={isErrorDateDestination}
        value={destination?.datePort?.earliestDate ? moment(destination?.datePort?.earliestDate) : null}
        placeholder={t('Required delivery date')}
        disabledDate={disabledDateDeparture}
        onChange={departureOnCalendarChange}
        onFocus={onFocusPortDateDestination}
        onBlur={onBlurPortDateDestinationHandler}
        size={sizeSelect}
        disabled={isAllDisabled}
      />
    )
  ), [
    destination?.isPort,
    destination?.datePort?.earliestDate,
    destination?.datePort?.latestDate,
    isAllDisabled,
    isErrorDateDestination,
    disabledDateDeparture, departureOnCalendarChange, t]);

  const isErrorDateMessageBox = useMemo(
    () => (!!destinationErrors.datePort?.error?.message
      && (destinationErrors.datePort?.error?.isVisited)
      && (originErrors.datePort?.error?.message)
      && (originErrors.datePort?.error?.isVisited))
      || (isSubmitVisited && destinationErrors.datePort?.error?.message && originErrors.datePort?.error?.message),
    [destinationErrors.datePort?.error, originErrors.datePort?.error, isSubmitVisited],
  );

  const MainRow = useMemo(() => (isCollapsed ? RowAddressNotCollapsed : RowAddress), [isCollapsed]);
  return (
    <MainRow className="FreightQuoteFormRequest__Row__col_Address">
      <RowAddressBoth className="FreightQuoteFormRequest__Row__col_both_col">
        <Col span={isResponsive ? 12 : 24} className="FreightQuoteFormRequest__Row__col_left">
          <Row justify="space-between" className="FreightQuoteFormRequest__Row__col_both_col__title">
            <div>
              <Typography.Title level={5}>{t('Origin')}</Typography.Title>
            </div>
            {isDrayageEnabled ? (
              <AddressBlockRadioGroup>
                <RadioGroup
                  size="small"
                  onChange={originTypeToPortOnChangeHandler}
                  disabled={isAllDisabled}
                  value={`${origin?.isPort ? '1' : '0'}`}
                >
                  <RadioButton value="0">{t('Door')}</RadioButton>
                  <RadioButton value="1">{t('Port')}</RadioButton>
                </RadioGroup>

              </AddressBlockRadioGroup>
            ) : null}
          </Row>
          <Row className="FreightQuoteFormRequest__Row__col FreightQuoteFormRequest__Row__col_first">
            <Col span={24}>
              <AddressBlockOriginContainer />
            </Col>
          </Row>

          <Row className={`${isFullMediaWidth ? 'contentFullWidth' : ''} FreightQuoteFormRequest__Row__col FreightQuoteFormRequest__Row__col_second`}>
            <Col span={24}>
              {firsDateComponent}
            </Col>
          </Row>
        </Col>

        <Col span={isResponsive ? 12 : 24} className="FreightQuoteFormRequest__Row__col_right">
          <Row justify="space-between" className="FreightQuoteFormRequest__Row__col_right__title">
            <div>
              <Typography.Title level={5}>{t('Destination')}</Typography.Title>
            </div>

            {isDrayageEnabled ? (
              <AddressBlockRadioGroup>
                <RadioGroup
                  size="small"
                  onChange={departureTypeToPortOnChangeHandler}
                  disabled={isAllDisabled}
                  value={`${destination?.isPort ? '1' : '0'}`}
                >
                  <RadioButton value="0">{t('Door')}</RadioButton>
                  <RadioButton value="1">{t('Port')}</RadioButton>
                </RadioGroup>

              </AddressBlockRadioGroup>
            ) : null}
          </Row>
          <Row className="FreightQuoteFormRequest__Row__col_first">
            <Col span={24}>
              <AddressBlockDestinationContainer />
            </Col>
          </Row>

          <Row className={`${(isFullMediaWidth && 'contentFullWidth') || ''} FreightQuoteFormRequest__Row FreightQuoteFormRequest__Row__col_second`}>
            <Col span={24}>
              {destinationDateComponent}
            </Col>
          </Row>
        </Col>
      </RowAddressBoth>
      {
        isErrorDateMessageBox && (
          <RowAddressMsg className="FreightQuoteFormRequest__Row__msg">
            <Col span={24}><AlertBlock type="warning" message={t('selectOrigin')} /></Col>
          </RowAddressMsg>
        )
      }

      {destination?.isPort && origin?.isPort
        ? (
          <AddressBlockIncludeRelatedPort>
            <AddressBlockIncludeRelatedPortCheckbox
              checked={isIncludeRelatedPort}
              onChange={onChangeIsIncludeRelatedPortHandler}
              disabled={isAllDisabled}
            >
              <AddressBlockIncludeRelatedPortText>{t('Include Related Ports')}</AddressBlockIncludeRelatedPortText>
            </AddressBlockIncludeRelatedPortCheckbox>
          </AddressBlockIncludeRelatedPort>
        ) : null}
    </MainRow>
  );
});
