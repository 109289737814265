import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';

import { PermissionAttributePolicy, ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { hasAccess } from 'app-wrapper/utils';
import { ShipmentOverviewComponent } from './ShipmentOverview.component';

const ShipmentOverviewContainer: FC = () => {
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const permissions = useSelector(R.selectors.shipment.getDashboardsPermissions);
  const permissionsOverview = useSelector(R.selectors.shipment.getOverviewPermissions);
  const isShowWarningCustomer = useSelector(R.selectors.shipment.getIsBookingStatusWarning);
  const isMismatchedStatus = useSelector(R.selectors.shipment.getIsMismatchedStatus);
  const isLoading = useSelector(R.selectors.overview.getLoadingStatus);
  const withMismatches = useSelector(R.selectors.shipmentChanges.getWithMismatches);
  const isMaersk = useSelector(R.selectors.shipment.getIsMaersk);
  const withMisMatches = useSelector(R.selectors.shipmentChanges.getWithMismatches);
  const shouldRefresh = useSelector(R.selectors.shipmentChanges.getShouldRefresh);
  const isRequestTheSame = useSelector(R.selectors.shipment.getIsRequestTheSameWithShipment);
  const isSIRequestTheSame = useSelector(R.selectors.shipment.getSiRequestTheSameWithShipment);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);

  const isMaerskWarning = useMemo(() => isMaersk && hasAccess(permissions, PermissionAttributePolicy.WRITE) && (shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED || shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED), [isMaersk, shipment?.bookingStatus, shouldRefresh]);
  const partnerWarning = useMemo(() => withMisMatches && hasAccess(permissions, PermissionAttributePolicy.WRITE) && (shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED), [withMisMatches, shipment?.bookingStatus, shouldRefresh]);
  const isAbleToSubmitDrayageInformation = hasAccess(permissionsOverview?.drayageDrawerInformationSubmission, PermissionAttributePolicy.WRITE);

  return (
    <ShipmentOverviewComponent
      loadData={UC.overview.loadData}
      clear={UC.overview.reset}
      toggleMismatchDrawer={appUC.drawer.openShipmentMismatches}
      toggleChangesDrawer={appUC.drawer.openShipmentChanges}
      toggleMaerskDrawer={appUC.drawer.openMaerskChanges}
      shipment={shipment}
      permissions={permissions}
      isShowWarning={isMaerskWarning || isShowWarningCustomer || partnerWarning}
      isShowMismatchWarning={isMismatchedStatus && withMismatches}
      isMaersk={isMaersk}
      partnerWarning={partnerWarning}
      shouldRefresh={shouldRefresh}
      isRequestTheSame={isRequestTheSame}
      isLoading={isLoading}
      isDestinationPartner={isDestinationPartner}
      isSIRequestTheSame={isSIRequestTheSame}
      isAbleToSubmitDrayageInformation={isAbleToSubmitDrayageInformation}
      openDrayageDrawer={appUC.drawer.openBookingDrayageDrawer}
    />
  );
};

export {
  ShipmentOverviewContainer as ShipmentOverview,
};
