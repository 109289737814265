import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Popover from 'antd/es/popover';

import { Button } from 'app-wrapper/view/components';
import {
  ContainerBasicTypes,
  ContainerReeferTypes,
  ContainerReeferTypesArray,
  ContainerReeferTypesNamesLongConst,
  ContainerUsualTypes,
  ContainerUsualTypesArray,
  ContainerUsualTypesNamesLongConst,
} from 'shipment-operations/constants';
import {
  PopoverContainer,
} from 'shipment-operations/view/pages/ShipmentOverview/components/ShipmentOverviewTitle/ShipmentOverviewTitle.styled';
import { DisableButton } from 'shipment-operations/view/pages/ShipmentContainers/components/ShipmentContainersTitle/ShipmentContainersTitle.styled';

interface IShipmentContainersAddContainerModalContainerProps {
  isLoading: boolean
  isDisabled: boolean
  containerBasicType?: ContainerBasicTypes
  onAddHandler: (shipmentId: string | undefined, type: ContainerReeferTypes | ContainerUsualTypes) => void
  openChangeRequestDrawer: () => void
  shipmentId: string | undefined
  disable: boolean
}

export const ShipmentContainersAddContainerModalComponent: FC<IShipmentContainersAddContainerModalContainerProps> = ({
  isLoading,
  isDisabled,
  containerBasicType,
  onAddHandler,
  disable,
  shipmentId,
  openChangeRequestDrawer,
}) => {
  const { t } = useTranslation();

  const onAdd = useCallback((_shipmentId: string | undefined, type: ContainerReeferTypes | ContainerUsualTypes) => () => {
    onAddHandler(_shipmentId, type);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onOpenChangeRequestDrawer = useCallback(() => {
    openChangeRequestDrawer();
  }, []);

  const isReeferType = containerBasicType === ContainerBasicTypes.REEFER;

  const menu = useMemo(() => (
    <Menu>
      {(isReeferType ? ContainerReeferTypesArray : ContainerUsualTypesArray).map((item) => (
        <Menu.Item key={item}>
          <Button
            size="small"
            type="text"
            onClick={onAdd(shipmentId, item)}
          >
            {(isReeferType
              ? ContainerReeferTypesNamesLongConst[item as ContainerReeferTypes]
              : ContainerUsualTypesNamesLongConst[item as ContainerUsualTypes])}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  ), [isReeferType]);

  return (disable) ? (
    <Popover
      trigger="hover"
      content={<PopoverContainer>{t('changesForbidden')}</PopoverContainer>}
      placement="left"
      overlayInnerStyle={{
        backgroundColor: 'rgba(32, 44, 60, 1)',
      }}
    >
      <DisableButton>Add container</DisableButton>
    </Popover>
  ) : (
    <>
      {/* <Button onClick={onOpenChangeRequestDrawer}> */}
      {/*   {t('Change request')} */}
      {/* </Button> */}
      <Dropdown overlay={menu} disabled={isDisabled}>
        <Button
          icon={<PlusOutlined />}
          size="middle"
          loading={isLoading}
        >
          {t('addContainer')}
        </Button>
      </Dropdown>
    </>
  );
};
