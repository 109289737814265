import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R as AppR } from 'app-wrapper/repository';
import { R } from 'shipment-operations/repository';

import { ShipmentMenuComponent } from './ShipmentMenu';

const ShipmentMenu: FC = () => {
  const shipmentByIdStats = useSelector(AppR.selectors.overview.getShipmentByIdStats);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const isLoading = useSelector(R.selectors.shipment.getLoadingState);

  return (
    <ShipmentMenuComponent
      shipmentStats={shipmentByIdStats}
      shipment={shipment}
      isLoading={isLoading}
    />
  );
};

const ShipmentMenuCached = memo(ShipmentMenu);

export { ShipmentMenuCached as ShipmentMenu };
