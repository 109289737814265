import { useLocation } from 'react-router';
import { EXPORT, IMPORT } from 'shipment-operations/constants';

const useIsExportOrImport = () => {
  const { pathname } = useLocation();
  const isExport = pathname.includes(EXPORT);
  const isImport = pathname.includes(IMPORT);
  const isExportOrImport = isExport || isImport;

  return { isExport, isImport, isExportOrImport };
};

export { useIsExportOrImport };
