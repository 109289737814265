import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { StepsHeaderComponent } from './StepsHeader.component';

const StepsHeaderContainer = () => {
  const currentStep = useSelector(R.selectors.bookingWizard.getCurrentStep);
  const isCustomerOrg = useSelector(R.selectors.bookingWizard.getIsCurrentOrganizationCustomer);
  const isSuppliersInformationMissing = useSelector(R.selectors.bookingWizard.getIsLoadingMethodsInformationMissing);

  return (
    <StepsHeaderComponent
      currentStep={currentStep}
      isCustomerOrg={isCustomerOrg}
      isSuppliersInformationMissing={isSuppliersInformationMissing}
    />
  );
};

export { StepsHeaderContainer as StepsHeader };
