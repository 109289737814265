import { action, computed } from 'mobx';
import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';
import { InputStore } from 'proto/BaseMobxStore/InputStore';

interface FormInputs {
  text?: InputStore<string>
  files?: InputStore<string>[]
}

interface IChangeRequestControllerState {
  containerId?: number
  isSubmitting: boolean
  type: string
  form: FormInputs
}

const initialState: IControllerState<IChangeRequestControllerState> = {
  isLoading: true,
  isSubmitting: false,
  type: '',
  form: {
    text: new InputStore(),
    files: [new InputStore('')],
  },
};

export class ChangeRequestStore extends ControllerStore<IControllerState<IChangeRequestControllerState>> {
  @action
  setType(type: string) {
    this.state.type = type;
  }

  @action
  setSubmitting(isSubmitting: boolean) {
    this.state.isSubmitting = isSubmitting;
  }

  @action
  setForm(formInputs: FormInputs) {
    this.state.form = formInputs;
  }

  @computed
  get hasError() {
    return Object.values(this.state.form).some((input: InputStore<any>) => input.hasError);
  }

  get containerId() {
    return this.state.containerId;
  }
}

export const createChangeRequestStore = (_initialState?: IControllerState<IChangeRequestControllerState>) => new ChangeRequestStore(_initialState || initialState);
