import {
  ValidateNested,
  IsBoolean,
  IsNumber,
  IsString,
  IsOptional,
  IsDefined,
  IsArray,
  IsEnum,
} from 'class-validator';
import { Type } from 'class-transformer';
import moment from 'moment';

import { DateDtm } from 'app-wrapper/models/dtm';
import {
  EFreightIncoterms, EFreightIncotermsTrade, EFreightLoadType, ETransportationType,
  freightTypeLocation,
} from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';

import { FreightFromCommodityStateDTM, IFreightFromCommodityStateDTM } from './freightFormState.dtm';
import {
  FreightSelectFieldCoordinatesDTM, FreightSelectFieldCountryDTM, IFreightSelectFieldCoordinatesDTM, IFreightSelectFieldCountryDTM,
} from './freightSelectField.dtm';
import { AdditionalServiceCheckedDTM, IQuotaBookmark, QuotaBookmark } from '../Quotas';

const QUOTA_DATE_FORMAT = 'D MMM YYYY, HH:mm';

export interface IFreightQuotaTransportationTransportDTM {
  number?: string;
  name: string;
  type: string;
}

export class FreightQuotaTransportationTransportDTM extends BaseDTM<IFreightQuotaTransportationTransportDTM> {
  @IsOptional()
  @IsString()
  number?: string;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  type: string;
}

interface IFreightQuotaTransportationScheduleDTM {
  type: string;
  departureTime: string;
  arrivalTime: string;
  transitDuration: number;
  terminalCutOff?: string;
  documentCutOff?: string;
  dropTime?: string;
  pickupTime?: string;
  pickupFromTime?: string;
  pickupToTime?: string;
  fullPickupTime?: string;
  deliveryTime?: string;
  fullReturnFromTime?: string;
  fullReturnToTime?: string;
  hazmatCutOff?: string;
}

export class FreightQuotaTransportationScheduleDTM extends BaseDTM<IFreightQuotaTransportationScheduleDTM> {
  @IsDefined()
  @IsString()
  type: string;

  @IsDefined()
  @IsString()
  departureTime: string;

  @IsDefined()
  @IsString()
  arrivalTime: string;

  @IsDefined()
  @IsNumber()
  transitDuration: number;

  @IsOptional()
  @IsString()
  terminalCutOff: string;

  @IsOptional()
  @IsString()
  documentCutOff: string;

  @IsOptional()
  @IsString()
  dropTime: string;

  @IsOptional()
  @IsString()
  pickupTime: string;

  @IsOptional()
  @IsString()
  pickupFromTime: string;

  @IsOptional()
  @IsString()
  pickupToTime: string;

  @IsOptional()
  @IsString()
  fullPickupTime: string;

  @IsOptional()
  @IsString()
  deliveryTime: string;

  @IsOptional()
  @IsString()
  fullReturnFromTime: string;

  @IsOptional()
  @IsString()
  fullReturnToTime: string;

  @IsOptional()
  @IsString()
  hazmatCutOff: string;
}

export interface IFreightQuotaTransportationDTM {
  id: number;
  transport?: IFreightQuotaTransportationTransportDTM
  schedule?: IFreightQuotaTransportationScheduleDTM
  arrivalTerminal?: string;
  departureTerminal?: string;
  legPhase?: string;
  transportLeg?: number;
  containers?: number[];
  voyageCode?: string | null
}

export class FreightQuotaTransportationDTM extends BaseDTM<IFreightQuotaTransportationDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaTransportationTransportDTM)
  transport?: FreightQuotaTransportationTransportDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaTransportationScheduleDTM)
  schedule?: FreightQuotaTransportationScheduleDTM;

  @IsOptional()
  @IsString()
  arrivalTerminal?: string;

  @IsOptional()
  @IsString()
  departureTerminal?: string;

  @IsOptional()
  @IsString()
  legPhase?: string;

  @IsOptional()
  @IsArray()
  containers?: number[];

  @IsOptional()
  @IsNumber()
  transportLeg?: number;

  @IsOptional()
  @IsString()
  voyageCode: string | null
}

export interface IFreightQuotaContentSchedulesChargesChargeCodeDTM {
  customId?: string
  code?: string
  description?: string
  originalDescription?: string
  mode?: string
  occurrence?: string
  type?: string
  subType?: string
}

export class FreightQuotaContentSchedulesChargesChargeCodeDTM extends BaseDTM<IFreightQuotaContentSchedulesChargesChargeCodeDTM> {
  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsString()
  originalDescription?: string;

  @IsOptional()
  @IsString()
  mode?: string;

  @IsOptional()
  @IsString()
  occurrence?: string;

  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsString()
  subType?: string;
}

export interface IFreightQuotaContentSchedulesChargesApplianceRangeDTM {
  minValue?: number
  maxValue?: number
}

export class FreightQuotaContentSchedulesChargesApplianceRangeDTM extends BaseDTM<IFreightQuotaContentSchedulesChargesApplianceRangeDTM> {
  @IsOptional()
  @IsNumber()
  minValue?: number;

  @IsOptional()
  @IsNumber()
  maxValue?: number;
}

export interface IMetaData {
  originalDescription?: string
  excludedCommodities?: string[]
}

export class MetaDataDTM implements IMetaData {
  @IsString()
  @IsOptional()
  originalDescription?: string;

  @IsArray()
  @IsOptional()
  excludedCommodities?: string[];
}

export interface IFreightQuotaContentSchedulesChargesDTM {
  unitType?: string
  subjectTo?: string
  currency?: string
  costPerUnit?: number
  numberOfUnits?: number
  totalCost?: number
  buyTotalCost?: number
  containerId?: number
  contractId?: number
  transportationIds?: number[]
  applied?: boolean
  chargeCode?: IFreightQuotaContentSchedulesChargesChargeCodeDTM
  measureBy?: string
  designation?: string
  priceBy?: string
  applianceRange?: IFreightQuotaContentSchedulesChargesApplianceRangeDTM
  customQuantity?: number
  customTotal?: number
  customContainerType?: string
  customUnitType?: string
  customAppliance?: string
  metadata?: IMetaData
}

export class FreightQuotaContentSchedulesChargesDTM extends BaseDTM<IFreightQuotaContentSchedulesChargesDTM> {
  @IsOptional()
  @IsString()
  unitType?: string;

  @IsOptional()
  @IsString()
  subjectTo?: string;

  @IsOptional()
  @IsString()
  currency?: string;

  @IsOptional()
  @IsNumber()
  costPerUnit?: number;

  @IsOptional()
  @IsNumber()
  numberOfUnits?: number;

  @IsOptional()
  @IsNumber()
  totalCost?: number;

  @IsOptional()
  @IsNumber()
  buyTotalCost?: number;

  @IsOptional()
  @IsNumber()
  containerId?: number;

  @IsOptional()
  @IsNumber()
  contractId?: number;

  @IsOptional()
  @IsArray()
  transportationIds?: number[];

  @IsOptional()
  @IsBoolean()
  applied?: boolean;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentSchedulesChargesChargeCodeDTM)
  chargeCode?: FreightQuotaContentSchedulesChargesChargeCodeDTM;

  @IsOptional()
  @IsString()
  measureBy?: string;

  @IsOptional()
  @IsString()
  designation?: string;

  @IsOptional()
  @IsString()
  priceBy?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentSchedulesChargesApplianceRangeDTM)
  applianceRange?: FreightQuotaContentSchedulesChargesApplianceRangeDTM;

  @IsOptional()
  @IsNumber()
  customQuantity?: number;

  @IsOptional()
  @IsNumber()
  customTotal?: number;

  @IsOptional()
  @IsString()
  customContainerType?: string;

  @IsOptional()
  @IsString()
  customUnitType?: string;

  @ValidateNested()
  @IsOptional()
  metadata?: MetaDataDTM;
}

export interface IFreightQuotaContentSchedulePlanDTM {
  id: number;
  containerId: number;
}

export class FreightQuotaContentSchedulePlanDTM extends BaseDTM<IFreightQuotaContentSchedulePlanDTM> {
  @IsNumber()
  id: number;

  @IsNumber()
  containerId: number;
}

export interface IFreightQuotaContentSchedulesDTM {
  id?: number
  oceanScheduleId?: number
  recommended?: boolean
  departureTime?: string
  arrivalTime?: string
  pickupFromTime?: string
  totalDuration?: number
  terminalCutOff?: string
  documentCutOff?: string
  hazmatCutOff?: string
  charges?: IFreightQuotaContentSchedulesChargesDTM[]
  transportations?: IFreightQuotaTransportationDTM[]
  plans: IFreightQuotaContentSchedulePlanDTM[]
}

export class FreightQuotaContentSchedulesDTM extends BaseDTM<IFreightQuotaContentSchedulesDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsNumber()
  oceanScheduleId?: number;

  @IsOptional()
  @IsBoolean()
  recommended?: boolean;

  @IsOptional()
  @IsString()
  departureTime?: string;

  @IsOptional()
  @IsString()
  arrivalTime?: string;

  @IsOptional()
  @IsString()
  pickupFromTime?: string;

  @ValidateNested()
  @Type(() => FreightQuotaContentSchedulePlanDTM)
  plans: FreightQuotaContentSchedulePlanDTM[]

  @IsOptional()
  @IsNumber()
  totalDuration?: number;

  @IsOptional()
  @IsString()
  terminalCutOff?: string;

  @IsOptional()
  @IsString()
  documentCutOff?: string;

  @IsOptional()
  @IsString()
  hazmatCutOff?: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentSchedulesChargesDTM)
  charges?: FreightQuotaContentSchedulesChargesDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaTransportationDTM)
  transportations?: FreightQuotaTransportationDTM[];
}

export interface IFreightQuotaContentContractsDTM {
  id?: number
  number?: string
  name?: string
  scac?: string
}

export class FreightQuotaContentContractsDTM extends BaseDTM<IFreightQuotaContentContractsDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  number?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  scac?: string;
}

export interface IFreightQuotaContentCommodity {
  code: string;
  description: string;
  imoClass?: string;
  unNumber?: string;
  value?: number;
}

export class FreightQuotaContentCommodity extends BaseDTM<IFreightQuotaContentCommodity> implements IFreightQuotaContentCommodity {
  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  description: string;

  @IsOptional()
  @IsString()
  imoClass?: string;

  @IsOptional()
  @IsString()
  unNumber?: string;

  @IsOptional()
  @IsNumber()
  value?: number;
}

export interface IFreightQuotaContentContainerDTM {
  id?: number
  type?: string
  ownContainer?: boolean
  rateId?: number
  weight?: number
  volume?: number
  commodities?: IFreightQuotaContentCommodity[]
}

export class FreightQuotaContentContainerDTM extends BaseDTM<IFreightQuotaContentContainerDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsBoolean()
  ownContainer?: boolean;

  @IsOptional()
  @IsNumber()
  rateId?: number;

  @IsOptional()
  @IsNumber()
  weight?: number;

  @IsOptional()
  @IsNumber()
  volume?: number;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentCommodity)
  commodities?: FreightQuotaContentCommodity[];
}

export interface IQuotaServiceByIdContentRoutesLegsLocationDTM {
  type?: string
  id?: number
  placeId?: string
  country?: IFreightSelectFieldCountryDTM
  state?: IFreightSelectFieldCountryDTM
  city?: string
  name?: string
  address?: string
  postalCode?: string
  code?: string
  timeZoneId?: string
  coordinates?: IFreightSelectFieldCoordinatesDTM
}

export class QuotaServiceByIdContentRoutesLegsLocationDTM extends BaseDTM<IQuotaServiceByIdContentRoutesLegsLocationDTM> {
  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  placeId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCountryDTM)
  country?: FreightSelectFieldCountryDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCountryDTM)
  state?: FreightSelectFieldCountryDTM;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  address?: string;

  @IsOptional()
  @IsString()
  postalCode?: string;

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  timeZoneId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCoordinatesDTM)
  coordinates?: FreightSelectFieldCoordinatesDTM;

  public getLocationName = () => `${this.name}${this.country?.code ? `, ${this.country?.code}` : ''}`;

  getAddressToString = () => {
    let fullAddress = this.name || '';
    const countryCode = this.country?.code || '';

    if (countryCode) {
      fullAddress += fullAddress ? `, ${countryCode}` : countryCode;
    }

    return fullAddress;
  }
}

export interface IFreightQuotaContentRoutesLocationDTM {
  type?: string
  id?: number
  placeId?: string
  country?: IFreightSelectFieldCountryDTM
  state?: IFreightSelectFieldCountryDTM
  city?: string
  name?: string
  subdivisionName?: string
  code?: string
  address?: string
  postalCode?: string
  timeZoneId?: string
  coordinates?: IFreightSelectFieldCoordinatesDTM
}

export class FreightQuotaContentRoutesLocationDTM extends BaseDTM<IFreightQuotaContentRoutesLocationDTM> {
  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  placeId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCountryDTM)
  country?: FreightSelectFieldCountryDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCountryDTM)
  state?: FreightSelectFieldCountryDTM;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  subdivisionName?: string;

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  address?: string;

  @IsOptional()
  @IsString()
  postalCode?: string;

  @IsOptional()
  @IsString()
  timeZoneId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCoordinatesDTM)
  coordinates?: FreightSelectFieldCoordinatesDTM;

  getAddressToString = () => {
    let fullAddress = this.name || '';
    const countryCode = this.country?.code || '';
    let doorName = '';
    const isDoor = this.type === freightTypeLocation.DOOR;

    if (countryCode) {
      fullAddress += fullAddress ? `, ${countryCode}` : countryCode;
    }

    if (isDoor) {
      doorName = `${this?.city || ''}`;

      doorName += doorName ? `, ${this?.state?.code || ''}` : `${this?.state?.code || ''}`;

      doorName += doorName ? `, ${this?.country?.code || ''}` : `${this?.country?.code || ''}`;
    }

    return isDoor ? doorName : fullAddress;
  }
}

export interface IFreightQuotaContentRouteLegDTM {
  id: number;
  phase: string;
  arrivalLocation: IQuotaServiceByIdContentRoutesLegsLocationDTM;
  departureLocation: IQuotaServiceByIdContentRoutesLegsLocationDTM;
}

export class FreightQuotaContentRouteLegDTM extends BaseDTM<IFreightQuotaContentRouteLegDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  phase: string;

  @IsDefined()
  @ValidateNested()
  @Type(() => QuotaServiceByIdContentRoutesLegsLocationDTM)
  arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => QuotaServiceByIdContentRoutesLegsLocationDTM)
  departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM;
}

export interface IFreightQuotaContentRoutesDTM {
  origin?: IFreightQuotaContentRoutesLocationDTM
  destination?: IFreightQuotaContentRoutesLocationDTM
  legs?: IFreightQuotaContentRouteLegDTM[]
  containerId?: number
  containerIds?: number[]
}

export class FreightQuotaContentRoutesDTM extends BaseDTM<IFreightQuotaContentRoutesDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentRoutesLocationDTM)
  origin?: FreightQuotaContentRoutesLocationDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentRoutesLocationDTM)
  destination?: FreightQuotaContentRoutesLocationDTM;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentRouteLegDTM)
  legs?: FreightQuotaContentRouteLegDTM[];

  @IsOptional()
  @IsNumber()
  containerId?: number;

  @IsOptional()
  @IsArray()
  containerIds?: number[];
}

export interface IFreightQuotaContentContainerRequestsLocationDTM {
  type?: string
  location?: IFreightQuotaContentRoutesLocationDTM
  earliestDate?: string
  latestDate?: string
  pickupTime?: string
  dropTime?: string
}

export class FreightQuotaContentContainerRequestsLocationDTM extends BaseDTM<IFreightQuotaContentContainerRequestsLocationDTM> {
  @IsOptional()
  @IsString()
  type: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentRoutesLocationDTM)
  location?: FreightQuotaContentRoutesLocationDTM;

  @IsOptional()
  @IsString()
  earliestDate?: string;

  @IsOptional()
  @IsString()
  latestDate?: string;

  @IsOptional()
  @IsString()
  pickupTime?: string;

  @IsOptional()
  @IsString()
  dropTime?: string;

  getDoorFullAddress = () => {
    const { location } = this;
    const address = `${location?.address ? `${location?.address}` : ''}`;
    const city = `${location?.city ? `${address && ', '}${location?.city}` : ''}`;
    const state = `${location?.state?.code ? `${(address || city) && ' '}(${location?.state?.code})` : ''}`;
    const countryName = `${location?.country?.name ? `${(address || city || state) && ', '}${location?.country?.name}` : ''}`;
    const fullName = `${address}${city}${state}${countryName}`;

    return fullName;
  }
}

export interface IFreightQuotaContentContainerRequestsContainerDTM {
  id?: number
  type?: string
  ownContainer?: boolean
  rateId?: number
  commodities?: IFreightFromCommodityStateDTM[]
  weight?: number
  volume?: number
}

export class FreightQuotaContentContainerRequestsContainerDTM extends BaseDTM<IFreightQuotaContentContainerRequestsContainerDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsBoolean()
  ownContainer?: boolean;

  @IsOptional()
  @IsNumber()
  rateId?: number;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightFromCommodityStateDTM)
  commodities?: FreightFromCommodityStateDTM[];

  @IsOptional()
  @IsNumber()
  weight?: number;

  @IsOptional()
  @IsNumber()
  volume?: number;
}

export interface IFreightQuotaContentContainerRequestsDTM {
  origin?: IFreightQuotaContentContainerRequestsLocationDTM,
  destination?: IFreightQuotaContentContainerRequestsLocationDTM,
  container?: IFreightQuotaContentContainerRequestsContainerDTM
}

export class FreightQuotaContentContainerRequestsDTM extends BaseDTM<IFreightQuotaContentContainerRequestsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentContainerRequestsContainerDTM)
  container?: FreightQuotaContentContainerRequestsContainerDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentContainerRequestsLocationDTM)
  origin?: FreightQuotaContentContainerRequestsLocationDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentContainerRequestsLocationDTM)
  destination?: FreightQuotaContentContainerRequestsLocationDTM;
}

export interface IFreightQuotaContentCostDTM {
  totalCost?: number;
  originTotalCost?: number;
  freightTotalCost?: number;
  destinationTotalCost?: number;
}

export class FreightQuotaContentCostDTM extends BaseDTM<IFreightQuotaContentCostDTM> {
  @IsOptional()
  @IsNumber()
  totalCost?: number;

  @IsOptional()
  @IsNumber()
  originTotalCost?: number;

  @IsOptional()
  @IsNumber()
  freightTotalCost?: number;

  @IsOptional()
  @IsNumber()
  destinationTotalCost?: number;
}

export interface IFreightQuotaContentCustomerDTM {
  companyId?: number;
  contactId?: number;
  addressId?: number;
  organizationId?: number;
  type?: string;
}

export class FreightQuotaContentCustomerDTM extends BaseDTM<IFreightQuotaContentCustomerDTM> {
  @IsOptional()
  @IsNumber()
  companyId: number;

  @IsOptional()
  @IsNumber()
  contactId: number;

  @IsOptional()
  @IsNumber()
  addressId: number;

  @IsOptional()
  @IsNumber()
  organizationId?: number

  @IsOptional()
  @IsString()
  type?: string
}

export interface IFreightQuotaContentValidPeriodDTM {
  from?: DateDtm;
  to?: DateDtm;
}

export class FreightQuotaContentValidPeriodDTM extends BaseDTM<IFreightQuotaContentValidPeriodDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  from?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  to?: DateDtm;

  getFormatDateDMMMFrom = () => this.from?.getDateAsMomentLocalOffset().format('D MMM')

  getFormatDateDMMMTo = () => this.to?.getDateAsMomentLocalOffset().format('D MMM')

  getIsExpired = () => {
    if (!this.to) {
      return false;
    }

    const endDay = this.to?.getDateAsMomentLocalOffset().endOf('day').valueOf();

    return endDay < Date.now();
  }
}

export interface IFreightQuotaContentDTM {
  id?: number
  freightMode?: string
  createdBy?: string
  createdAt?: string
  tradeType?: EFreightIncotermsTrade
  incoterm?: EFreightIncoterms
  loadType?: string
  cost?: IFreightQuotaContentCostDTM
  routes?: IFreightQuotaContentRoutesDTM[]
  schedules?: IFreightQuotaContentSchedulesDTM[]
  contracts?: IFreightQuotaContentContractsDTM[]
  containers?: IFreightQuotaContentContainerDTM[]
  containerRequests?: IFreightQuotaContentContainerRequestsDTM[]
  additionalServices?: AdditionalServiceCheckedDTM[]
  customer?: IFreightQuotaContentCustomerDTM
  arrivalTerminal?: string;
  departureTerminal?: string;
  transportLeg?: number;
  validPeriod?: IFreightQuotaContentValidPeriodDTM
  bookmark?: IQuotaBookmark
  quotasCount?: number
  favoriteQuotasCount?: number
  isFavorite?: boolean
  isFavoriteLoading?: boolean
  isSelfServiceRequest?: boolean
  isIncludeRelatedPortRequest?: boolean
  includeRelatedPorts?: boolean
}

export class FreightQuotaContentDTM extends BaseDTM<IFreightQuotaContentDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  freightMode: string;

  @IsOptional()
  @IsString()
  createdBy?: string

  @IsOptional()
  @IsString()
  createdAt: string;

  @IsOptional()
  @IsEnum(EFreightIncotermsTrade)
  tradeType?: EFreightIncotermsTrade;

  @IsOptional()
  @IsEnum(EFreightIncoterms)
  incoterm?: EFreightIncoterms;

  @IsOptional()
  @IsString()
  loadType: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentCostDTM)
  cost?: FreightQuotaContentCostDTM;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentRoutesDTM)
  routes?: FreightQuotaContentRoutesDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentSchedulesDTM)
  schedules?: FreightQuotaContentSchedulesDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentContractsDTM)
  contracts?: FreightQuotaContentContractsDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentContainerDTM)
  containers?: FreightQuotaContentContainerDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentContainerRequestsDTM)
  containerRequests?: FreightQuotaContentContainerRequestsDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceCheckedDTM)
  additionalServices?: AdditionalServiceCheckedDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentCustomerDTM)
  customer?: FreightQuotaContentCustomerDTM;

  @IsOptional()
  @IsString()
  arrivalTerminal?: string;

  @IsOptional()
  @IsString()
  departureTerminal?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentValidPeriodDTM)
  validPeriod?: FreightQuotaContentValidPeriodDTM;

  @IsOptional()
  @IsString()
  transportLeg: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => QuotaBookmark)
  bookmark?: QuotaBookmark;

  @IsOptional()
  @IsNumber()
  quotasCount?: number;

  @IsOptional()
  @IsNumber()
  favoriteQuotasCount?: number;

  @IsOptional()
  @IsBoolean()
  isFavorite?: boolean;

  @IsOptional()
  @IsBoolean()
  isFavoriteLoading?: boolean;

  @IsOptional()
  @IsBoolean()
  isSelfServiceRequest?: boolean;

  @IsOptional()
  @IsBoolean()
  isIncludeRelatedPortRequest?: boolean;

  @IsOptional()
  @IsBoolean()
  includeRelatedPorts?: boolean;

  getCreateQuotaDate = () => {
    if (this.createdAt) {
      return moment(Date.now()).format(QUOTA_DATE_FORMAT);
    }
    return moment(Date.now()).format(QUOTA_DATE_FORMAT);
  }

  getOriginLocationToOneString = () => {
    const origin = this.routes?.[0]?.origin;
    const country = origin?.country?.code;
    const state = '';
    const code = '';
    const city = origin?.name;
    const firstCityName = true;
    let doorName = '';
    let portName = '';

    const isDoor = origin?.type === freightTypeLocation.DOOR;

    if (firstCityName && !isDoor) {
      const cityName = `${city ? `${city}` : ''}`;
      const stateName = `${state ? `${cityName && ', '}${state}` : ''}`;
      const countryName = `${country ? `${(cityName || stateName) && ', '}${country}` : ''}`;

      return `${cityName}${stateName}${countryName}`;
    }

    if (code && !portName) {
      const countryName = `${state ? `${state}` : ''}`;
      const stateName = `${country ? `${countryName && ', '}${country}` : ''}`;
      const cityName = `${code ? ` (${code})` : ''}`;

      portName = `${countryName}${stateName}${cityName}`;
    }

    const countryName = `${country ? `${country}` : ''}`;
    const stateName = `${state ? `${countryName && ', '}${state}` : ''}`;
    const cityName = `${city ? `${(countryName || stateName) && ', '}${city}` : ''}`;

    if (portName) {
      portName = `${countryName}${stateName}${cityName}`;
    }

    if (origin && isDoor) {
      doorName = `${origin?.city || ''}`;

      const postalCode = origin.postalCode ? `, ${origin.postalCode || ''}` : '';
      doorName += doorName ? `${postalCode}` : `${origin.postalCode || ''}`;

      doorName += doorName ? `, ${origin?.country?.name || ''}` : `${origin?.country?.name || ''}`;
    }

    return isDoor ? doorName : portName;
  }

  getDestinationLocationToOneString = () => {
    const destination = this.routes?.[0]?.destination;
    const country = destination?.country?.code;
    const state = '';
    const code = '';
    const city = destination?.name;
    const firstCityName = true;
    let doorName = '';
    let portName = '';

    const isDoor = destination?.type === freightTypeLocation.DOOR;

    if (firstCityName && !isDoor) {
      const cityName = `${city ? `${city}` : ''}`;
      const stateName = `${state ? `${cityName && ', '}${state}` : ''}`;
      const countryName = `${country ? `${(cityName || stateName) && ', '}${country}` : ''}`;

      return `${cityName}${stateName}${countryName}`;
    }

    if (code && !portName) {
      const countryName = `${state ? `${state}` : ''}`;
      const stateName = `${country ? `${countryName && ', '}${country}` : ''}`;
      const cityName = `${code ? ` (${code})` : ''}`;

      portName = `${countryName}${stateName}${cityName}`;
    }

    const countryName = `${country ? `${country}` : ''}`;
    const stateName = `${state ? `${countryName && ', '}${state}` : ''}`;
    const cityName = `${city ? `${(countryName || stateName) && ', '}${city}` : ''}`;

    if (!portName) {
      portName = `${countryName}${stateName}${cityName}`;
    }

    if (destination && isDoor) {
      doorName = `${destination?.city || ''}`;

      const postalCode = destination.postalCode ? `, ${destination.postalCode || ''}` : '';
      doorName += doorName ? `${postalCode}` : `${destination.postalCode || ''}`;

      doorName += doorName ? `, ${destination?.country?.name || ''}` : `${destination?.country?.name || ''}`;
    }

    return isDoor ? doorName : portName;
  }

  isFirstPOLLeg = () => {
    const transportationType = this.schedules?.[0]?.transportations?.[0]?.transport?.type;

    return this.routes?.[0]?.origin?.type !== freightTypeLocation.DOOR
      && (transportationType === ETransportationType.RAIL
        || transportationType === ETransportationType.WATER
        || transportationType === ETransportationType.ROAD);
  }

  getFirstPOLLeg = () => {
    let name = '';
    let legId: number | undefined;
    let leg: FreightQuotaContentRouteLegDTM | undefined;
    const transportation = this.schedules?.[0]?.transportations || [];

    if (this.isFirstPOLLeg()) {
      transportation.forEach((item) => {
        if (!legId && item.transport?.type === ETransportationType.SEA) {
          legId = item.transportLeg;
        }
      });

      if (legId) {
        this.routes?.forEach((item) => {
          item.legs?.forEach((itemLeg) => {
            if (!leg && itemLeg.id === legId) {
              leg = { ...itemLeg };
            }
          });
        });
      }
    }

    if (leg) {
      name = `${leg.departureLocation.name || ''}`;

      name += leg.departureLocation.country?.code === 'US' ? `, ${leg.departureLocation.state?.code || ''}` : '';

      name += name ? `, ${leg.departureLocation.country?.code || ''}` : `${leg.departureLocation.country?.code || ''}`;
    }

    return name;
  }

  isSecondPOLLeg = () => {
    const transportationType = this.schedules?.[0]?.transportations?.[this.schedules?.[0]?.transportations.length - 1]?.transport?.type;

    return this.routes?.[0]?.destination?.type !== freightTypeLocation.DOOR
      && (transportationType === ETransportationType.RAIL
        || transportationType === ETransportationType.WATER
        || transportationType === ETransportationType.ROAD);
  }

  getSecondPOLLeg = () => {
    let name = '';
    let legId: number | undefined;
    let leg: FreightQuotaContentRouteLegDTM | undefined;
    const transportation = this.schedules?.[0]?.transportations || [];

    if (this.isSecondPOLLeg()) {
      transportation.reverse().forEach((item) => {
        if (!legId && item.transport?.type === ETransportationType.SEA) {
          legId = item.transportLeg;
        }
      });

      if (legId) {
        this.routes?.forEach((item) => {
          item.legs?.forEach((itemLeg) => {
            if (!leg && itemLeg.id === legId) {
              leg = { ...itemLeg };
            }
          });
        });
      }
    }

    if (leg) {
      name = `${leg.arrivalLocation.name || ''}`;

      name += leg.arrivalLocation.country?.code === 'US' ? `, ${leg.arrivalLocation.state?.code || ''}` : '';

      name += name ? `, ${leg.arrivalLocation.country?.code || ''}` : `${leg.arrivalLocation.country?.code || ''}`;
    }

    return name;
  }

  getOriginLocationUNLOCK = () => `${this.routes?.[0]?.origin?.code || ''}`

  getDestinationLocationUNLOCK = () => `${this.routes?.[0]?.destination?.code || ''}`
}

export interface IFreightRFRQuotaContentParamsApplicationLevelDTM {
  id?: number
  type?: string
}

export class FreightRFRQuotaContentParamsApplicationLevelDTM extends BaseDTM<IFreightRFRQuotaContentParamsApplicationLevelDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  type: string;
}

export interface IFreightRFRQuotaContentParamsApplianceRangeDTM {
  minValue?: number
  maxValue?: number
}

export class FreightRFRQuotaContentParamsApplianceRangeDTM extends BaseDTM<IFreightRFRQuotaContentParamsApplianceRangeDTM> {
  @IsOptional()
  @IsNumber()
  minValue: number;

  @IsOptional()
  @IsNumber()
  maxValue: number;
}

export interface IFreightRFRQuotaContentParamsValidPeriodDTM {
  from?: string
  to?: string
}

export class FreightRFRQuotaContentParamsValidPeriodDTM extends BaseDTM<IFreightRFRQuotaContentParamsValidPeriodDTM> {
  @IsOptional()
  @IsString()
  from: string;

  @IsOptional()
  @IsString()
  to: string;
}

export interface IFreightRFRQuotaContentChargeCodeDTM {
  code: string
  description: string
  mode: string
  loadType: EFreightLoadType
  type: string
  subType?: string
  occurrence: string
  phases?: string[]
  group?: string
  status?: string
  organizationId?: number
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
}

export class FreightRFRQuotaContentChargeCodeDTM extends BaseDTM<IFreightRFRQuotaContentChargeCodeDTM> {
  @IsString()
  code: string;

  @IsString()
  description: string;

  @IsString()
  mode: string;

  @IsEnum(EFreightLoadType)
  loadType: EFreightLoadType;

  @IsString()
  type: string;

  @IsOptional()
  @IsString()
  subType: string;

  @IsString()
  occurrence: string;

  @IsOptional()
  @IsString({ each: true })
  phases: string[];

  @IsOptional()
  @IsString()
  group: string;

  @IsOptional()
  @IsString()
  status: string;

  @IsOptional()
  @IsNumber()
  organizationId: number;

  @IsOptional()
  @IsString()
  createdBy: string;

  @IsOptional()
  @IsString()
  createdAt: string;

  @IsOptional()
  @IsString()
  updatedBy: string;

  @IsOptional()
  @IsString()
  updatedAt: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceCheckedDTM)
  services?: AdditionalServiceCheckedDTM[];
}

export interface IFreightRFRQuotaContentParamsCommoditiesDTM {
  code: string
  imoClass: string
}

export class FreightRFRQuotaContentParamsCommoditiesDTM extends BaseDTM<IFreightRFRQuotaContentParamsCommoditiesDTM> {
  @IsOptional()
  @IsString()
  code: string;

  @IsOptional()
  @IsString()
  imoClass: string;
}

export interface IFreightRFRQuotaContentParamsLocationCountryDTM {
  code: string
  name?: string
  forbidden?: boolean
}

export class FreightRFRQuotaContentParamsLocationCountryDTM extends BaseDTM<IFreightRFRQuotaContentParamsLocationCountryDTM> {
  @IsString()
  code: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsBoolean()
  forbidden?: boolean;
}

export interface IFreightRFRQuotaContentParamsLocationDTM {
  code: string
  placeId?: string
  type?: string
  city?: string
  name?: string
  address?: string
  country?: IFreightRFRQuotaContentParamsLocationCountryDTM
  state?: IFreightSelectFieldCountryDTM
  subdivisionCode?: string
  subdivisionName?: string
  subdivisionType?: string
  locationCode?: string
  locationName?: string
  coordinates?: string
  timeZoneId?: number
  iataCode?: string
  alternativeCodes?: string
}

export class FreightRFRQuotaContentParamsLocationDTM extends BaseDTM<IFreightRFRQuotaContentParamsLocationDTM> {
  @IsString()
  code: string;

  @IsOptional()
  @IsString()
  placeId?: string;

  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  address?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCountryDTM)
  state?: FreightSelectFieldCountryDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightRFRQuotaContentParamsLocationCountryDTM)
  country?: FreightRFRQuotaContentParamsLocationCountryDTM;

  @IsOptional()
  @IsString()
  subdivisionCode?: string;

  @IsOptional()
  @IsString()
  subdivisionName: string;

  @IsOptional()
  @IsString()
  subdivisionType?: string;

  @IsOptional()
  @IsString()
  locationCode?: string;

  @IsOptional()
  @IsString()
  locationName?: string;

  @IsOptional()
  @IsString()
  coordinates?: string;

  @IsOptional()
  @IsNumber()
  timeZoneId?: number;

  @IsOptional()
  @IsString()
  iataCode?: string;

  @IsOptional()
  @IsString()
  alternativeCodes?: string;

  getLocationName = () => {
    let fullAddress = this.name || '';
    const countryCode = this.country?.code || '';
    let doorName = '';
    const isDoor = this.type === freightTypeLocation.DOOR;

    if (countryCode) {
      fullAddress += fullAddress ? `, ${countryCode}` : countryCode;
    }

    if (isDoor) {
      doorName = `${this?.city || ''}`;

      doorName += doorName ? `, ${this?.state?.code || ''}` : `${this?.state?.code || ''}`;

      doorName += doorName ? `, ${this?.country?.code || ''}` : `${this?.country?.code || ''}`;
    }

    return isDoor ? doorName : fullAddress;
  }

  getDoorFullAddress = () => {
    const location = this;
    const address = `${location?.address ? `${location?.address}` : ''}`;
    const city = `${location?.city ? `${address && ', '}${location?.city}` : ''}`;
    const state = `${location?.state?.code ? `${(address || city) && ' '}(${location?.state?.code})` : ''}`;
    const countryName = `${location?.country?.name ? `${(address || city || state) && ', '}${location?.country?.name}` : ''}`;
    const fullName = `${address}${city}${state}${countryName}`;

    return fullName;
  }
}

export interface IFreightRFRQuotaContentParamsDTM {
  loadType: EFreightLoadType
  fromDate?: string
  toDate?: string
  origin: IFreightRFRQuotaContentParamsLocationDTM
  destination: IFreightRFRQuotaContentParamsLocationDTM
  status?: string
  carriers?: string[]
  containers?: string[]
  commodities?: IFreightRFRQuotaContentParamsCommoditiesDTM[]
  services?: AdditionalServiceCheckedDTM[]
}

export class FreightRFRQuotaContentParamsDTM extends BaseDTM<IFreightRFRQuotaContentParamsDTM> {
  @IsEnum(EFreightLoadType)
  loadType: EFreightLoadType;

  @IsOptional()
  @IsString()
  fromDate: string;

  @IsOptional()
  @IsString()
  toDate: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightRFRQuotaContentParamsLocationDTM)
  origin: FreightRFRQuotaContentParamsLocationDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightRFRQuotaContentParamsLocationDTM)
  destination: FreightRFRQuotaContentParamsLocationDTM;

  @IsOptional()
  @IsString()
  status: string;

  @IsOptional()
  @IsString({ each: true })
  carriers: string[];

  @IsOptional()
  @IsString({ each: true })
  containers: string[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightRFRQuotaContentParamsCommoditiesDTM)
  commodities?: FreightRFRQuotaContentParamsCommoditiesDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceCheckedDTM)
  services?: AdditionalServiceCheckedDTM[];
}

export interface IFreightRFRQuotaContentDTM {
  id: number
  quotaRequestId?: number
  params?: IFreightRFRQuotaContentParamsDTM
  status?: string
  partnerOrgId: number
  organizationId?: number
  createdBy?: string
  createdAt?: string
  completedBy?: string
  completedAt?: string
  customerName?: string
  customerEmail?: string
  gensetControl?: boolean
}

export class FreightRFRQuotaContentDTM extends BaseDTM<IFreightRFRQuotaContentDTM> {
  @IsNumber()
  id: number;

  @IsOptional()
  @IsNumber()
  quotaRequestId: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightRFRQuotaContentParamsDTM)
  params?: FreightRFRQuotaContentParamsDTM;

  @IsOptional()
  @IsString()
  status: string;

  @IsNumber()
  partnerOrgId: number;

  @IsOptional()
  @IsNumber()
  organizationId: number;

  @IsOptional()
  @IsString()
  createdBy: string;

  @IsOptional()
  @IsString()
  createdAt: string;

  @IsOptional()
  @IsString()
  completedBy: string;

  @IsOptional()
  @IsString()
  completedAt: string;

  @IsOptional()
  @IsString()
  customerName: string;

  @IsOptional()
  @IsString()
  customerEmail: string;

  @IsOptional()
  @IsBoolean()
  gensetControl?: boolean;

  getCreateRequestDate = () => {
    if (this.createdAt) {
      return moment(this.createdAt).format(QUOTA_DATE_FORMAT);
    }
    return moment(Date.now()).format(QUOTA_DATE_FORMAT);
  }
}

export interface IFreightRFRQuotaScheduleCarrierCodeDTM {
  code: string
  name?: string
}

export class FreightRFRQuotaScheduleCarrierCodeDTM extends BaseDTM<IFreightRFRQuotaScheduleCarrierCodeDTM> {
  @IsString()
  code: string;

  @IsOptional()
  @IsString()
  name: string;
}

export interface IFreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM {
  number?: number
  name: string
  type: ETransportationType
}

export class FreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM extends BaseDTM<IFreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM> {
  @IsOptional()
  @IsNumber()
  number: number;

  @IsString()
  name: string;

  @IsEnum(ETransportationType)
  type: ETransportationType;
}

export interface IFreightRFRQuotaScheduleRouteTransportLegVoyageDTM {
  code: string
  operator: IFreightRFRQuotaScheduleCarrierCodeDTM
  transport: IFreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM
}

export class FreightRFRQuotaScheduleRouteTransportLegVoyageDTM extends BaseDTM<IFreightRFRQuotaScheduleRouteTransportLegVoyageDTM> {
  @IsString()
  code: string;

  @ValidateNested()
  @Type(() => FreightRFRQuotaScheduleCarrierCodeDTM)
  operator: FreightRFRQuotaScheduleCarrierCodeDTM;

  @ValidateNested()
  @Type(() => FreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM)
  transport: FreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM;
}

export interface IFreightRFRQuotaScheduleRouteTransportLegDTM {
  id?: number
  sequence: number
  departureTime: string
  arrivalTime: string
  transitDuration: number
  arrivalLocation: string
  departureLocation: string
  voyage: IFreightRFRQuotaScheduleRouteTransportLegVoyageDTM
}

export class FreightRFRQuotaScheduleRouteTransportLegDTM extends BaseDTM<IFreightRFRQuotaScheduleRouteTransportLegDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsNumber()
  sequence: number;

  @IsString()
  departureTime: string;

  @IsString()
  arrivalTime: string;

  @IsNumber()
  transitDuration: number;

  @IsString()
  arrivalLocation: string;

  @IsString()
  departureLocation: string;

  @ValidateNested()
  @Type(() => FreightRFRQuotaScheduleRouteTransportLegVoyageDTM)
  voyage: FreightRFRQuotaScheduleRouteTransportLegVoyageDTM;
}

export interface IFreightRFRQuotaScheduleRouteDTM {
  origin: string
  destination: string
  transportLeg: IFreightRFRQuotaScheduleRouteTransportLegDTM[]
}

export class FreightRFRQuotaScheduleRouteDTM extends BaseDTM<IFreightRFRQuotaScheduleRouteDTM> {
  @IsString()
  origin: string;

  @IsString()
  destination: string;

  @ValidateNested({ each: true })
  @Type(() => FreightRFRQuotaScheduleRouteTransportLegDTM)
  transportLeg: FreightRFRQuotaScheduleRouteTransportLegDTM[];
}

export interface IFreightRFRQuotaScheduleDTM {
  id?: number
  carrier: IFreightRFRQuotaScheduleCarrierCodeDTM
  documentCutoff?: string
  hazmatCutoff?: string
  vgmCutoff?: string
  ctoAvailable?: string
  terminalCutoff?: string
  departureTime: string
  arrivalTime: string
  totalDuration?: number
  route: IFreightRFRQuotaScheduleRouteDTM
}

export class FreightRFRQuotaScheduleDTM extends BaseDTM<IFreightRFRQuotaScheduleDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @ValidateNested()
  @Type(() => FreightRFRQuotaScheduleCarrierCodeDTM)
  carrier: FreightRFRQuotaScheduleCarrierCodeDTM;

  @IsOptional()
  @IsString()
  documentCutoff: string;

  @IsOptional()
  @IsString()
  hazmatCutoff: string;

  @IsOptional()
  @IsString()
  vgmCutoff: string;

  @IsOptional()
  @IsString()
  ctoAvailable: string;

  @IsOptional()
  @IsString()
  terminalCutoff: string;

  @IsString()
  departureTime: string;

  @IsString()
  arrivalTime: string;

  @IsOptional()
  @IsNumber()
  totalDuration: number;

  @ValidateNested()
  @Type(() => FreightRFRQuotaScheduleRouteDTM)
  route: FreightRFRQuotaScheduleRouteDTM;
}
