import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RAPWrap = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'RAPWrap',
}))`
  ${divFlex}
  flex-direction: column;

  margin-top: 17px;
`;

export const RAPContent = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'RAPContent',
}))`
  ${divFlex}
  flex-direction: column;

  margin-top: 8px;
`;

export const RAPContentSelect = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'RAPContentSelect',
}))`
  ${divFlex}
`;

export const RFQFormHeader = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'RFQFormHeader',
}))`
  ${divFlex}

  padding: 8px 0;
  box-shadow: inset 0px -1px 0px #f4f5f8;
`;

export const RFQFormHeaderName = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'RFQFormHeaderName',
}))`
  ${divFlex}

  ${({ theme }) => theme?.fonts?.BodyTextMedium};
`;

export const RFQFormHeaderMore = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'RFQFormHeaderMore',
}))`
  ${divFlex}

  cursor: pointer;
  text-decoration: underline;
  margin-left: auto;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
`;

export const RAPContentTitleWrap = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'RAPContentTitleWrap',
}))`
  ${divFlex}

  margin-bottom: 8px;

  svg {
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    width: 11px;
    height: 11px;
    cursor: pointer;
    margin-left: 5px;
  }
`;

export const RAPContentTitleIcon = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'RAPContentTitleIcon',
}))`
  ${divFlex}
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    width: 11px;
    height: 11px;
    cursor: pointer;
    margin-left: 5px;
  }
`;

export const RAPContentTitle = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'RAPContentTitle',
}))`
  ${divFlex}
  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
`;
