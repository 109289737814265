import { action, computed } from 'mobx';
import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

interface FormInputs {
  file?: InputStore<ContainerDocumentDTM | undefined>
}

interface IOverviewControllerState {
  isSubmitting: boolean
  form: FormInputs
}

const initialState: IControllerState<IOverviewControllerState> = {
  isLoading: true,
  isSubmitting: false,
  form: {
    file: new InputStore(),
  },
};

export class DocumentUploaderDrawerStore extends ControllerStore<IControllerState<IOverviewControllerState>> {
  setSubmitting(isSubmitting: boolean) {
    this.state.isSubmitting = isSubmitting;
  }

  @action
  setForm(formInputs: FormInputs) {
    this.state.form = formInputs;
  }

  @action
  setFile(file: ContainerDocumentDTM) {
    this.state.form.file?.setValue(file);
  }

  @computed
  get hasError() {
    return Object.values(this.state.form).some((input: InputStore<any>) => input.hasError);
  }
}

export const createDocumentUploaderDrawerStore = (_initialState?: IControllerState<IOverviewControllerState>) => new DocumentUploaderDrawerStore(_initialState || initialState);
