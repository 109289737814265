import { createSelector } from 'reselect';
import findLastIndex from 'lodash/fp/findLastIndex';
import moment from 'moment-timezone';

import { RootState } from 'app-wrapper/store';
import {
  PASSED, FREE_DATE, NOT_ACHIEVED, DEFINED_DATE,
} from 'shipment-operations/constants';
import { getEventStatus, getSyncedActualDate, getSyncedEstimatedDate } from 'shipment-operations/view/pages/ShipmentTransportationTracker/utils';
import {
  DispatchedContainerDTM,
  ShipmentEventDTM,
  StepsDTM,
  TrackerContainersTableDTM,
  TransportPlanDTM,
} from 'shipment-operations/models/dtm';
import { shipmentTrackerRoutesSelectors } from '../ShipmentTrackerRoutes';

const localState = (state: RootState) => state.shipmentTracker;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsLoadingPage = createSelector(
  localState,
  (state) => state.isPageLoading,
);

const getIsError = createSelector(
  localState,
  (state) => state.error,
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getUpdatedDates = createSelector(
  localState,
  (state) => state.updatedDates,
);

const getIsUpdatedDates = createSelector(
  getUpdatedDates,
  (dates) => !!dates.length,
);

const getDispatchedContainers = createSelector(
  localState,
  (state) => state.dispatchedContainers,
);

const getDispatchedContainersNumbers = createSelector(
  getDispatchedContainers,
  (dispatchedContainers) => dispatchedContainers.map((container) => container.number),
);

const getVoyageCode = (event: ShipmentEventDTM, _plan: TransportPlanDTM | undefined) => _plan?.transportations?.find((transportation) => transportation?.leg?.arrivalLocation.code === event.location.code || transportation?.leg?.departureLocation.code === event.location.code)?.voyageCode;

const getContainersForTable = createSelector(
  getContainers,
  getUpdatedDates,
  shipmentTrackerRoutesSelectors.getPlan,
  getDispatchedContainersNumbers,
  (containers, updatedDates, plans, dispatchedContainers) => containers.map((item, indexMain) => {
    // check if all events are completed

    const plan = plans.find((_plan) => _plan.id === item.planId);

    if (item.container.events.at(-1)?.actual) {
      const events = item.container.events.map((event) => ({
        id: event.id,
        status: PASSED,
        title: event.code,
        locationName: event.location.name || event.location.city || '',
        stateCode: event.location.state.code,
        countryCode: event.location.country.code,
        vessel: event.transport.name,
        voyage: getVoyageCode(event, plan),
        estimatedDate: event.estimated,
        estimatedDateType: event.estimated ? DEFINED_DATE : FREE_DATE,
        actualDate: event.actual,
        actualDateType: event.actual ? DEFINED_DATE : FREE_DATE,
        originalActualDateType: event.actual ? DEFINED_DATE : FREE_DATE,
        withWarning: moment(event.estimated?.date).clone().add(4, 'hours').isBefore(moment()),
      }));

      return (TrackerContainersTableDTM.fromPlain({
        key: indexMain,
        type: item.container.type,
        number: item.container.number,
        previousEvent: item.container.events.at(-1)?.code,
        previousEventDrayage: item.container.events.at(6)?.code,
        date: item.container.events.at(-1)?.actual,
        dateType: DEFINED_DATE,
        nextEvent: '',
        estimatedDate: undefined,
        estimatedDateType: '',
        actualDate: undefined,
        actualDateType: '',
        editable: true,
        eventId: events.at(-1)?.id,
        isLinked: dispatchedContainers.includes(item.container.number || ''),
        id: item.container.id,
        planId: item.planId,
        events,
      }));
    }

    // if the journey just started :)
    if (!item.container.events.find((elem) => elem.actual)) {
      const events = item.container.events.map((event) => {
        const syncedActualDate = getSyncedActualDate(updatedDates, event.id);
        const syncedEstimatedDate = getSyncedEstimatedDate(updatedDates, event.id);
        const calculatedEstimatedDate = syncedEstimatedDate || event.estimated;
        return ({
          id: event.id,
          status: NOT_ACHIEVED,
          title: event.code,
          locationName: event.location.name || event.location.city || '',
          stateCode: event.location.state.code,
          countryCode: event.location.country.code,
          vessel: event.transport.name,
          voyage: getVoyageCode(event, plan),
          estimatedDate: syncedEstimatedDate || event.estimated,
          estimatedDateType: (syncedEstimatedDate || event.estimated) ? DEFINED_DATE : FREE_DATE,
          actualDate: syncedActualDate || event.actual,
          actualDateType: (syncedActualDate || event.actual) ? DEFINED_DATE : FREE_DATE,
          originalActualDateType: event.actual ? DEFINED_DATE : FREE_DATE,
          withWarning: moment(calculatedEstimatedDate?.date).clone().add(4, 'hours').isBefore(moment()),
        });
      });
      const syncedActualDate = getSyncedActualDate(updatedDates, events.at(0)?.id);
      const syncedEstimatedDate = getSyncedEstimatedDate(updatedDates, events.at(0)?.id);

      return (TrackerContainersTableDTM.fromPlain({
        key: indexMain,
        type: item.container.type,
        number: item.container.number,
        previousEvent: '',
        previousEventDrayage: '',
        date: undefined,
        dateType: '',
        nextEvent: item.container.events.at(0)?.code,
        estimatedDate: syncedEstimatedDate || item.container.events.at(0)?.estimated,
        estimatedDateType: (syncedEstimatedDate || item.container.events.at(0)?.estimated) ? DEFINED_DATE : FREE_DATE,
        actualDate: syncedActualDate,
        actualDateType: syncedActualDate ? DEFINED_DATE : FREE_DATE,
        editable: true,
        eventId: events[0].id,
        isLinked: dispatchedContainers.includes(item.container.number || ''),
        id: item.container.id,
        planId: item.planId,
        events,
      }));
    }

    const previousEventIndex = findLastIndex((elem) => !!elem.actual, item.container.events);
    const previousEvent = item.container.events[previousEventIndex];
    const inProgressEvent = item.container.events[previousEventIndex + 1];
    const inProgressEventIndex = previousEventIndex + 1;
    const events = item.container.events.map((event, index) => {
      const syncedActualDate = getSyncedActualDate(updatedDates, event.id);
      const syncedEstimatedDate = getSyncedEstimatedDate(updatedDates, event.id);
      const getEstimatedType = () => {
        if (syncedEstimatedDate || event.estimated) {
          return DEFINED_DATE;
        }
        return item.container.id ? FREE_DATE : '';
      };
      const getActualType = () => {
        if (syncedActualDate || event.actual) {
          return DEFINED_DATE;
        }
        return FREE_DATE;
      };
      const getOriginalType = () => {
        if (event.actual) {
          return DEFINED_DATE;
        }
        return item.container.id ? FREE_DATE : '';
      };
      const calculatedEstimatedDate = syncedEstimatedDate || event.estimated;
      const targetUpdatedEvent = updatedDates.find(({ id }) => id === event.id);

      return StepsDTM.fromPlain({
        id: event.id,
        status: getEventStatus(index, inProgressEventIndex),
        title: event.code,
        locationName: event.location.name || event.location.city || '',
        stateCode: event.location.state.code,
        countryCode: event.location.country.code,
        vessel: event.transport.name,
        voyage: getVoyageCode(event, plan),
        estimatedDate: syncedEstimatedDate || event.estimated,
        estimatedDateType: getEstimatedType(),
        actualDate: targetUpdatedEvent && !targetUpdatedEvent.time ? undefined : (syncedActualDate || event.actual),
        actualDateType: getActualType(),
        originalActualDateType: getOriginalType(),
        withWarning: moment(calculatedEstimatedDate?.date).clone().add(4, 'hours').isBefore(moment()),
      });
    });
    const syncedActualDate = getSyncedActualDate(updatedDates, events[inProgressEventIndex].id);
    const syncedEstimatedDate = getSyncedEstimatedDate(updatedDates, events[inProgressEventIndex].id);
    const getActualDateType = () => {
      if (syncedActualDate) {
        return DEFINED_DATE;
      }
      return FREE_DATE;
    };

    return (TrackerContainersTableDTM.fromPlain({
      key: indexMain,
      type: item.container.type,
      number: item.container.number,
      previousEvent: previousEvent ? previousEvent.code : '',
      previousEventDrayage: previousEvent ? previousEvent.code : '',
      date: previousEvent.actual,
      dateType: previousEvent ? DEFINED_DATE : FREE_DATE,
      nextEvent: inProgressEvent.code,
      estimatedDate: syncedEstimatedDate || inProgressEvent.estimated,
      estimatedDateType: DEFINED_DATE,
      actualDate: syncedActualDate,
      actualDateType: getActualDateType(),
      editable: true,
      eventId: events[inProgressEventIndex].id,
      isLinked: dispatchedContainers.includes(item.container.number || ''),
      id: item.container.id,
      planId: item.planId,
      events,
    }));
  }),
);

const getDispatchedContainersTable = createSelector(
  getDispatchedContainers,
  (containers) => containers.map((container) => {
    const events = container?.events?.map((event) => ({
      id: event.id,
      title: event.type,
      city: event.place.city,
      country: event.place.country,
      date: event.time,
    }));

    return DispatchedContainerDTM.fromPlain({
      key: container.number,
      type: container.type,
      number: container.number,
      previousEvent: container?.events?.at(-1)?.type,
      date: container?.events?.at(-1)?.time,
      events,
    });
  }),
);

const getAllEvents = createSelector(
  getContainers,
  (containers) => containers.reduce((acc, cur) => [...acc, ...cur.container.events], [] as ShipmentEventDTM[]),
);

const getIsLoadingUpdate = createSelector(
  localState,
  (state) => state.updateDatesLoading,
);

const getIsErrorUpdate = createSelector(
  localState,
  (state) => state.updateDatesError,
);

const getIsShowUntrackedWarning = createSelector(
  getDispatchedContainers,
  getContainers,
  (dispatchedContainers, existingContainers) => {
    const dispatchedContainersNumbers = dispatchedContainers.map((container) => container.number);
    const existingContainersNumbers = existingContainers.map((container) => container.container.number);
    return dispatchedContainersNumbers.some((number) => !existingContainersNumbers.includes(number));
  },
);

const getActionLoading = createSelector(
  localState,
  (state) => state.actionLoading,
);

const getShouldUpdateData = createSelector(
  localState,
  (state) => state.shouldUpdateData,
);

export const shipmentTrackerSelectors = {
  getContainers,
  getIsError,
  getIsLoading,
  getIsLoadingPage,
  getContainersForTable,
  getUpdatedDates,
  getAllEvents,
  getIsUpdatedDates,
  getIsLoadingUpdate,
  getIsErrorUpdate,
  getIsShowUntrackedWarning,
  getDispatchedContainersTable,
  getActionLoading,
  getShouldUpdateData,
};
