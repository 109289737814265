import React, { FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';
import { SHIPPING_PARTY_MAX_LENGTHS } from 'app-wrapper/constants';

interface IShippingPartiesPostalCodeInputComponentProps {
  postalCode?: string;
  setPostalCode: (code: string) => void;
  error?: boolean;
}

export const PostalCodeInputComponent: FC<IShippingPartiesPostalCodeInputComponentProps> = ({
  postalCode,
  setPostalCode,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setPostalCode(e.target.value);
  }, [setPostalCode]);

  return (
    <Input
      value={postalCode}
      onChange={handleChange}
      maxLength={SHIPPING_PARTY_MAX_LENGTHS.POSTCODE}
      error={error && !postalCode?.length}
    />
  );
};
