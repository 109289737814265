import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Input } from 'app-wrapper/view/components';
import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline';
import { SectionTitle } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/SupplierLayout/SupplierLayout.styled';

import {
  Layout,
  Title,
  Label,
  Content,
  Column,
  CompanySection,
  AddressSection,
  ContactSection,
  DetailsSection,
  Textarea,
  ReferencesSection,
} from './SupplierLayout.styled';

interface ISupplierLayoutProps {
  title: string;
  company?: ReactNode;
  address?: ReactNode;
  contact?: ReactNode;
  loadingDetails?: ReactNode;
  references?: ReactNode;
}

export const SupplierLayout: FC<ISupplierLayoutProps> = ({
  title,
  company,
  address,
  contact,
  loadingDetails,
  references,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Title>
          {title}
        </Title>

        <Divider style={{ margin: '16px 0' }} dashed />

        <GapVerticalContainerStyled>
          <Content>
            <Column>
              <CompanySection>
                {company}
              </CompanySection>

              <AddressSection>
                {address}
              </AddressSection>
            </Column>

            <Column>
              <ContactSection>
                {contact}
              </ContactSection>

              <DetailsSection>
                <Title>
                  {t('Additional Details')}
                </Title>

                <Label>
                  {t('Comment')}
                </Label>

                <Textarea />
              </DetailsSection>
            </Column>
          </Content>

          <Content>
            <Column>
              <ReferencesSection>
                {references}
              </ReferencesSection>
            </Column>

            <Column>
              <ReferencesSection>
                <GapVerticalContainerStyled>
                  <SectionTitle>
                    {t('On behalf')}
                  </SectionTitle>

                  <Input
                    style={{
                      height: 40,
                    }}
                  />
                </GapVerticalContainerStyled>
              </ReferencesSection>
            </Column>
          </Content>
        </GapVerticalContainerStyled>
      </Layout>

      {loadingDetails}
    </>
  );
};
