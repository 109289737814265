import { createSelector } from 'reselect';

import i18n from 'app-wrapper/i18n/i18n';
import { RootState } from 'app-wrapper/store';
import { areAddressesEqual } from 'app-wrapper/utils';
import {
  BookingWizardContainersErrorsDTM,
  CargoDTM,
  CompanyAddressDTM,
  CompanyContactDTM,
  ContainerDTM,
} from 'shipment-operations/models/dtm';
import { ValidationErrorType } from 'app-wrapper/types';
import { EFreightIncotermsTrade } from 'monetary/constants';
import { EOrganizationMemberRole, EOrganizationType, EShipmentOrganizationRole } from 'user-management/constants';
import {
  GEN,
  ShipmentStatusEnum,
  CONTAINER_MAX_COST_VALUE,
} from 'shipment-operations/constants';

export enum EPickupDeliveryTabs {
  CARGO_SUPPLIER = 'CARGO_SUPPLIER',
  CARGO_RECEIVER = 'CARGO_RECEIVER',
}

const localState = (state: RootState) => state.bookingWizard;

const localStateShipment = (state: RootState) => state.shipment;

const getIsSecondStep = createSelector(
  localState,
  (state) => state.step === 2,
);

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading || !state.shipmentId,
);

const getIsContentUpdating = createSelector(
  localState,
  (state) => state.isContentUpdating,
);

const getCurrentStep = createSelector(
  localState,
  (state) => state.step,
);

const getShipmentId = createSelector(
  localState,
  (state) => state.shipmentId,
);

const getShipmentNRAFileNamePrint = createSelector(
  localState,
  localStateShipment,
  (state, stateShipment) => {
    let fileName = i18n.t('Skypace. NRA for ');
    const name = state.shipmentId || stateShipment?.shipment?.shipmentName;

    if (name) {
      fileName += name;
    }

    return fileName;
  },
);

const getShipmentTermsOfServiceFileNamePrint = createSelector(
  localState,
  localStateShipment,
  (state, stateShipment) => {
    let fileName = i18n.t('T/C for ');
    const name = stateShipment?.shipment?.shipmentName;

    if (name) {
      fileName += name;
    }

    return fileName;
  },
);

const getNewBookPartiesId = createSelector(
  localState,
  (state) => state.newBookPartiesId,
);

const getNewBookPartiesConsigneeId = createSelector(
  localState,
  (state) => state.newBookPartiesConsigneeId,
);

const getTotalCost = createSelector(
  localState,
  (state) => state.totalCost,
);

const getShipment = createSelector(
  localState,
  (state) => state.shipmentData,
);

const getIsShipmentWaitingForApproval = createSelector(
  getShipment,
  (shipment) => shipment?.status === ShipmentStatusEnum.AWAITING_APPROVAL,
);

const getShipmentOrigin = createSelector(
  localState,
  (state) => (state.shipmentData ? state.shipmentData.origin : null),
);

const getShipmentDestination = createSelector(
  localState,
  (state) => (state.shipmentData ? state.shipmentData.destination : null),
);

const getIsUsShipmentOriginOrDestination = createSelector(
  localState,
  (state) => Boolean(state.shipmentData?.origin.countryCode === 'US' || state.shipmentData?.destination.countryCode === 'US'),
);

const getVoyageDuration = createSelector(
  localState,
  (state) => state.durationDays,
);

const getCargos = createSelector(
  localState,
  (state) => state.cargos,
);

const getShouldHaveAnyHazmats = createSelector(
  localState,
  (state) => state.shouldHaveHazmats,
);

const getCargoById = (cargoId: number) => createSelector(
  localState,
  ({ cargos }) => {
    const cargo = cargos.find(({ id }) => id === cargoId) as CargoDTM;

    return cargo;
  },
);

const getHasTemperatureControl = createSelector(
  localState,
  (state) => state.hasTemperatureControl,
);

const getIsFirstCargo = (cargoId: number) => createSelector(
  localState,
  (state) => {
    const { cargos } = state;
    const cargoIndex = cargos.findIndex(({ id }) => cargoId === id);

    return cargoIndex === 0;
  },
);

const getIsHazmatToggledByCargoId = (cargoId: number) => createSelector(
  localState,
  (state) => {
    const { toggledHazmatCargoIds } = state;

    return !!toggledHazmatCargoIds.find((id) => id === cargoId);
  },
);

const getCargosTotalWeightAndVolume = createSelector(
  localState,
  (state) => {
    const { cargos } = state;
    const weight = cargos.reduce((start, next) => start + (next.weight && !Number.isNaN(next.weight) ? +next.weight : 0), 0);
    const volume = cargos.reduce((start, next) => start + (next.volume && !Number.isNaN(next.volume) ? +next.volume : 0), 0);

    return {
      weight,
      volume,
    };
  },
);

const hasErrorsAtAnyCargo = createSelector(
  localState,
  (state) => {
    const { cargos } = state;
    let hasErrors = false;

    cargos.forEach(({ errors }) => {
      if (errors.hasErrors()) {
        hasErrors = true;
      }
    });

    return hasErrors;
  },
);

const hasErrorsAtAnyContainers = createSelector(
  localState,
  (state) => {
    const { containersErrors } = state;
    let hasErrors = false;

    containersErrors.forEach((errors) => {
      if (errors.hasErrors()) {
        hasErrors = true;
      }
    });

    return hasErrors;
  },
);

const getHSCodeBEValidationErrorMessage = createSelector(
  localState,
  (state) => {
    const { cargos } = state;

    const cargoWithHSCodeError = cargos.find(({ errors }) => {
      const { code } = errors;

      return code && code.type === ValidationErrorType.ALERT;
    });

    if (!cargoWithHSCodeError || !cargoWithHSCodeError.errors || !cargoWithHSCodeError.errors.code || !cargoWithHSCodeError.errors.code.message) {
      return '';
    }

    return cargoWithHSCodeError.errors.code.message;
  },
);

const getIsCargoForcedToBeHazmat = createSelector(
  localState,
  (state) => {
    const shouldHaveAnyHazmats = state.shouldHaveHazmats;
    const hasSingleCargo = state.cargos.length === 1;

    return shouldHaveAnyHazmats && hasSingleCargo;
  },
);

const getIsHazmatErrorVisible = createSelector(
  localState,
  (state) => state.isHazmatErrorVisible,
);

const getIsHaveAccountLimit = createSelector(
  localState,
  (state) => state.isHaveAccountLimit,
);

const getIsShowAccountLimit = createSelector(
  localState,
  (state) => state.isShowAccountLimit,
);

const getCreatedCargosIds = createSelector(
  localState,
  (state) => state.createdCargosIds,
);

const getWasInformationSavedOnce = createSelector(
  localState,
  (state) => state.wasInformationSavedOnce,
);

const getDefaultCargo = createSelector(
  localState,
  (state) => state.defaultCargo,
);

const getContainersAmount = createSelector(
  localState,
  (state) => state.containersAmount,
);

const getCargosToDeleteIds = createSelector(
  localState,
  (state) => state.cargosToDeleteIds,
);

const getEmptyReleaseDate = createSelector(
  localState,
  (state) => {
    if (!state.emptyReleaseDate) {
      return undefined;
    }

    return state.emptyReleaseDate.getDateAsMomentWithOffset();
  },
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getContainerById = (id: string) => createSelector(
  localState,
  (state) => state.containers.find((_container) => id === _container.id) as ContainerDTM,
);

const getContainerErrorsById = (id: string) => createSelector(
  localState,
  (state) => {
    const containerErrors = state.containersErrors.find(({ containerId }) => containerId === id);

    return containerErrors as BookingWizardContainersErrorsDTM;
  },
);

const getContainersErrors = createSelector(
  localState,
  (state) => state.containersErrors,
);

const getHasSOC = createSelector(
  localState,
  (state) => state.hasSOC,
);

const getNameSCAC = createSelector(
  localState,
  (state) => state.nameSCAC,
);

const getEmptyReleaseDateError = createSelector(
  localState,
  (state) => state.emptyReleaseDateError,
);

const getCurrentOrganization = createSelector(
  localState,
  (state) => state.currentOrganization,
);

const getShouldShowShipperSection = createSelector(
  localState,
  getCurrentOrganization,
  (state, currentOrganization) => {
    const { incotermsTrade } = state;

    if (!currentOrganization || currentOrganization.role !== EOrganizationMemberRole.CUSTOMER) {
      return false;
    }

    const { type } = currentOrganization;

    return type === EOrganizationType.FF || incotermsTrade === EFreightIncotermsTrade.IMPORT;
  },
);

const getShouldShowConsigneeSection = createSelector(
  localState,
  getCurrentOrganization,
  (state, currentOrganization) => {
    const { incotermsTrade } = state;

    if (!currentOrganization || currentOrganization.role !== EOrganizationMemberRole.CUSTOMER) {
      return false;
    }

    const { type } = currentOrganization;

    return Boolean(type === EOrganizationType.FF && incotermsTrade === EFreightIncotermsTrade.IMPORT);
  },
);

const getShouldShowConsigneeNRASection = createSelector(
  localState,
  (state) => {
    const { incotermsTrade } = state;

    return Boolean(incotermsTrade === EFreightIncotermsTrade.IMPORT);
  },
);

const getCompaniesList = createSelector(
  localState,
  (state) => state.companiesList,
);

const getSelectedCompany = createSelector(
  localState,
  (state) => state.selectedCompany,
);

const getSelectedConsigneeCompany = createSelector(
  localState,
  (state) => state.selectedConsigneeCompany,
);

const getConsigneeCompanyError = createSelector(
  localState,
  (state) => state.consigneeCompanyError,
);

const getShipmentReference = createSelector(
  localState,
  (state) => state.shipmentReference,
);

const getShipmentReferenceData = createSelector(
  localState,
  (state) => state.shipmentReferenceData,
);

const getCompanyError = createSelector(
  localState,
  (state) => state.companyError,
);

const getShipmentReferenceError = createSelector(
  localState,
  (state) => state.shipmentReferenceError,
);

const getShipmentPartyReference = createSelector(
  localState,
  (state) => state.shipmentPartyReference,
);

const getShipmentPartyReferenceError = createSelector(
  localState,
  (state) => state.shipmentPartyReferenceError,
);

const getShipmentConsigneeReference = createSelector(
  localState,
  (state) => state.shipmentConsigneeReference,
);

const getShipmentConsigneeReferenceError = createSelector(
  localState,
  (state) => state.shipmentConsigneeReferenceError,
);

const getIsCurrentOrganizationCustomer = createSelector(
  getCurrentOrganization,
  (currentOrg) => !!currentOrg && currentOrg.role === EOrganizationMemberRole.CUSTOMER,
);

const getIsNRAChecked = createSelector(
  localState,
  (state) => state.isNRAChecked,
);

const getIsTermsAndConditionsChecked = createSelector(
  localState,
  (state) => state.isTermsAndConditionsChecked,
);

const getIsAgreeAndBookDisabled = createSelector(
  getIsCurrentOrganizationCustomer,
  getIsNRAChecked,
  getIsTermsAndConditionsChecked,
  getIsUsShipmentOriginOrDestination,
  (isCustomerOrg,
    isNRAChecked,
    isTermsAndConditionsChecked,
    isUsShipmentOriginOrDestination) => !isCustomerOrg || (isUsShipmentOriginOrDestination && !isNRAChecked) || !isTermsAndConditionsChecked,
);

const getContactBookDefault = (state: RootState) => (
  state.bookingWizard.wizardContactBook.defaultState
);

const getContactBookUpdate = (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState
);

const getContactBookUpdateCompanyListByIds = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.companyList.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateErrorsCompanyListStateById = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.errors.companyListState.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateCompanyListAddressesByIds = (ids: string[]) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.companyListAddresses.filter((item) => ids.includes(item?.customId || '')).map((item) => CompanyAddressDTM.fromPlain(item))
);

const getContactBookUpdateCompanyListContactPersonsByIds = (ids: string[]) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.companyListContactPersons.filter((item) => ids.includes(item?.customId || '')).map((item) => CompanyContactDTM.fromPlain(item))
);

const getContactBookUpdateErrorsAddressesById = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.errors.addresses.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateErrorsContactPersonsById = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.errors.contactPersons.filter((item) => id === item.customId)?.[0]
);

const getActiveQuoteCharges = createSelector(
  localState,
  (state) => state.activeQuoteCharges,
);

const getIsWithGensetCharges = createSelector(
  getActiveQuoteCharges,
  (charges) => !!charges?.some((item) => item?.chargeCode?.code === GEN),
);

const getSumOfGenset = createSelector(
  getActiveQuoteCharges,
  (charges) => charges?.reduce((acc, item) => {
    if (item?.chargeCode?.code === GEN) {
      return acc + (item.totalCost || 0);
    }

    return acc;
  }, 0),
);

const getCargoSupplier = createSelector(
  localState,
  (state) => state.cargoSupplier,
);

const getCargoReceiver = createSelector(
  localState,
  (state) => state.cargoReceiver,
);

const getCargoSupplierCompany = createSelector(
  getCargoSupplier,
  (party) => party?.company,
);

const getCargoSupplierAddress = createSelector(
  getCargoSupplier,
  (party) => party?.address,
);

const getCargoSupplierContact = createSelector(
  getCargoSupplier,
  (party) => party?.contact,
);

const getCargoReceiverCompany = createSelector(
  getCargoReceiver,
  (party) => party?.company,
);

const getCargoReceiverAddress = createSelector(
  getCargoReceiver,
  (party) => party?.address,
);

const getCargoReceiverContact = createSelector(
  getCargoReceiver,
  (party) => party?.contact,
);

const getIsCargoSupplierRequiredErrorVisible = createSelector(
  localState,
  (state) => state.isCargoSupplierRequiredErrorVisible,
);

const getIsCargoReceiverRequiredErrorVisible = createSelector(
  localState,
  (state) => state.isCargoReceiverRequiredErrorVisible,
);

const getShipmentRoles = createSelector(
  localState,
  (state) => state.shipmentRoles,
);

const getIsOriginPartner = createSelector(
  getShipmentRoles,
  (roles) => roles.includes(EShipmentOrganizationRole.ORIGIN_PARTNER),
);

const getIsDestinationPartner = createSelector(
  getShipmentRoles,
  (roles) => roles.includes(EShipmentOrganizationRole.DESTINATION_PARTNER),
);

const getIsCreateCompanyAddressBlocked = createSelector(
  localState,
  (state) => state.isCreateCompanyAddressBlocked,
);

const getSupplierStepActiveTab = createSelector(
  localState,
  (state) => state.suppliersStepActiveTab,
);

const getIsLoadingMethodsInformationMissing = createSelector(
  getIsOriginPartner,
  getIsShipmentWaitingForApproval,
  (isOriginPartner, isShipmentWaitingForApproval) => isOriginPartner && isShipmentWaitingForApproval,
);

const getContainersTotalValue = createSelector(
  getContainers,
  (containers) => containers.length * CONTAINER_MAX_COST_VALUE,
);

const getIsContainersValueWarningVisible = createSelector(
  getIsOriginPartner,
  getIsShipmentWaitingForApproval,
  getContainersTotalValue,
  getCargos,
  (isOriginPartner, isShipmentWaitingForApproval, totalAllowedValue, cargos) => {
    const actualCost = cargos.reduce((prev, current) => (Number(current.value) || 0) + prev, 0);

    return isOriginPartner && isShipmentWaitingForApproval && actualCost >= totalAllowedValue;
  },
);

const getSupplierLoadingControls = createSelector(
  localState,
  (state) => state.supplierLoadingControls,
);

const getReceiverLoadingControls = createSelector(
  localState,
  (state) => state.receiverLoadingControls,
);

const getSupplierPhoneErrorMessage = createSelector(
  localState,
  (state) => state.supplierPhoneErrorMessage,
);

const getSupplierAdditionalErrorMessage = createSelector(
  localState,
  (state) => state.supplierAdditionalPhoneErrorMessage,
);

const getReceiverPhoneErrorMessage = createSelector(
  localState,
  (state) => state.receiverPhoneErrorMessage,
);

const getReceiverAdditionalErrorMessage = createSelector(
  localState,
  (state) => state.receiverAdditionalPhoneErrorMessage,
);

const getIsThereAnyPhoneError = createSelector(
  getSupplierPhoneErrorMessage,
  getSupplierAdditionalErrorMessage,
  getReceiverPhoneErrorMessage,
  getReceiverAdditionalErrorMessage,
  (supplierPhoneErrorMessage, supplierAdditionalPhoneErrorMessage, receiverPhoneErrorMessage, receiverAdditionalPhoneErrorMessage) => !!supplierPhoneErrorMessage?.length || !!supplierAdditionalPhoneErrorMessage?.length || !!receiverPhoneErrorMessage?.length || !!receiverAdditionalPhoneErrorMessage?.length,
);

const getChosenCompany = createSelector(
  localState,
  (state) => {
    const {
      suppliersStepActiveTab,
      cargoSupplier,
      cargoReceiver,
      companiesList,
    } = state;
    const shippingParty = suppliersStepActiveTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? cargoSupplier : cargoReceiver;

    if (!shippingParty?.company?.id) {
      return null;
    }

    const targetCompany = companiesList.find((_company) => _company.id === shippingParty?.company?.id);

    return targetCompany;
  },
);

const getContactsForChosenCompany = createSelector(
  getChosenCompany,
  (company) => company?.contacts || [],
);

const getChosenContactId = createSelector(
  localState,
  (state) => state.chosenContactId,
);

const getIsAbleToSaveAddressToContactBook = createSelector(
  localState,
  getChosenCompany,
  getCargoSupplierAddress,
  getCargoReceiverAddress,
  (state, company, supplierAddress, receiverAddress) => {
    const { suppliersStepActiveTab } = state;
    const address = suppliersStepActiveTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? supplierAddress : receiverAddress;

    if (!company || !address || !company.addresses.length) {
      return false;
    }

    const { addresses } = company;

    const similarAddresses = addresses.filter((companyAddress) => areAddressesEqual(address, companyAddress));

    return !similarAddresses.length;
  },
);

const getIsSavingAddress = createSelector(
  localState,
  (state) => state.isSavingAddress,
);

export const bookingWizardSelectors = {
  getDefaultCargo,
  getIsContentUpdating,
  getIsSecondStep,
  getContainersAmount,
  getCurrentStep,
  getSupplierLoadingControls,
  getReceiverLoadingControls,
  getShipmentId,
  getShipmentNRAFileNamePrint,
  getShipmentTermsOfServiceFileNamePrint,
  getVoyageDuration,
  getShipmentOrigin,
  getShipmentDestination,
  getCreatedCargosIds,
  getCargos,
  getShouldHaveAnyHazmats,
  getCargoById,
  getHasTemperatureControl,
  getIsFirstCargo,
  getIsHazmatToggledByCargoId,
  getCargosTotalWeightAndVolume,
  hasErrorsAtAnyCargo,
  hasErrorsAtAnyContainers,
  getIsCargoForcedToBeHazmat,
  getIsHazmatErrorVisible,
  getIsHaveAccountLimit,
  getIsShowAccountLimit,
  getHSCodeBEValidationErrorMessage,
  getIsLoading,
  getWasInformationSavedOnce,
  getNewBookPartiesId,
  getNewBookPartiesConsigneeId,
  getTotalCost,
  getCargosToDeleteIds,
  getEmptyReleaseDate,
  getContainers,
  getContainerById,
  getContainerErrorsById,
  getHasSOC,
  getNameSCAC,
  getEmptyReleaseDateError,
  getCurrentOrganization,
  getShouldShowShipperSection,
  getCompaniesList,
  getSelectedCompany,
  getShipmentReference,
  getShipmentReferenceData,
  getCompanyError,
  getShipmentReferenceError,
  getShouldShowConsigneeSection,
  getShouldShowConsigneeNRASection,
  getIsCurrentOrganizationCustomer,
  getIsNRAChecked,
  getIsTermsAndConditionsChecked,
  getIsAgreeAndBookDisabled,
  getContactBookDefault,
  getContactBookUpdate,
  getContactBookUpdateCompanyListByIds,
  getContactBookUpdateErrorsCompanyListStateById,
  getContactBookUpdateCompanyListAddressesByIds,
  getContactBookUpdateCompanyListContactPersonsByIds,
  getContactBookUpdateErrorsAddressesById,
  getContactBookUpdateErrorsContactPersonsById,
  getIsUsShipmentOriginOrDestination,
  getShipment,
  getShipmentPartyReference,
  getShipmentPartyReferenceError,
  getIsWithGensetCharges,
  getSumOfGenset,
  getShipmentConsigneeReference,
  getShipmentConsigneeReferenceError,
  getSelectedConsigneeCompany,
  getConsigneeCompanyError,
  getCargoSupplier,
  getCargoReceiver,
  getCargoSupplierCompany,
  getCargoSupplierAddress,
  getCargoSupplierContact,
  getCargoReceiverCompany,
  getCargoReceiverAddress,
  getCargoReceiverContact,
  getIsCargoSupplierRequiredErrorVisible,
  getIsCargoReceiverRequiredErrorVisible,
  getContainersErrors,
  getShipmentRoles,
  getIsOriginPartner,
  getIsCreateCompanyAddressBlocked,
  getSupplierStepActiveTab,
  getIsShipmentWaitingForApproval,
  getIsLoadingMethodsInformationMissing,
  getContainersTotalValue,
  getIsContainersValueWarningVisible,
  getSupplierAdditionalErrorMessage,
  getSupplierPhoneErrorMessage,
  getReceiverPhoneErrorMessage,
  getReceiverAdditionalErrorMessage,
  getIsThereAnyPhoneError,
  getContactsForChosenCompany,
  getChosenContactId,
  getIsAbleToSaveAddressToContactBook,
  getIsSavingAddress,
  getIsDestinationPartner,
};
