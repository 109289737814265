import React, {
  FC, useCallback, useMemo, useState, Key,
} from 'react';
import get from 'lodash/fp/get';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import { Footer } from 'app-wrapper/view/drawers/components';
import { useToggleCollapse } from 'shipment-operations/view/hooks';

import { DispatchedContainerDTM, ShipmentTrackerTableDTM } from 'shipment-operations/models/dtm';
import { DateDtm } from 'app-wrapper/models/dtm';

import {
  StyledType,
} from 'shipment-operations/view/pages/ShipmentTransportationTracker/components/ContainersTable/ContainersTable.styled';
import {
  StyledExpandIcon,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable/CustomTable.styled';
import { ContainerTitleWrapper, ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';
import { InnerSteps } from './InnerSteps';
import { StyledWrapper, StyledTableRadio } from './LinkContainer.styled';

interface ILinkContainerComponentProps {
  onClose: () => void;
  containers: DispatchedContainerDTM[];
  onLinkContainer: (shipmentId: string, containerNumber: string, planId: number) => void;
  planId?: number;
  isLoadingAction: boolean;
}

const TIME_FORMAT = 'D MMM YYYY, HH:mm';

const LinkContainerComponent: FC<ILinkContainerComponentProps> = ({
  onClose, containers, onLinkContainer, planId, isLoadingAction,
}) => {
  const { openedKeys, onToggleCollapse } = useToggleCollapse();
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const [selectedNumber, setSelectedNumber] = useState<Key[]>([]);

  const columns = useMemo(() => [
    {
      title: 'Container',
      dataIndex: 'number',
      key: 'number',
      width: '33%',
      render: (number: string, record: ShipmentTrackerTableDTM | {}) => {
        const key = get(['key'], record);
        const type = get(['type'], record);
        return (
          <StyledType onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
            <StyledExpandIcon isActive={openedKeys.includes(key)} />
            <ContainerTitleWrapper>
              <ContainerType active={openedKeys.includes(key)}>
                {type} &apos;
              </ContainerType>
            </ContainerTitleWrapper>
            {number}
          </StyledType>
        );
      },
    },
    {
      title: 'Previous Event',
      dataIndex: 'previousEvent',
      key: 'previousEvent',
      width: '33%',
      render: (name) => name && t(`eventsCodes.${name}`),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '33%',
      render: (date: DateDtm) => (date ? date?.getDateAsMomentWithOffset().format(TIME_FORMAT) : ''),
    },
  ], [openedKeys]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => { // поменять any на ДТМ, ошибка тс поменять тп
      setSelectedNumber(selectedRowKeys);
    },
  };

  const expandRender = (record: DispatchedContainerDTM | {}) => {
    const events = get(['events'], record);

    return (
      <InnerSteps data={events} />
    );
  };

  const handleLink = useCallback(() => {
    if (shipmentId && selectedNumber && planId) {
      onLinkContainer(shipmentId, selectedNumber[0].toString(2), planId);
    }
  }, [selectedNumber]);

  return (
    <ShipmentDrawerWrapper
      title={t('ContainersBatch')}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <StyledWrapper>
        <StyledTableRadio
          columns={columns}
          dataSource={containers}
          pagination={false}
          expandedRowClassName={() => 'customExpandRow'}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          expandable={{
            expandedRowRender: expandRender,
            expandedRowKeys: openedKeys,
            expandIconColumnIndex: -1,
          }}
        />
        <Footer
          onAccept={handleLink}
          closeDrawer={onClose}
          isLoading={false}
          isAbleToAccept={!!selectedNumber}
          okText={t('Link')}
          hideCancel
          isLoadingAccept={isLoadingAction}
        />
      </StyledWrapper>
    </ShipmentDrawerWrapper>
  );
};

export { LinkContainerComponent };
