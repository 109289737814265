import {
  IsDefined, IsString, IsNumber, IsEnum, IsBoolean, IsOptional, ValidateNested, validateSync,
} from 'class-validator';
import { Type } from 'class-transformer';
import i18n from 'i18next';

import { IReferenceDTM, ReferenceDTM } from 'app-wrapper/types/Reference';
import { BaseDTM } from 'proto/BaseDTM';

import {
  ContainerReeferTypes,
  ContainerUsualTypes,
  EContainerVGMMethodTypes,
  EContainerVGMTypes,
  EContainerRailBillingTypes,
} from 'shipment-operations/constants';
import { DepartureDetailsStatusEnum } from 'shipment-operations/constants/DepartureDetailsStatus.enum';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import { CargoDTM, ICargoDTM } from 'shipment-operations/models/dtm';
import {
  DateDtm, DocumentDTM, IDocumentDTM,
} from 'app-wrapper/models/dtm';
import { UploadFileStatus } from 'antd/lib/upload/interface';

export interface IContainerRailBillingDTM {
  status: EContainerRailBillingTypes;
}

export class ContainerRailBillingDTM extends BaseDTM<IContainerRailBillingDTM> {
  @IsDefined()
  @IsEnum(EContainerRailBillingTypes)
  status: EContainerRailBillingTypes;
}

export interface IContainerVGMDTM {
  value: number;
  method: keyof typeof EContainerVGMMethodTypes;
  status: keyof typeof EContainerVGMTypes;
}

export class ContainerVGMDTM extends BaseDTM<IContainerVGMDTM> {
  @IsDefined()
  @IsNumber()
  value: number;

  @IsDefined()
  @IsEnum(EContainerVGMMethodTypes)
  method: EContainerVGMMethodTypes;

  @IsDefined()
  @IsEnum(EContainerVGMTypes)
  status: EContainerVGMTypes;
}

export interface IContainerCargoShortItemDTM {
  id: string
  cargoId: string
  packagesNumber: string
  weight: string
  volume: string
}

export class ContainerCargoShortItemDTM extends BaseDTM<IContainerCargoShortItemDTM> {
  @IsString()
  id: string;

  @IsString()
  cargoId: string;

  @IsString()
  packagesNumber: string;

  @IsString()
  weight: string;

  @IsString()
  volume: string;
}

export interface IContainerDocumentDTM {
  uid: string
  response: IDocumentDTM
  name: string
  url: string
  status: UploadFileStatus
}

export class ContainerDocumentDTM extends BaseDTM<IContainerDocumentDTM> {
  @IsDefined()
  @IsString()
  uid: string

  @IsDefined()
  @ValidateNested()
  @Type(() => DocumentDTM)
  response: DocumentDTM

  @IsDefined()
  @IsString()
  name: string

  @IsDefined()
  @IsString()
  url: string

  @IsDefined()
  @IsString()
  status: UploadFileStatus
}

export interface IContainerDepartureDetailsDTM {
  id?: number
  drayageSide?: keyof typeof EDrayageSide
  arrivalDate?: DateDtm
  departureDate?: DateDtm
  dispatchOrder?: string
  reference?: string
  status?: keyof typeof DepartureDetailsStatusEnum
}

export class ContainerDepartureDetailsDTM extends BaseDTM<IContainerDepartureDetailsDTM> {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsEnum(EDrayageSide)
  drayageSide?: EDrayageSide;

  @IsOptional()
  @Type(() => DateDtm)
  arrivalDate?: DateDtm

  @IsOptional()
  @Type(() => DateDtm)
  departureDate?: DateDtm

  @IsOptional()
  @IsString()
  dispatchOrder?: string

  @IsOptional()
  @IsString()
  reference?: string

  @IsOptional()
  @IsEnum(DepartureDetailsStatusEnum)
  status?: DepartureDetailsStatusEnum;
}

export interface IContainerInputDTM {
  id?: string
  type: ContainerReeferTypes | ContainerUsualTypes
  number?: string | null
  sealNumber?: string
  ownContainer?: boolean
  rateId?: string
  planId?: string
  name?: string
  seaworthyCertificate?: IContainerDocumentDTM | null
  cargoItems: IContainerCargoShortItemDTM[]
  isInDraft: boolean
  isVirtual: boolean
  references: IReferenceDTM[]
  vgm?: IContainerVGMDTM
  railBilling?: IContainerRailBillingDTM
  estimatedWeight: number;
  estimatedVolume: number;
  departureDetails?: IContainerDepartureDetailsDTM[]
}

export class ContainerDTM extends BaseDTM<IContainerInputDTM> {
  @IsString()
  id: string

  @IsDefined()
  @IsEnum([...Object.values(ContainerReeferTypes), ...Object.values(ContainerUsualTypes)])
  type: ContainerReeferTypes | ContainerUsualTypes;

  @IsString()
  @IsOptional()
  number?: string | null;

  @IsString()
  sealNumber: string;

  @IsBoolean()
  ownContainer: boolean;

  @IsString()
  rateId: string;

  @IsString()
  planId: string;

  @IsString()
  name: string;

  @ValidateNested()
  @Type(() => ContainerCargoShortItemDTM)
  cargoItems: ContainerCargoShortItemDTM[]

  // TODO: unused field, should be excluded or refactored
  @IsDefined()
  @IsBoolean()
  isInDraft: boolean;

  // if container created for FE, but no instance for BE
  @IsDefined()
  @IsBoolean()
  isVirtual: boolean;

  @IsDefined()
  @ValidateNested()
  @Type(() => ReferenceDTM)
  references: ReferenceDTM[]

  @ValidateNested()
  @IsOptional()
  @Type(() => ContainerDocumentDTM)
  seaworthyCertificate: ContainerDocumentDTM | null;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContainerVGMDTM)
  vgm?: ContainerVGMDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContainerRailBillingDTM)
  railBilling?: ContainerRailBillingDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContainerDepartureDetailsDTM)
  departureDetails?: ContainerDepartureDetailsDTM[]

  @IsDefined()
  @IsNumber()
  estimatedWeight: number;

  @IsDefined()
  @IsNumber()
  estimatedVolume: number;

  departureDetailByType(drayageSide: EDrayageSide) {
    return this.departureDetails?.find((detail) => detail.drayageSide === drayageSide);
  }

  public getContainerName = () => {
    const { number } = this;

    if (number) {
      return number;
    }

    return i18n.t('Number pending entry');
  };

  isValidWithMissingPropSkip = () => validateSync(this, { skipMissingProperties: true }).length === 0;

  validateWithoutMissingProp = () => validateSync(this, { skipMissingProperties: true });
}

export interface IContainerSumDTM {
  volume: number;
  weight: number;
  containersNumber: number;
  packagesNumber: number;
  value: number;
}

export class ContainerSumDTM extends BaseDTM<IContainerSumDTM> {
  @IsNumber()
  @IsDefined()
  volume: number;

  @IsNumber()
  @IsDefined()
  weight: number;

  @IsNumber()
  @IsDefined()
  containersNumber: number;

  @IsNumber()
  @IsDefined()
  packagesNumber: number;

  @IsNumber()
  @IsDefined()
  value: number;
}

export interface IContainerSumWithTypeDTM {
  volume: number;
  weight: number;
  containersNumber: number;
  packagesNumber: number;
  value: number;
  type: ContainerReeferTypes | ContainerUsualTypes;
}

export class ContainerSumWithTypeDTM extends BaseDTM<IContainerSumWithTypeDTM> {
  @IsNumber()
  @IsDefined()
  volume: number;

  @IsNumber()
  @IsDefined()
  weight: number;

  @IsNumber()
  @IsDefined()
  containersNumber: number;

  @IsNumber()
  @IsDefined()
  packagesNumber: number;

  @IsNumber()
  @IsDefined()
  value: number;

  @IsDefined()
  @IsEnum([...Object.values(ContainerReeferTypes), ...Object.values(ContainerUsualTypes)])
  type: ContainerReeferTypes | ContainerUsualTypes;
}

export interface IShortContainerDTM {
  id: number
  type: ContainerReeferTypes | ContainerUsualTypes
  number?: string
}

export class ShortContainerDTM extends BaseDTM<IShortContainerDTM> {
  @IsNumber()
  @IsDefined()
  id: number

  @IsDefined()
  @IsEnum([...Object.values(ContainerReeferTypes), ...Object.values(ContainerUsualTypes)])
  type: ContainerReeferTypes | ContainerUsualTypes;

  @IsString()
  @IsOptional()
  number?: string;
}

export interface ICargoItemWithCargoDTM extends IContainerCargoShortItemDTM {
  cargo: ICargoDTM;
}

export class CargoItemWithCargoDTM extends BaseDTM<ICargoItemWithCargoDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => CargoDTM)
  cargo: CargoDTM

  @IsDefined()
  @IsString()
  id: string;

  @IsDefined()
  @IsString()
  cargoId: string;

  @IsDefined()
  @IsString()
  packagesNumber: string;

  @IsDefined()
  @IsString()
  weight: string;

  @IsDefined()
  @IsString()
  volume: string;
}

export interface IContainerWithCargoDTM extends Omit<IContainerInputDTM, 'cargoItems' | 'seaworthyCertificate'> {
  cargoItems: ICargoItemWithCargoDTM[];
}

export class ContainerWithCargoDTM extends BaseDTM<IContainerWithCargoDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => CargoItemWithCargoDTM)
  cargoItems: ICargoItemWithCargoDTM[]

  @IsString()
  @IsDefined()
  id: string

  @IsDefined()
  @IsEnum([...Object.values(ContainerReeferTypes), ...Object.values(ContainerUsualTypes)])
  type: ContainerReeferTypes | ContainerUsualTypes;

  @IsString()
  @IsOptional()
  number?: string;

  @IsString()
  @IsDefined()
  sealNumber: string;

  @IsBoolean()
  @IsDefined()
  ownContainer: boolean;

  @IsString()
  @IsDefined()
  rateId: string;

  @IsString()
  @IsDefined()
  planId: string;

  @IsString()
  @IsDefined()
  name: string;

  @IsBoolean()
  @IsDefined()
  isInDraft: boolean;

  @IsBoolean()
  @IsDefined()
  isVirtual: boolean;

  @ValidateNested()
  @IsDefined()
  @Type(() => ReferenceDTM)
  references: ReferenceDTM[]

  @IsDefined()
  @IsNumber()
  estimatedWeight: number;

  @IsDefined()
  @IsNumber()
  estimatedVolume: number;
}

export interface IContainerForTableDTM {
  title: string | number;
  value: string | number
  key: string | number
  children?: IContainerForTableDTM[];
  disabled: boolean;
  type?: string;
}

export class ContainerForTableDTM extends BaseDTM<IContainerForTableDTM> {
  @IsOptional()
  title: string | number;

  @IsOptional()
  value: string | number;

  @IsOptional()
  key: string | number;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContainerForTableDTM)
  children?: ContainerForTableDTM[];

  @IsBoolean()
  @IsDefined()
  disabled: boolean;

  @IsOptional()
  @IsString()
  type?: string;
}

export interface IContainerTypeWithQTYDTM {
  type: ContainerReeferTypes | ContainerUsualTypes;
  quantity: number;
}

export class ContainerTypeWithQTYDTM extends BaseDTM<IContainerTypeWithQTYDTM> {
  @IsDefined()
  @IsString()
  type: ContainerReeferTypes | ContainerUsualTypes;

  @IsDefined()
  @IsNumber()
  quantity: number;
}
