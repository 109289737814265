import React, { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { Textarea } from 'app-wrapper/view/components';
import { ChangeRequestStore } from 'shipment-operations/repository/mobxStores';
import { ChangeRequestDrawerController } from 'shipment-operations/controllers/ChangeRequestDrawerController';
import { useTranslation } from 'react-i18next';

export const TextContainer = observer(() => {
  const { t } = useTranslation();
  const controller = useCurrentController<ChangeRequestDrawerController>();
  const store = useCurrentControllerStore<ChangeRequestStore>();
  const textStore = store.state.form.text;

  const text = useMemo(() => controller.text(), []);

  const handleOnChange = useCallback((e) => {
    text.onChange(e.target.value);
  }, []);

  const handleOnBlur = useCallback(() => {
    text.onBlur();
  }, []);

  return (
    <Textarea
      placeholder={t('Please specify an information you would like to update...')}
      value={textStore?.value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
});
