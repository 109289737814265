import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainersAddContainerModalComponent } from './ShipmentContainersAddContainerModal.component';

const ShipmentContainersAddContainerModalContainer: FC = () => {
  const { shipmentId } = useParams<'shipmentId'>();
  const containerBasicType = useSelector(R.selectors.shipment.getContainersBasicType);
  const isLoading = useSelector(R.selectors.shipmentNewContainer.getLoadingState);
  const isDisabled = useSelector(R.selectors.shipmentContainers.isNewContainerCreated);
  const disable = useSelector(R.selectors.shipment.getIsDisableChanges);

  return (
    <ShipmentContainersAddContainerModalComponent
      isLoading={isLoading}
      isDisabled={isDisabled}
      containerBasicType={containerBasicType}
      onAddHandler={UC.shipmentContainers.addContainer}
      openChangeRequestDrawer={UC.shipmentContainers.openChangeRequestDrawer}
      shipmentId={shipmentId}
      disable={disable}
    />
  );
};

export {
  ShipmentContainersAddContainerModalContainer as ShipmentContainersAddContainerModal,
};
