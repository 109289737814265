import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import Skeleton from 'antd/es/skeleton';

import { Divider } from 'app-wrapper/view/components';
import { ContainerDTM, BookingWizardContainersErrorsDTM } from 'shipment-operations/models/dtm';

import {
  Section,
  Row,
  SectionTitle,
  DatePickerLabel,
  DatePickerWrapper,
  DatePickerStyled,
  HeaderContent,
  HeaderText,
  Content,
  ContentRow,
  Warning,
  WarningText,
  WarningIcon,
  ContainersHeader,
  HeaderTitle,
  HeaderTitleGrey,
} from './ContainersDetails.styled';
import {
  ContainerName,
  ContainerNumberInput,
  SealNumberInput,
  SOCDocumentUpload,
  Container,
} from './components';

interface ContainersDetailsComponentProps {
  isPending: boolean;
  hasSOC: boolean;
  date?: Moment;
  onChange: (value: Moment | null) => void;
  containers: ContainerDTM[];
  error: string;
  setContainerWeightById: (id: string, weight: number) => void;
  setContainerVolumeById: (id: string, volume: number) => void;
  setContainerReferenceById: (id: string, reference: string) => void;
  containersErrors: BookingWizardContainersErrorsDTM[];
  isDoorShipment?: boolean;
}

export const ContainersDetailsComponent: FC<ContainersDetailsComponentProps> = ({
  date,
  hasSOC,
  onChange,
  isPending,
  containers,
  error,
  setContainerReferenceById,
  setContainerVolumeById,
  setContainerWeightById,
  containersErrors = [],
  isDoorShipment,
}) => {
  const { t } = useTranslation();

  return isPending ? (
    <Skeleton />
  ) : (
    <>
      {error ? (
        <Warning>
          <WarningIcon />

          <WarningText>
            {error}
          </WarningText>
        </Warning>
      ) : null}

      <Section>
        <Row
          style={{
            paddingBottom: hasSOC ? 3 : 0,
            height: 30,
          }}
        >
          <SectionTitle>
            {t('Containers Details')}
          </SectionTitle>

          <DatePickerWrapper>
            <DatePickerLabel>
              {t('Empty Release Date')}
            </DatePickerLabel>

            <DatePickerStyled
              value={date}
              onChange={onChange}
            />
          </DatePickerWrapper>
        </Row>

        {hasSOC ? (
          <>
            <HeaderContent>
              <HeaderText>
                {t('Container Type')}
              </HeaderText>
              <HeaderText>
                {t('Container Number *')}
              </HeaderText>
              <HeaderText>
                {t('Seal Number')}
              </HeaderText>
              <HeaderText>
                {t('Seaworthiness Certificate *')}
              </HeaderText>
            </HeaderContent>

            <Content>
              {containers.map((container) => (
                <ContentRow key={`ContainersDetailsComponent_${container.id}`}>
                  <ContainerName containerId={container.id} />
                  <ContainerNumberInput containerId={container.id} />
                  <SealNumberInput containerId={container.id} />
                  <SOCDocumentUpload containerId={container.id} />
                </ContentRow>
              ))}
            </Content>
          </>
        ) : (
          <>
            <Divider
              style={{
                margin: '16px 0 8px 0',
              }}
              dashed
            />

            <ContainersHeader>
              <HeaderTitle>
                {t('Container Type')}
              </HeaderTitle>

              <HeaderTitle>
                {isDoorShipment ? t('Pickup Reference') : null}
              </HeaderTitle>

              <HeaderTitle>
                {t('Weight')}
                &nbsp;
                <HeaderTitleGrey>
                  {t('kg')}
                </HeaderTitleGrey>
              </HeaderTitle>

              <HeaderTitle>
                {t('Volume')}
                &nbsp;
                <HeaderTitleGrey>
                  {t('cbm')}
                </HeaderTitleGrey>
              </HeaderTitle>
            </ContainersHeader>

            {containers.map((container) => (
              <Container
                key={container.id}
                container={container}
                isDoorShipment={isDoorShipment}
                setContainerWeightById={setContainerWeightById}
                setContainerVolumeById={setContainerVolumeById}
                setContainerReferenceById={setContainerReferenceById}
                errors={containersErrors.find((_error) => _error.containerId === container.id)}
              />
            ))}
          </>
        )}
      </Section>
    </>
  );
};
