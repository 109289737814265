import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SHIPPING_PARTY_MAX_LENGTHS } from 'app-wrapper/constants';

import { StyledInput } from './TaxIDInput.styled';

interface ITaxIDInputComponentProps {
  taxId?: string;
  setTaxId: (taxId: string) => void;
}

export const TaxIdInputComponent: FC<ITaxIDInputComponentProps> = ({
  taxId,
  setTaxId,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    setTaxId(e.target.value);
  }, [setTaxId]);

  return (
    <StyledInput
      value={taxId}
      onChange={handleChange}
      placeholder={t('Example: +91310333717869141R: DP')}
      maxLength={SHIPPING_PARTY_MAX_LENGTHS.TAX_ID}
    />
  );
};
