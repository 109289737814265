import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router';
import Tabs from 'antd/es/tabs';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { hasAccess, hasReadAccess } from 'app-wrapper/utils';
import { RouteNames } from 'app-wrapper/constants';
import { SubPageTabs } from 'app-wrapper/view/guideline';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { StyledButton } from 'shipment-operations/view/pages/ShipmentTransportation/components/ShipmentTransportationTabs/ShipmentTransportationTabs.styled';

interface IDrayageTransportationTabsComponentProps {
  openAddCharge?: () => void
  addChargesImportPermissions?: PermissionAttributePolicy
  addChargesExportPermissions?: PermissionAttributePolicy
  trackerAndChargesVisibility?: PermissionAttributePolicy
}

const DrayageTransportationTabsComponent: FC<IDrayageTransportationTabsComponentProps> = ({
  openAddCharge,
  addChargesImportPermissions,
  addChargesExportPermissions,
  trackerAndChargesVisibility,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isExport } = useIsExportOrImport();

  const onChangeTab = useCallback((tab) => {
    navigate(tab, { replace: true });
  }, []);

  const isAbleToWriteExportCharges = hasAccess(addChargesExportPermissions, PermissionAttributePolicy.WRITE);
  const isAbleToWriteImportCharges = hasAccess(addChargesImportPermissions, PermissionAttributePolicy.WRITE);
  const isAbleToViewChargesAndTracker = hasReadAccess(trackerAndChargesVisibility);

  const extraContent = useMemo(() => {
    if (pathname === RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_CHARGES(shipmentId) && isAbleToWriteExportCharges) {
      return (
        <StyledButton onClick={openAddCharge} icon={<PlusOutlined />}>{t('addCharge')}</StyledButton>
      );
    }

    if (pathname === RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_CHARGES(shipmentId) && isAbleToWriteImportCharges) {
      return (
        <StyledButton onClick={openAddCharge} icon={<PlusOutlined />}>{t('addCharge')}</StyledButton>
      );
    }

    return undefined;
  }, [pathname, shipmentId, isAbleToWriteExportCharges, isAbleToWriteImportCharges]);

  return (
    <SubPageTabs
      activeKey={pathname}
      onChange={onChangeTab}
      tabBarExtraContent={extraContent}
    >
      <Tabs.TabPane
        tab={t('Overview')}
        key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_OVERVIEW(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_OVERVIEW(shipmentId)}
      />

      {isAbleToViewChargesAndTracker ? (
        <>
          <Tabs.TabPane
            tab={t('Tracker')}
            key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_TRACKER(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_TRACKER(shipmentId)}
          />
          <Tabs.TabPane
            tab={t('Charges')}
            key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_CHARGES(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_CHARGES(shipmentId)}
          />
        </>
      ) : null}
    </SubPageTabs>
  );
};

export { DrayageTransportationTabsComponent };
