import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import { Tooltip } from 'app-wrapper/view/components';
import {
  CustomSeparator,
  EmptyNumber,
  Number,
  NumberDisabled,
  SecondNumber,
  StepWrapper,
  Title,
  Wrapper,
  WarningIcon,
} from 'shipment-operations/view/components/BookingDrawer/components/StepsHeader/StepsHeader.styled';

interface StepsHeaderComponentProps {
  currentStep: number;
  isCustomerOrg: boolean;
  isSuppliersInformationMissing: boolean;
}

export const StepsHeaderComponent: FC<StepsHeaderComponentProps> = ({
  currentStep,
  isCustomerOrg,
  isSuppliersInformationMissing,
}) => {
  const { t } = useTranslation();

  const isLastStep = currentStep === 3;

  return (
    <Wrapper>
      <StepWrapper>
        {currentStep > 1 ? (
          <EmptyNumber>
            <CheckOutlined style={{ color: '#6C79DE' }} />
          </EmptyNumber>
        ) : (
          <Number>1</Number>
        )}
        <Title isSecondStep>
          {t('Booking Details')}
        </Title>
      </StepWrapper>

      <CustomSeparator isSecondStep={currentStep > 1} />

      <StepWrapper>
        <>
          {currentStep > 2 ? (
            <EmptyNumber>
              <CheckOutlined style={{ color: '#6C79DE' }} />
            </EmptyNumber>
          ) : (
            <>
              {currentStep === 2 ? (
                <Number>2</Number>
              ) : (
                <>
                  {isSuppliersInformationMissing ? (
                    <Tooltip title={t('Waiting for additional information')}>
                      <WarningIcon />
                    </Tooltip>
                  ) : (
                    <NumberDisabled>2</NumberDisabled>
                  )}
                </>
              )}
            </>
          )}
        </>

        <Title isSecondStep={currentStep > 1 || isSuppliersInformationMissing}>
          {t('Pickup & Delivery')}
        </Title>
      </StepWrapper>

      {isCustomerOrg ? (
        <>
          <CustomSeparator isSecondStep={isLastStep} />

          <StepWrapper>
            <SecondNumber isSecondStep={isLastStep}>3</SecondNumber>

            <Title isSecondStep={isLastStep}>
              {t('Review and Confirm')}
            </Title>
          </StepWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};
