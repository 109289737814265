import { plainToInstance } from 'class-transformer';
import moment, { Moment } from 'moment';
import {
  IsDate, IsDefined, IsNumber,
} from 'class-validator';

export interface IDateDTM {
  date: Date
  offset: number
}

export type TDateDTMInput = {
  date: string
  offset: number
}

// this model separates date and offset, date have local TZ offset, but time value should be shifted for target location
export class DateDtm implements IDateDTM {
  @IsDefined()
  @IsDate()
  date: Date

  @IsDefined()
  @IsNumber()
  offset: number

  static fromPlain(input: TDateDTMInput | string | Moment) {
    if (moment.isMoment(input)) {
      return plainToInstance(DateDtm, {
        date: (input as Moment).toDate(),
        offset: (input as Moment).utcOffset(),
      });
    }

    if (typeof input === 'string') {
      return plainToInstance(DateDtm, {
        date: moment.parseZone(input).toDate(),
        offset: moment.parseZone(input).utcOffset(),
      });
    }

    return plainToInstance(DateDtm, {
      date: moment.parseZone((input as TDateDTMInput).date).toDate(),
      offset: (input as TDateDTMInput).offset,
    });
  }

  static fromPlainWithDateShift = (input: TDateDTMInput) => plainToInstance(DateDtm, {
    date: moment.parseZone(input.date).utcOffset(input.offset, true).toDate(),
    offset: input.offset,
  })

  static getDifBetweenDates = (startDate: DateDtm, endDate: DateDtm) => moment.duration(moment(endDate.date).diff(moment(startDate.date)));

  getValueOf = () => moment(this.date).valueOf()

  // beware: this.date TZ will be ignored, only local time will be used
  getDateAsMomentWithOffset = () => {
    const date = moment(this.date);
    date.utcOffset(this.offset);

    return date;
  }

  getDateAsMomentLocalOffset = () => moment(this.date)

  getFormatDMMM = () => moment.parseZone(this.date).format('D MMM')

  getFormatDMMMHHmm = () => moment.parseZone(this.date).format('D MMM, HH:mm')

  getFormatDMMMHHmmWithOffset = () => moment.parseZone(this.getDateAsMomentWithOffset()).format('D MMM, HH:mm')

  getDateDMMMYYYYHHmm = () => moment.parseZone(this.date).format('D MMM YYYY, HH:mm')

  getDateYYYYMMDD = () => moment.parseZone(this.date).format('YYYY-MM-DD')

  getDateDDMMMHHmm = () => moment.parseZone(this.date).format('DD MMM, HH:mm')

  getDateMMMDYYYY = () => moment.parseZone(this.date).format('MMM D, YYYY')

  getDateDMMMYYYYHHmmWithOffset = () => moment.parseZone(this.getDateAsMomentWithOffset()).format('D MMM YYYY, HH:mm')

  getDateMMMDYYYYWithOffset = () => this.getDateAsMomentWithOffset().format('MMMM D, YYYY');

  getDateDMMMYYYY = () => moment.parseZone(this.date).format('D MMM YYYY')

  getDateDMMMYYYYWithOffset = () => moment.parseZone(this.getDateAsMomentWithOffset()).format('D MMM YYYY')

  getDateISO = () => moment(this.date).toISOString()

  getBackendFormatWithOffset() {
    return moment(this.date).utcOffset(this.offset).format('YYYY-MM-DDTHH:mm:ssZ');
  }

  addDays(days: number) {
    return DateDtm.fromPlain({
      date: moment(this.date).add(days, 'days').format(),
      offset: this.offset,
    });
  }

  addHours(hours: number) {
    return DateDtm.fromPlain({
      date: moment(this.date).add(hours, 'hours').format(),
      offset: this.offset,
    });
  }
}
