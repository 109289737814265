import React, {
  FC, useMemo, useState, useCallback, useEffect,
} from 'react';
import Skeleton from 'antd/es/skeleton';
import getOr from 'lodash/fp/getOr';
import { useTranslation } from 'react-i18next';
import EditOutlined from '@ant-design/icons/EditOutlined';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';

import { EditTransportPlan } from 'shipment-operations/view/components';
import { PreparedRoutesDTM, TrackerScheduleDTM } from 'shipment-operations/models/dtm';
import { DEPARTURE, ARRIVAL } from 'shipment-operations/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { TrackerHeaderDestination, TrackerHeaderSteps, TrackerHeaderDates } from './components';
import {
  TrackerHeaderContainer, TrackerHeaderDivider, TrackerHeaderWaypoints, EditWrapper, TitleWrapper,
  ShipmentDrawerWrapper,
} from './ShipmentTrackerHeader.styled';

export interface ITrackerHeaderComponentProps {
  routes: PreparedRoutesDTM
  isPageLoading: boolean
  skeletonHeight: string
  isLoadingSchedules: boolean
  isErrorSchedules: boolean
  schedule: TrackerScheduleDTM
  withEdit: boolean
  onClear: () => void
  setDefaultLegs: () => void
}

const ShipmentTrackerHeaderComponent: FC<ITrackerHeaderComponentProps> = ({
  routes,
  isPageLoading,
  isLoadingSchedules,
  isErrorSchedules,
  schedule,
  withEdit,
  onClear,
  setDefaultLegs,
  skeletonHeight,
}) => {
  const { t } = useTranslation();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const departure = useMemo(() => {
    const city = getOr('', ['route', DEPARTURE, 0, 'location', 'city'], routes);
    const name = getOr('', ['route', DEPARTURE, 0, 'location', 'name'], routes);
    const country = getOr('', ['route', DEPARTURE, 0, 'location', 'country', 'code'], routes);
    const actualTime = getOr(0, ['route', DEPARTURE, 0, 'actual'], routes);
    const estimatedTime = getOr(0, ['route', DEPARTURE, 0, 'estimated'], routes);
    return {
      name: `${city || name}, ${country}`,
      actualTime,
      estimatedTime,
    };
  }, [routes]);

  const arrival = useMemo(() => {
    const city = getOr('', ['route', ARRIVAL, 0, 'location', 'city'], routes);
    const name = getOr('', ['route', ARRIVAL, 0, 'location', 'name'], routes);
    const country = getOr('', ['route', ARRIVAL, 0, 'location', 'country', 'code'], routes);
    const actualTime = getOr(0, ['route', ARRIVAL, 0, 'actual'], routes);
    const estimatedTime = getOr(0, ['route', ARRIVAL, 0, 'estimated'], routes);
    return {
      name: `${city || name}, ${country}`,
      actualTime,
      estimatedTime,
    };
  }, [routes]);

  const onCloseEdit = useCallback(() => setIsOpenEdit(false), [setIsOpenEdit]);
  const onOpenEdit = useCallback(() => setIsOpenEdit(true), [setIsOpenEdit]);

  useEffect(() => {
    if (!isOpenEdit) {
      onClear();
    } else {
      setDefaultLegs();
    }
  }, [isOpenEdit]);

  if (isPageLoading) {
    return (
      <SkeletonSection height={skeletonHeight} />
    );
  }

  return (
    <TrackerHeaderContainer>
      <ShipmentDrawerWrapper
        title={(
          <div>
            <ArrowLeftOutlined onClick={onCloseEdit} style={{ marginRight: '10px' }} />
            {t('updateTransportPlan')}
          </div>
          )}
        maskClosable={false}
        onClose={onCloseEdit}
        visible={isOpenEdit}
        width={1137}
      >
        <EditTransportPlan onClose={onCloseEdit} />
      </ShipmentDrawerWrapper>

      {withEdit ? (
        <TitleWrapper>
          <EditWrapper onClick={onOpenEdit}>
            <EditOutlined style={{ margin: '0 7px 0 3px' }} />
            {t('update')}
          </EditWrapper>
        </TitleWrapper>
      ) : null}

      <TrackerHeaderSteps routes={routes} />
      <TrackerHeaderWaypoints>
        <TrackerHeaderDestination
          name={departure.name}
          estimatedTime={departure.estimatedTime}
          actualTime={departure.actualTime}
        />
        <TrackerHeaderDestination
          arrival
          name={arrival.name}
          estimatedTime={arrival.estimatedTime}
          actualTime={arrival.actualTime}
        />
      </TrackerHeaderWaypoints>
      <TrackerHeaderDivider />
      {isLoadingSchedules && (
        <Skeleton />
      )}
      {!isLoadingSchedules && !isErrorSchedules && (
        <TrackerHeaderDates
          earliestCyEmptyRelease={schedule.cyAvailable}
          earliestCtoFullDropDate={schedule.ctoAvailable}
          portCutoff={schedule.terminalCutOff}
          vgmCutoff={schedule.vgmCutOff}
          documentCutOff={schedule.documentCutOff}
        />
      )}
    </TrackerHeaderContainer>
  );
};

export { ShipmentTrackerHeaderComponent };
