import React, { FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';
import { SHIPPING_PARTY_MAX_LENGTHS } from 'app-wrapper/constants';

interface IShippingPartiesCityInputComponentProps {
  city: string;
  setCity: (city: string) => void;
  error?: boolean;
}

export const CityInputComponent: FC<IShippingPartiesCityInputComponentProps> = ({
  city,
  setCity,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setCity(e.target.value);
  }, [setCity]);

  return (
    <Input
      value={city}
      onChange={handleChange}
      maxLength={SHIPPING_PARTY_MAX_LENGTHS.CITY}
      error={error && !city?.length}
    />
  );
};
