import message from 'antd/es/message';
import { components } from 'api-contracts/dev/task-service/v1/schema';
import { ENotificationCriticality, ENotificationDomain, ENotificationStatus } from 'app-wrapper/constants';
import {
  TaskObjectReferenceType, TaskTargetAudienceEnum, TaskTypeEnum, TaskAssigneeTypeEnum,
} from 'app-wrapper/enums';
import i18n from 'app-wrapper/i18n/i18n';
import { DateDtm, NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { InputMobxUseCase } from 'app-wrapper/usecases/InputMobx.useCase';
import moment from 'moment';
import { BaseController, controller } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { R as appWrapperR } from 'app-wrapper/repository';
import { R } from 'shipment-operations/repository';
import { ChangeRequestStore } from 'shipment-operations/repository/mobxStores';

@controller
export class ChangeRequestDrawerController extends BaseController<ChangeRequestStore> {
  onLoadedPage() {
    super.onLoadedPage();
    this.currentStore?.setLoading(true);

    this.currentStore?.setForm({
      text: new InputStore(''),
      files: [new InputStore('')],
    });

    this.currentStore?.setLoading(false);
  }

  text() {
    return new InputMobxUseCase(
      this,
      this.currentStore?.state.form.text as InputStore<string>,
    );
  }

  closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  async submitForm() {
    if (this.currentStore?.hasError) {
      return;
    }

    this.currentStore?.setSubmitting(true);

    let newTask: NotificationTaskContentDTM;

    const shortShipment = R.selectors.shipment.getShipment(this.store.getState());

    if (!shortShipment) {
      return;
    }

    try {
      const createdAt = DateDtm.fromPlain(moment());

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      newTask = await appWrapperR.services.CommandCenter.postTask(
        {
          type: TaskTypeEnum.CHANGE_REQUEST,
          title: i18n.t('Update Containers Details'),
          criticality: ENotificationCriticality.MEDIUM,
          status: ENotificationStatus.TODO,
          description: this.currentStore?.state.form.text?.value,
          domain: ENotificationDomain.BOOKING,
          createdAt: createdAt?.getBackendFormatWithOffset(),
          dueDate: createdAt?.addDays(1)?.getBackendFormatWithOffset(),

          assignee: {
            type: TaskAssigneeTypeEnum.ORGANIZATION,
            organization: {
              id: 2,
              name: 'Skypace LTD',
            },
          } as components['schemas']['OrganizationAssignee'],
          organizationId: 1,
          objectReference: {
            type: TaskObjectReferenceType.SHIPMENT,
            shipmentId: Number(this.shipmentId()),
          } as components['schemas']['ShipmentReference'],
          targetAudience: {
            type: TaskTargetAudienceEnum.ORGANIZATION,
            organizationId: 2,
          } as components['schemas']['OrganizationTargetAudience'],

          metadata: {
            shipmentId: this.shipmentId(),
            shipmentName: shortShipment.shipmentName,
            taskType: TaskTypeEnum.CHANGE_REQUEST,
          },
          // "payload": {
          //   "before": "1",
          //   "requested": "2"
          // },
          // "createdBy": "admin@freightune.com",
          // "assignee": {
          //   "type": "ORGANIZATION",
          //   "organization": {
          //     "id": 2,
          //     "name": "Skypace LTD"
          //   }
          // },
          // "organizationId": 1,
          // "objectReference": {
          //   "type": "ORGANIZATION",
          //   "organizationId": 44
          // },
          // "targetAudience": {
          //   "type": "ORGANIZATION",
          //   "organizationId": 2
          // },
        },
      );
    } finally {
      this.currentStore?.setSubmitting(false);
    }

    // newTask

    message.success(i18n.t('Saved successfully'));
    this.closeDrawer();
  }

  openFileUploaderDrawer() {
    new DrawersUseCase(this).openFileUploaderDrawer();
  }

  private shipmentId() {
    return (this.params as { shipmentId: string }).shipmentId;
  }
}
