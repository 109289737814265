import React, { memo } from 'react';

import { PageComponent } from 'app-wrapper/view/routes/components';
import {
  ChangeRequestDrawerContainer,
} from 'shipment-operations/view/drawers/ChangeRequestDrawer/ChangeRequestDrawer.container';
import { createChangeRequestStore } from 'shipment-operations/repository/mobxStores';
import { ChangeRequestDrawerController } from 'shipment-operations/controllers/ChangeRequestDrawerController';

interface IChangeRequestDrawerProps {
  type: string
}

export const ChangeRequestDrawerPage = memo(({ type }: IChangeRequestDrawerProps) => (
  <PageComponent
    ControllerClass={ChangeRequestDrawerController}
    StoreFactory={() => {
      const store = createChangeRequestStore();
      store.setType(type);
      return store;
    }}
  >
    <ChangeRequestDrawerContainer />
  </PageComponent>
));
