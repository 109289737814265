import React, { FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from 'antd/es/alert';
import notification from 'antd/es/notification';

import {
  TrackerHeader,
} from 'shipment-operations/view/components/ShipmentTrackerHeader';
import { TrackerHeaderDrayage } from 'shipment-operations/view/components';
import {
  Wrapper, GapVerticalContainerStyled, SubPageHeaderTitle, SubPageHeader,
} from 'app-wrapper/view/guideline';
import { useIsExportOrImport } from 'app-wrapper/hooks';
import { ContainersTable } from './components';

interface IShipmentTransportationTrackerComponentProps {
  loadData: (shipmentId?: string) => void
  onClosePage: () => void
  errorUpdate: boolean
  resetError: () => void
  withEdit: boolean
  isLoading: boolean
  showUntrackedWarning: boolean
  shouldUpdateData: boolean
  isCommon: boolean
}

export const ShipmentTransportationTrackerComponent: FC<IShipmentTransportationTrackerComponentProps> = ({
  loadData,
  isLoading,
  onClosePage,
  errorUpdate,
  resetError,
  withEdit,
  showUntrackedWarning,
  shouldUpdateData,
  isCommon,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();

  const { isExportOrImport } = useIsExportOrImport();

  useEffect(() => {
    loadData(shipmentId);
    return onClosePage;
  }, []);

  useEffect(() => {
    if (shouldUpdateData) {
      loadData(shipmentId);
    }
  }, [shouldUpdateData]);

  useEffect(() => {
    if (errorUpdate) {
      notification.error({
        message: t('basicErrors.REQUEST_COMMON.title'),
        description: t('basicErrors.REQUEST_COMMON.subTitle'),
        placement: 'bottomRight',
        bottom: 60,
        onClose: resetError,
      });
    }
  }, [errorUpdate, t]);

  const content = useCallback(() => (
    <GapVerticalContainerStyled>
      {isExportOrImport ? (
        <TrackerHeaderDrayage isPageLoading={isLoading} skeletonHeight="210px" />
      ) : (
        <TrackerHeader isPageLoading={isLoading} skeletonHeight="210px" withEdit={withEdit && !isCommon} />
      )}

      {showUntrackedWarning && !isLoading && isExportOrImport && (
        <Alert
          type="warning"
          showIcon
          message={t('Containers have been sent')}
        />
      )}

      <ContainersTable isCommon={isCommon} />
    </GapVerticalContainerStyled>
  ), [isLoading, showUntrackedWarning, isExportOrImport, withEdit, isCommon]);

  return isCommon ? (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderTitle>{t('Shipment Tracker')}</SubPageHeaderTitle>
      </SubPageHeader>
      {content()}
    </Wrapper>
  ) : content();
};
