import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { BookingDrayageDrawerComponent } from './BookingDrayageDrawer.component';

const BookingDrayageDrawerContainer = () => {
  const shipmentId = useSelector(R.selectors.bookingWizard.getShipmentId);
  const isLoading = useSelector(R.selectors.bookingWizard.getIsLoading);
  const currentStep = useSelector(R.selectors.bookingWizard.getCurrentStep);

  return (
    <BookingDrayageDrawerComponent
      isLoading={isLoading}
      onClose={UC.bookingWizard.closeWizard}
      shipmentId={shipmentId}
      init={UC.bookingWizard.initDrayage}
      currentStep={currentStep}
    />
  );
};

export { BookingDrayageDrawerContainer as BookingDrayageDrawer };
