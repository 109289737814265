import { CanceledError } from 'axios';
import without from 'lodash/without';
import { BaseController, controller } from 'proto/BaseController';
import debounce from 'lodash/debounce';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import querystring from 'querystring-es3';
import { R } from 'shipment-operations/repository';
import {
  IShipmentListFiltersCheckboxCompaniesDTM,
  ShipmentAllStatsDTM, ShipmentListFiltersDTM, ShipmentListFiltersRadioSortByDTM, ShipmentPreviewDTM,
} from 'shipment-operations/models/dtm';
import { RouteNames } from 'app-wrapper/constants';
import { EPageSortGeneral } from 'app-wrapper/models/contracts';
import {
  AppFiltersCheckboxGroupDTM, CommandCenterGetTasksDTM, IAppFiltersCheckboxDTM, IAppFiltersCheckboxGroupDTM,
} from 'app-wrapper/models/dtm';
import i18n from 'app-wrapper/i18n/i18n';
import { ShipmentStatusEnum } from 'shipment-operations/constants';

const findFilter = (path: string) => {
  const lastIndex = path.lastIndexOf('/');
  return path.substring(lastIndex + 1);
};

type FilterObject = {
  checked: boolean
  value: string
}

type ObjectValue<T extends { value: string }> = T['value'];

const setCheck = <T extends FilterObject, Key extends keyof T>(values: string[], array: T[], key: Key = 'value' as Key): { group: T[], isUpdate: boolean, disableReset: boolean } => {
  let isUpdate = false;

  return {
    group: array.map((item) => {
      const newObj = { ...item };
      if (values.some((value) => value === (item[key] as ObjectValue<T>))) {
        newObj.checked = true;
        isUpdate = true;
      }

      return newObj;
    }),
    isUpdate,
    disableReset: !isUpdate,
  };
};

const replaceState = (params: Record<string, string[] | undefined>): void => {
  const queryParams: Record<string, string[] | undefined> = {};
  Object.keys(params).forEach((key) => {
    if (params[key] && params[key]?.length) {
      queryParams[key] = params[key];
    }
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}${Object.keys(queryParams).length > 0 ? '?' : ''}${querystring.stringify(queryParams)}`,
  );
};

@controller
export class ShipmentListController extends BaseController {
  uploadList = async () => {
    let list: ShipmentPreviewDTM[] | undefined;

    this.dispatch(R.actions.shipmentList.setLoading(true));

    try {
      const { shipmentList } = await R.services.shipment.getList();
      list = shipmentList;
    } catch (e) {
      console.error(e);

      this.dispatch(R.actions.shipmentList.setLoading(false));

      return;
    }

    if (!list) {
      return;
    }

    this.dispatch(R.actions.shipmentList.setList(list));
  }

  private generateGroupFilter = (page: string) => {
    if (page === 'active') {
      return [
        {
          checked: false,
          name: 'pending confirmation',
          value: ShipmentStatusEnum.PENDING_CONFIRMATION,
        },
        {
          checked: false,
          name: 'confirmed',
          value: ShipmentStatusEnum.CONFIRMED,
        },
        {
          checked: false,
          name: 'delivered',
          value: ShipmentStatusEnum.DELIVERED,
        },
        {
          checked: false,
          name: 'in transit',
          value: ShipmentStatusEnum.IN_TRANSIT,
        },
      ];
    }
    if (page === 'completed') {
      return [
        {
          checked: true,
          name: 'completed',
          value: ShipmentStatusEnum.COMPLETED,
        },
      ];
    }
    if (page === 'cancelled') {
      return [
        {
          checked: true,
          name: 'cancelled',
          value: ShipmentStatusEnum.CANCELLED,
        },
      ];
    }
    if (page === 'waiting-approval') {
      return [
        {
          checked: true,
          name: 'waiting approval',
          value: ShipmentStatusEnum.AWAITING_APPROVAL,
        },
      ];
    }
    return [];
  }

  onStartLoadShipmentListPage = async () => {
    await this.clearList();

    let filters: ShipmentListFiltersDTM | null = null;

    const page = findFilter(this.location.pathname);
    const statusGroup = this.generateGroupFilter(page);
    this.dispatch(R.actions.shipmentList.setLoading(true));

    try {
      filters = await R.services.shipment.getFilters(page);
    } catch (e) {
      console.error('onStartLoadShipmentListPage: error', e);
    }

    const queryParams = new URLSearchParams(this.location.search);
    const { email } = this.store.getState().auth;
    const assigneeUserEmails = email ? [email] : undefined;

    if (filters) {
      if (filters.filterLocation) {
        this.dispatch(R.actions.shipmentList.setFilterLocationChange({
          ...filters.filterLocation,
          values: {
            ...filters.filterLocation.values,
            ...setCheck(queryParams.getAll('location'), filters.filterLocation?.values?.group || [], 'name'),
            lastGroup: [...filters.filterLocation?.values?.group || []],
          },
        }));
      }

      if (filters.filterShippingParty) {
        this.dispatch(R.actions.shipmentList.setFilterShippingPartyChange({
          ...filters.filterShippingParty,
          values: {
            ...filters.filterShippingParty.values,
            ...setCheck(queryParams.getAll('shippingParty'), filters.filterShippingParty?.values?.group || []),
            lastGroup: [...filters.filterShippingParty?.values?.group || []],
          },
        }));
      }

      if (filters.filterShippingCarrier) {
        this.dispatch(R.actions.shipmentList.setFilterShippingCarrierChange({
          ...filters.filterShippingCarrier,
          values: {
            ...filters.filterShippingCarrier.values,
            ...setCheck(queryParams.getAll('shippingCarrier'), filters.filterShippingCarrier?.values?.group || []),
            lastGroup: [...filters.filterShippingCarrier?.values?.group || []],
          },
        }));
      }

      if (filters.filterContainerType) {
        this.dispatch(R.actions.shipmentList.setFilterContainerTypeChange({
          ...filters.filterContainerType,
          values: {
            ...filters.filterContainerType.values,
            ...setCheck(queryParams.getAll('containerType'), filters.filterContainerType?.values?.group || []),
            lastGroup: [...filters.filterContainerType?.values?.group || []],
          },
        }));
      }

      if (filters.filterStatus) {
        this.dispatch(R.actions.shipmentList.setFilterStatusChange({
          ...filters.filterStatus,
          values: {
            ...filters.filterStatus.values,
            lastGroup: [...filters.filterStatus?.values?.group || []],
            ...setCheck(queryParams.getAll('status'), statusGroup),
            groupDefault: statusGroup,
          },
        }));
      }

      if (filters.filterSortBy) {
        const sortByGroup = [
          AppFiltersCheckboxGroupDTM.fromPlain({
            name: i18n.t('Created At'),
            value: EPageSortGeneral.CREATED_AT,
            checked: !(queryParams.getAll('sortBy').length > 0 && !queryParams.getAll('sortBy').includes(EPageSortGeneral.CREATED_AT)),
          }),
          AppFiltersCheckboxGroupDTM.fromPlain({
            name: EPageSortGeneral.ARRIVAL.toLowerCase(),
            value: EPageSortGeneral.ARRIVAL,
            checked: queryParams.getAll('sortBy').includes(EPageSortGeneral.ARRIVAL) || false,
          }),
          AppFiltersCheckboxGroupDTM.fromPlain({
            name: EPageSortGeneral.DEPARTURE.toLowerCase(),
            value: EPageSortGeneral.DEPARTURE,
            checked: queryParams.getAll('sortBy').includes(EPageSortGeneral.DEPARTURE) || false,
          }),
        ];

        const filterSortBY = sortByGroup.find((f) => f.checked) || sortByGroup[0];
        this.dispatch(R.actions.shipmentList.setFilterSortByChange(ShipmentListFiltersRadioSortByDTM.fromPlain({
          values: {
            ...filters.filterSortBy?.values,
            name: filterSortBY?.name,
            value: filterSortBY?.value,
            valueLast: filterSortBY?.value,
            valueDefault: sortByGroup[0].value,
            ...setCheck(queryParams.getAll('sortBy'), sortByGroup || []),
            groupDefault: [...sortByGroup],
          },
          response: {
            ...filters.filterSortBy?.response,
          },
        })));
      }
    }
    this.dispatch(R.actions.shipmentList.setLoading(false));

    await this.loadNextShipmentListPage();

    let allStats: ShipmentAllStatsDTM[] | undefined;

    try {
      allStats = await R.services.shipment.getShipmentAllStats(CommandCenterGetTasksDTM.fromPlain({
        assigneeUserEmails,
      }));
    } catch (e) {
      console.error('onStartLoadShipmentListPage: error', e);
    }

    if (allStats) {
      await this.dispatch(R.actions.shipmentList.setShipmentAllStats([
        ...allStats,
      ]));

      await this.setShipmentAllStats();
    }
  }

  onChangeFilterLocation = async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    this.dispatch(R.actions.shipmentList.setFilterLocationGroupById({
      indexId: id,
      value: AppFiltersCheckboxGroupDTM.fromPlain(value),
    }));

    this.compareFilterLocation();
  }

  onResetFilterLocation = async () => {
    this.dispatch(R.actions.shipmentList.setFilterLocationGroupByDefault());

    const { filters } = this.store.getState().shipmentList;

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterLocation?.values);

    if (filters.filterLocation?.values?.isUpdate
      || isSomeFilter) {
      await this.onApplyFilterLocation();
    }

    this.compareFilterLocation();
  }

  onApplyFilterLocation = async () => {
    const { isLoading, isLoadingFilters, filters } = this.store.getState().shipmentList;
    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterLocation?.values);
    const isRequest = isSomeFilter
      && !isLoadingFilters
      && !isLoading;

    if (isRequest) {
      await this.dispatch(R.actions.shipmentList.setPage(0));
      await this.dispatch(R.actions.shipmentList.setList([]));
      await this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

      this.dispatch(R.actions.shipmentList.setFilterLocationLastGroup(filters?.filterLocation?.values?.group?.map((item) => AppFiltersCheckboxGroupDTM.fromPlain(item)) || []));

      await this.loadNextShipmentListPage();
    }

    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
  }

  onChangeFilterShippingParty = async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    this.dispatch(R.actions.shipmentList.setFilterShippingPartyGroupById({
      indexId: id,
      value: AppFiltersCheckboxGroupDTM.fromPlain(value),
    }));

    this.compareFilterShippingParty();
  }

  onResetFilterShippingParty = async () => {
    this.dispatch(R.actions.shipmentList.setFilterShippingPartyGroupByDefault());

    const { filters } = this.store.getState().shipmentList;

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterShippingParty?.values);

    if (filters.filterShippingParty?.values?.isUpdate
      || isSomeFilter) {
      await this.onApplyFilterShippingParty();
    }

    this.compareFilterShippingParty();
  }

  onApplyFilterShippingParty = async () => {
    const { isLoading, isLoadingFilters, filters } = this.store.getState().shipmentList;
    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterShippingParty?.values);
    const isRequest = isSomeFilter
      && !isLoadingFilters
      && !isLoading;

    if (isRequest) {
      await this.dispatch(R.actions.shipmentList.setPage(0));
      await this.dispatch(R.actions.shipmentList.setList([]));
      await this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

      this.dispatch(R.actions.shipmentList.setFilterShippingPartyLastGroup(filters?.filterShippingParty?.values?.group?.map((item) => AppFiltersCheckboxGroupDTM.fromPlain(item)) || []));

      await this.loadNextShipmentListPage();
    }

    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
  }

  onChangeFilterShippingCarrier = async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    this.dispatch(R.actions.shipmentList.setFilterShippingCarrierGroupById({
      indexId: id,
      value: AppFiltersCheckboxGroupDTM.fromPlain(value),
    }));

    this.compareFilterShippingCarrier();
  }

  onResetFilterShippingCarrier = async () => {
    this.dispatch(R.actions.shipmentList.setFilterShippingCarrierGroupByDefault());

    const { filters } = this.store.getState().shipmentList;

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterShippingCarrier?.values);

    if (filters.filterShippingCarrier?.values?.isUpdate
      || isSomeFilter) {
      await this.onApplyFilterShippingCarrier();
    }

    this.compareFilterShippingCarrier();
  }

  onApplyFilterShippingCarrier = async () => {
    const { isLoading, isLoadingFilters, filters } = this.store.getState().shipmentList;
    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterShippingCarrier?.values);
    const isRequest = isSomeFilter
      && !isLoadingFilters
      && !isLoading;

    if (isRequest) {
      await this.dispatch(R.actions.shipmentList.setPage(0));
      await this.dispatch(R.actions.shipmentList.setList([]));
      await this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

      this.dispatch(R.actions.shipmentList.setFilterShippingCarrierLastGroup(filters?.filterShippingCarrier?.values?.group?.map((item) => AppFiltersCheckboxGroupDTM.fromPlain(item)) || []));

      await this.loadNextShipmentListPage();
    }

    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
  }

  onChangeFilterContainerType = async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    this.dispatch(R.actions.shipmentList.setFilterContainerTypeGroupById({
      indexId: id,
      value: AppFiltersCheckboxGroupDTM.fromPlain(value),
    }));

    this.compareFilterContainerType();
  }

  onResetFilterContainerType = async () => {
    this.dispatch(R.actions.shipmentList.setFilterContainerTypeGroupByDefault());

    const { filters } = this.store.getState().shipmentList;

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterContainerType?.values);

    if (filters.filterContainerType?.values?.isUpdate
      || (isSomeFilter && filters?.filterContainerType?.values?.group?.some((item) => !item.checked))) {
      await this.onApplyFilterContainerType();
    }

    this.compareFilterContainerType();
  }

  onApplyFilterContainerType = async () => {
    const { isLoading, isLoadingFilters, filters } = this.store.getState().shipmentList;
    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterContainerType?.values);
    const isRequest = isSomeFilter
      && !isLoadingFilters
      && !isLoading;

    if (isRequest) {
      await this.dispatch(R.actions.shipmentList.setPage(0));
      await this.dispatch(R.actions.shipmentList.setList([]));
      await this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

      this.dispatch(R.actions.shipmentList.setFilterContainerTypeLastGroup(filters?.filterContainerType?.values?.group?.map((item) => AppFiltersCheckboxGroupDTM.fromPlain(item)) || []));

      await this.loadNextShipmentListPage();
    }

    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
  }

  onChangeFilterStatus = async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    this.dispatch(R.actions.shipmentList.setFilterStatusGroupById({
      indexId: id,
      value: AppFiltersCheckboxGroupDTM.fromPlain(value),
    }));

    this.compareFilterStatus();
  }

  onResetFilterStatus = async () => {
    const { filters } = this.store.getState().shipmentList;

    this.dispatch(R.actions.shipmentList.setFilterStatusGroupByDefault());

    if (filters.filterStatus?.values?.isUpdate) {
      await this.onApplyFilterStatus();
    }

    this.compareFilterStatus();
  }

  onApplyFilterStatus = async () => {
    const { isLoading, isLoadingFilters, filters } = this.store.getState().shipmentList;
    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));

    const isSomeFilter = this.checkFilterGroupIsSome(filters?.filterStatus?.values);
    const isRequest = isSomeFilter
      && !isLoadingFilters
      && !isLoading;

    if (isRequest) {
      await this.dispatch(R.actions.shipmentList.setPage(0));
      await this.dispatch(R.actions.shipmentList.setList([]));
      await this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

      this.dispatch(R.actions.shipmentList.setFilterStatusLastGroup(filters?.filterStatus?.values?.group?.map((item) => AppFiltersCheckboxGroupDTM.fromPlain(item)) || []));

      await this.loadNextShipmentListPage();
    }

    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
  }

  onChangeFilterSortBy = async (value: IAppFiltersCheckboxGroupDTM) => {
    this.dispatch(R.actions.shipmentList.setFilterSortByValue(value.value));

    await this.onApplyFilterSortBy();

    this.compareFilterSortBy();
  }

  onSearch = async (value: string) => {
    this.dispatch(R.actions.shipmentList.setPage(0));
    this.dispatch(R.actions.shipmentList.setList([]));
    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));
    this.dispatch(R.actions.shipmentList.setSearchValue(value));
    apiWorker.abortAllRequests();

    await this.fetchBySearch();
  };

  fetchBySearch = debounce(async () => {
    this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

    await this.loadNextShipmentListPage();
  }, 500);

  onApplyFilterSortBy = async () => {
    const { isLoading, isLoadingFilters, filters } = this.store.getState().shipmentList;
    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));

    const isSomeFilter = filters?.filterSortBy?.values?.value !== filters?.filterSortBy?.values?.valueLast;
    const isRequest = isSomeFilter
      && !isLoadingFilters
      && !isLoading;

    if (isRequest) {
      await this.dispatch(R.actions.shipmentList.setPage(0));
      await this.dispatch(R.actions.shipmentList.setList([]));
      await this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

      this.dispatch(R.actions.shipmentList.setFilterSortByValueLast(filters?.filterSortBy?.values?.value || ''));

      await this.loadNextShipmentListPage();
    }

    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
  }

  private clearFilter(key: string) {
    // @ts-ignore
    this.dispatch(R.actions.shipmentList[`setFilter${key}GroupByDefault`]());
    // @ts-ignore
    this.dispatch(R.actions.shipmentList[`setFilter${key}LastGroup`]());
    // @ts-ignore
    this.dispatch(R.actions.shipmentList[`setFilter${key}DisableReset`](true));
    // @ts-ignore
    this.dispatch(R.actions.shipmentList[`setFilter${key}IsUpdate`](false));
  }

  async clearAllFilters() {
    ['Location', 'Status', 'ContainerType', 'ShippingParty', 'ShippingCarrier'].forEach((key) => this.clearFilter(key));

    this.dispatch(R.actions.shipmentList.setLoadingFilters(true));

    this.dispatch(R.actions.shipmentList.setPage(0));
    this.dispatch(R.actions.shipmentList.setList([]));
    this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(false));

    await this.loadNextShipmentListPage();

    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));

    replaceState({});
  }

  loadNextShipmentListPage = async () => {
    let list: ShipmentPreviewDTM[] | undefined;
    let endList: boolean = false;

    const activePage = findFilter(this.location.pathname);

    const isLoading = R.selectors.shipmentList.getLoadingState(this.store.getState());
    if (isLoading) {
      return;
    }
    const { page, filters, searchValue } = this.store.getState().shipmentList;

    const filterLocation = filters.filterLocation?.values?.group?.filter((item) => item.checked)?.map((item) => item.name);
    const filterShippingParty = filters.filterShippingParty?.values?.group?.filter((item) => item.checked)?.map((item) => item.name);
    const filterShippingCarrier = filters.filterShippingCarrier?.values?.group?.filter((item) => item.checked)?.map((item) => item.name);
    const filterContainerType = filters.filterContainerType?.values?.group?.filter((item) => item.checked)?.map((item) => item.value);
    const filterStatus = filters.filterStatus?.values?.group?.filter((item) => item.checked)?.map((item) => item.value);
    let preparedFilters = filters.filterStatus?.response?.filter((item) => filterStatus?.includes(item));
    if (activePage === 'active' && !preparedFilters?.length) {
      preparedFilters = [ShipmentStatusEnum.AWAITING_APPROVAL, ShipmentStatusEnum.PENDING_CONFIRMATION, ShipmentStatusEnum.CONFIRMED, ShipmentStatusEnum.IN_TRANSIT, ShipmentStatusEnum.DELIVERED];
    }

    replaceState({
      location: filterLocation,
      shippingParty: filterShippingParty,
      shippingCarrier: filterShippingCarrier,
      containerType: filterContainerType,
      status: without(filterStatus, ShipmentStatusEnum.COMPLETED, ShipmentStatusEnum.CANCELLED),
      // @ts-ignore all filters are not dtm
      sortBy: filters.filterSortBy?.urlParams(),
    });

    this.dispatch(R.actions.shipmentList.setLoading(true));

    const getCompaniesCheck = (listShipping?: IShipmentListFiltersCheckboxCompaniesDTM[], checked?: string[]) => listShipping?.filter((item) => checked?.includes(this.getLocation(item.name || '')))?.map((item) => ({
      id: item.id || undefined,
      name: item.name || undefined,
      phone: item.phone || undefined,
      phone2: item.phone2 || undefined,
      email: item.email || undefined,
      taxId: item.taxId || undefined,
      type: item.type || undefined,
      companyType: item.companyType || undefined,
      organizationId: item.organizationId || undefined,
      isPrimary: item.isPrimary || undefined,
      contacts: item.contacts?.map((itemContract) => ({
        id: itemContract.id || undefined,
        fullName: itemContract.fullName || undefined,
        email: itemContract.email || undefined,
        phone: itemContract.phone || undefined,
        phone2: itemContract.phone2 || undefined,
      })),
      addresses: item.addresses?.map((itemAddress) => ({
        id: itemAddress.id || undefined,
        country: itemAddress.country || undefined,
        state: itemAddress.state || undefined,
        city: itemAddress.city || undefined,
        address1: itemAddress.address1 || undefined,
        address2: itemAddress.address2 || undefined,
        postalCode: itemAddress.postalCode || undefined,
        closestPort: itemAddress.closestPort || undefined,
      })),
    }));

    const companiesCheck = [
      ...getCompaniesCheck(filters.filterShippingParty?.response, filterShippingParty) || [],
      ...getCompaniesCheck(filters.filterShippingCarrier?.response, filterShippingCarrier) || [],
    ];

    this.dispatch(R.actions.shipmentList.incrementPage());

    try {
      const postShipmentSearch = async (pageReq: number) => {
        let res = await R.services.shipment.postShipmentSearch({
          locations: filters.filterLocation?.response?.filter((item) => filterLocation?.includes(this.getLocation((item.name || item?.city) || '', item.country?.code)))?.map((item) => ({
            id: item.id || undefined,
            country: item.country ? {
              code: item.country.code,
              name: item.country.name,
            } : undefined,
            state: item.state ? {
              code: item.state.code,
              name: item.state.name,
            } : undefined,
            coordinates: item.coordinates ? {
              lat: item.coordinates.lat,
              lng: item.coordinates.lng,
            } : undefined,
            timezoneId: item.timezoneId || undefined,
            code: item.code || undefined,
            name: item.name || undefined,
            type: item.type || '',
            placeId: item.placeId,
          })) || undefined,
          companies: companiesCheck,
          containerTypes: filters.filterContainerType?.response?.filter((item) => filterContainerType?.includes(item)),
          statuses: preparedFilters,
          pageRequest: {
            page: pageReq,
            sort: filters.filterSortBy?.values.value || EPageSortGeneral.CREATED_AT,
          },
          query: searchValue,
          requiresMyBookingApproval: activePage === 'waiting-approval',
        });

        if (!res.isEnd && !res.shipmentList?.length) {
          await this.dispatch(R.actions.shipmentList.incrementPage());
          const { page: pageNext } = this.store.getState().shipmentList;

          res = await postShipmentSearch(pageNext);
        }
        return res;
      };

      const { shipmentList, isEnd } = await postShipmentSearch(page);

      list = shipmentList;
      endList = isEnd;
    } catch (e) {
      const error = e as Error;

      if (error instanceof CanceledError) {
        return;
      }

      this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
      this.dispatch(R.actions.shipmentList.setLoading(false));
      return;
    }

    if (endList) {
      this.dispatch(R.actions.shipmentList.setWasLastRequestEmpty(true));
      this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
      this.dispatch(R.actions.shipmentList.setLoading(false));
      return;
    }

    if (!list || !list.length) return;

    const currentShipmentList = R.selectors.shipmentList.getList(this.store.getState());

    this.dispatch(R.actions.shipmentList.setList([
      ...currentShipmentList,
      ...list,
    ]));
    this.dispatch(R.actions.shipmentList.setLoadingFilters(false));
    this.dispatch(R.actions.shipmentList.setLoading(false));

    await this.setShipmentAllStats();
  }

  setShipmentAllStats = () => {
    const { shipmentAllStats } = this.store.getState().shipmentList;

    if (shipmentAllStats) {
      const { list } = this.store.getState().shipmentList;
      const newList = list.map((item) => {
        const findStat = shipmentAllStats?.find((itemStat) => itemStat?.objectReference?.shipmentId === item.id);

        if (findStat) {
          return ShipmentPreviewDTM.fromPlain({
            ...item,
            shipmentStats: ShipmentAllStatsDTM.fromPlain({ ...findStat }),
            shipmentAllTasks: `${findStat.getShipmentAllTasks()}`,
            shipmentAllAlerts: `${findStat.getShipmentAllAlerts()}`,
          });
        }

        return item;
      });

      this.dispatch(R.actions.shipmentList.setList([
        ...newList,
      ]));
    }
  }

  clearList = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.shipmentList.clear());
  }

  onClickGetNewQuote = () => {
    this.navigate(RouteNames.FREIGHT_QUOTE());
  }

  public onClickToCustomer = (id?: number) => () => {
    if (!id) return;

    this.dispatch(R.actions.shippingParties.setIsForceCustomerBlock(true));

    this.navigate(RouteNames.SHIPMENT_SHIPPING_PARTIES(id));
  }

  private compareFilterLocation = () => {
    const { filters } = this.store.getState().shipmentList;

    const getChecked = this.checkFilterGroup(filters?.filterLocation?.values);

    this.dispatch(R.actions.shipmentList.setFilterLocationDisableReset(getChecked.isDisabled));
    this.dispatch(R.actions.shipmentList.setFilterLocationIsUpdate(getChecked.isUpdate));

    return getChecked.isDisabled;
  }

  private compareFilterShippingParty = () => {
    const { filters } = this.store.getState().shipmentList;

    const getChecked = this.checkFilterGroup(filters?.filterShippingParty?.values);

    this.dispatch(R.actions.shipmentList.setFilterShippingPartyDisableReset(getChecked.isDisabled));
    this.dispatch(R.actions.shipmentList.setFilterShippingPartyIsUpdate(getChecked.isUpdate));

    return getChecked.isDisabled;
  }

  private compareFilterShippingCarrier = () => {
    const { filters } = this.store.getState().shipmentList;

    const getChecked = this.checkFilterGroup(filters?.filterShippingCarrier?.values);

    this.dispatch(R.actions.shipmentList.setFilterShippingCarrierDisableReset(getChecked.isDisabled));
    this.dispatch(R.actions.shipmentList.setFilterShippingCarrierIsUpdate(getChecked.isUpdate));

    return getChecked.isDisabled;
  }

  private compareFilterContainerType = () => {
    const { filters } = this.store.getState().shipmentList;

    const getChecked = this.checkFilterGroup(filters?.filterContainerType?.values);

    this.dispatch(R.actions.shipmentList.setFilterContainerTypeDisableReset(getChecked.isDisabled));
    this.dispatch(R.actions.shipmentList.setFilterContainerTypeIsUpdate(getChecked.isUpdate));

    return getChecked.isDisabled;
  }

  private compareFilterStatus = () => {
    const { filters } = this.store.getState().shipmentList;

    const getChecked = this.checkFilterGroup(filters.filterStatus?.values);

    this.dispatch(R.actions.shipmentList.setFilterStatusDisableReset(getChecked.isDisabled));
    this.dispatch(R.actions.shipmentList.setFilterStatusIsUpdate(getChecked.isUpdate));

    return getChecked.isDisabled;
  }

  private checkFilterGroup = (group?: IAppFiltersCheckboxDTM) => {
    let isDisabled = true;
    let isUpdate = false;

    group?.group?.forEach((item, index) => {
      if (item.checked) {
        isDisabled = false;
      }
      if (group?.groupDefault?.[index].name !== item.name
        || group?.groupDefault[index].value !== item.value
        || group?.groupDefault[index].checked !== item.checked) {
        isUpdate = true;
      }
    });

    return { isUpdate, isDisabled };
  }

  private checkFilterGroupIsSome = (group?: IAppFiltersCheckboxDTM) => {
    const isSome = group?.group?.some((item, index) => !group?.lastGroup?.[index] || group?.lastGroup?.[index]?.value !== item.value || group?.lastGroup?.[index]?.checked !== item.checked);

    return isSome && (group?.isUpdate
      || (group?.group?.some((item) => !item.checked)));
  }

  private compareFilterSortBy = () => {
    const { filters } = this.store.getState().shipmentList;
    let isDisabled = true;
    let isUpdate = false;

    if (filters?.filterSortBy?.values?.value !== filters?.filterSortBy?.values?.valueDefault) {
      isUpdate = true;
    }
    filters?.filterSortBy?.values?.group?.forEach((item) => {
      if (item.checked) {
        isDisabled = false;
      }
    });

    this.dispatch(R.actions.shipmentList.setFilterSortByIsUpdate(isUpdate));

    return isDisabled;
  }

  private getLocation = (city?: string, country?: string) => `${city || ''}${(city && country) ? `, ${country}` : (country || '')}`
}
