import message from 'antd/es/message';
import { Moment } from 'moment/moment';
import { shipmentContainerNumberValidate } from 'shipment-operations/controllers/ShipmentContainersController/ShipmentContainers.validate';
import i18n from 'app-wrapper/i18n/i18n';
import { DateDtm } from 'app-wrapper/models/dtm';
import { DatePickerMobxUseCase } from 'app-wrapper/usecases/DatePickerMobx.useCase';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { InputMobxUseCase } from 'app-wrapper/usecases/InputMobx.useCase';
import { SelectMobxUseCase } from 'app-wrapper/usecases/SelectMobx.useCase';
import { instanceToPlain } from 'app-wrapper/utils';
import { BaseController, controller } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { DepartureDetailsStatusEnum } from 'shipment-operations/constants';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import { ContainerDepartureDetailsDTM, ContainerDTM, IContainerInputDTM } from 'shipment-operations/models/dtm';
import { ShipmentEditContainerStore } from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';
import { R } from 'shipment-operations/repository';

@controller
export class ShipmentEditContainerController extends BaseController<ShipmentEditContainerStore> {
  onLoadedPage() {
    super.onLoadedPage();
    this.currentStore?.setLoading(true);

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    const container = this.mobxStore.shipmentContainersStore.items.find((_container) => _container.id === String(this.currentStore?.containerId));
    this.currentStore?.setContainer(container);
    this.currentStore?.setLoadingControl(this.loadingControl());

    const departureDetail = container?.departureDetailByType(this.currentStore?.state.drayageSide || EDrayageSide.DESTITATION_DRAYAGE);

    this.currentStore?.setForm({
      number: new InputStore(container?.number || ''),
      // carrier: new InputStore('container.number'),
      arrivalDate: new InputStore(departureDetail?.arrivalDate ? departureDetail.arrivalDate : undefined),
      departureDate: new InputStore(departureDetail?.departureDate ? departureDetail.departureDate : undefined), // will be implemented later (this.calculateDepartureDate(departureDetail)),
      reference: new InputStore(departureDetail?.reference),
      dispatchOrder: new InputStore(departureDetail?.dispatchOrder),
      status: new InputStore(departureDetail?.status || DepartureDetailsStatusEnum.DISPATCHED),
    });

    const waypoint = this.currentStore?.state.drayageSide === EDrayageSide.ORIGIN_DRAYAGE ? shipment?.origin : shipment?.destination;
    const utcOffset = waypoint?.utcOffset() || 0;

    this.currentStore?.state?.form?.arrivalDate?.setUtcOffset(utcOffset);
    this.currentStore?.state?.form?.departureDate?.setUtcOffset(utcOffset);

    this.currentStore?.setLoading(false);
  }

  number() {
    return new InputMobxUseCase(
      this,
      this.currentStore?.state.form.number as InputStore<string>,
      this.numberValidation,
    );
  }

  numberValidation(useCase: InputMobxUseCase<InputStore<string>>) {
    const error = shipmentContainerNumberValidate(useCase.storeMobx.state.value);
    if (error) {
      useCase.setError(error.message);
      return;
    }

    useCase.setError(null);
  }

  arrivalDate() {
    return new DatePickerMobxUseCase(this, this.currentStore?.state.form.arrivalDate as InputStore<DateDtm>,
      this.emptyArrivalDateValidation);
  }

  onChangeArrivalDate(value: Moment | null) {
    this.arrivalDate().onChange(value);
    const loadingTime = this.currentStore?.state.loadingControl?.liveLoadingTime();
    if (loadingTime && value) {
      this.departureDate().onChange(value?.add(loadingTime, 'hours'));
    }
  }

  emptyArrivalDateValidation(useCase: DatePickerMobxUseCase<InputStore<DateDtm>>) {
    if (this.currentStore?.state?.form?.arrivalDate?.value
      && this.currentStore?.state?.form?.departureDate?.value
      && DateDtm.getDifBetweenDates(this.currentStore?.state?.form?.arrivalDate?.value, this.currentStore?.state?.form?.departureDate?.value).asMinutes() < 0) {
      useCase.setError(i18n.t('Empty arrival date must not be later then Full departure date'));
      return;
    }

    this.currentStore?.state.form.arrivalDate?.setError(null);
    this.currentStore?.state.form.departureDate?.setError(null);
  }

  departureDate() {
    return new DatePickerMobxUseCase(this, this.currentStore?.state.form.departureDate as InputStore<DateDtm>,
      this.emptyArrivalDateValidation);
  }

  reference() {
    return new InputMobxUseCase(this, this.currentStore?.state.form.reference as InputStore<string>);
  }

  dispatchOrder() {
    return new InputMobxUseCase(this, this.currentStore?.state.form.dispatchOrder as InputStore<string>);
  }

  status() {
    return new SelectMobxUseCase(this, this.currentStore?.state.form.status as InputStore<DepartureDetailsStatusEnum>);
  }

  closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  async updateContainer() {
    if (this.currentStore?.hasError) {
      return;
    }

    const container = ContainerDTM.fromPlain(instanceToPlain(this.container()) as IContainerInputDTM);

    let departureDetail = container?.departureDetails?.find((detail) => detail.drayageSide === this.currentStore?.state.drayageSide);
    if (!departureDetail) {
      departureDetail = ContainerDepartureDetailsDTM.fromPlain({
        id: undefined,
        drayageSide: this.currentStore?.state.drayageSide,
        arrivalDate: undefined,
        departureDate: undefined,
        status: undefined,
      });
      container?.departureDetails?.push(departureDetail);
    }

    container.number = this.currentStore?.state.form?.number?.value;
    departureDetail.arrivalDate = this.currentStore?.state.form?.arrivalDate?.value;
    departureDetail.departureDate = this.currentStore?.state.form?.departureDate?.value;
    departureDetail.status = this.currentStore?.state.form?.status?.value as DepartureDetailsStatusEnum;
    departureDetail.dispatchOrder = this.currentStore?.state.form?.dispatchOrder.value;
    departureDetail.reference = this.currentStore?.state.form?.reference.value;

    this.currentStore?.setSubmitting(true);

    let newContainer: ContainerDTM;

    try {
      newContainer = await R.services.shipmentContainers.putContainer(
        this.shipmentId(),
        container,
      );
    } finally {
      this.currentStore?.setSubmitting(false);
    }

    this.mobxStore.shipmentContainersStore.updateItem(newContainer);

    message.success(i18n.t('Saved successfully'));
    this.closeDrawer();
  }

  private shipmentId() {
    return (this.params as { shipmentId: string }).shipmentId;
  }

  private container() {
    return this.mobxStore.shipmentContainersStore.items.find((_container) => _container.id === String(this.currentStore?.containerId));
  }

  // will be implemented later
  // private calculateDepartureDate(departureDetail?: ContainerDepartureDetailsDTM) {
  //   console.log(1);
  //   const loadingControl = this.currentStore?.state?.loadingControl;
  //   if (!loadingControl || !loadingControl.loadingTime || !loadingControl.hasLiveLoadingTime()) {
  //     return undefined;
  //   }
  //
  //   if (loadingControl.hasLiveLoadingTime() && (!departureDetail?.arrivalDate || departureDetail?.departureDate)) {
  //     return undefined;
  //   }
  //
  //   return loadingControl.hasLiveLoadingTime()
  //     ? departureDetail?.departureDate?.addHours(loadingControl.loadingTime)
  //     : departureDetail?.departureDate;
  // }

  private loadingControl() {
    return this.currentStore?.state.drayageSide === EDrayageSide.ORIGIN_DRAYAGE
      ? this.mobxStore.shipmentLoadingControlsStore.getExportLoadingControl
      : this.mobxStore.shipmentLoadingControlsStore.getImportLoadingControl;
  }
}
