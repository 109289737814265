import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentGap, ContentSection,
  ContentSectionTitle,
  FooterContainer,
  GapVerticalContainerStyled, SubPage,
  SubPageContent,
  Wrapper,
  ContentSectionContainer,
  InputTitle,
  Title, DocumentsSection,
} from 'app-wrapper/view/guideline';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';
import { CustomModal } from 'shipment-operations/view/components';
import {
  Button, HintBox,
} from 'app-wrapper/view/components';
import { TextContainer } from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/Text';

interface IChargesCostChangesComponentProps {
  isLoading: boolean
  onClose: () => void
  onSave: () => void
  isSubmitting: boolean
  openFileUploaderDrawer: () => void
}

const ChangeRequestDrawerComponent: FC<IChargesCostChangesComponentProps> = ({
  onClose, isLoading, onSave, isSubmitting, openFileUploaderDrawer,
}) => {
  const { t } = useTranslation();

  const handleCloseDrawer = useCallback(() => {
    onClose();
  }, []);

  const handleSaveContainer = useCallback(() => {
    onSave();
  }, []);

  const handleOpenFileUploaderDrawer = useCallback(() => {
    openFileUploaderDrawer();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <CustomModal
      title={t('Create Change Request')}
      onClose={handleCloseDrawer}
      contentStyles={{
        left: 'unset',
        width: '736px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
        padding: '0',
      }}
    >
      <SubPage>
        <Wrapper>
          <GapVerticalContainerStyled width="100%">
            <HintBox>
              <GapVerticalContainerStyled>
                <Title>
                  {t('How does a change request work?')}
                </Title>
                <span>
                  {t('Our operator will process the request within 24 hours and contact you. After approval, the operator will make changes and you will be able to see them in the system.')}
                </span>
              </GapVerticalContainerStyled>
            </HintBox>
            <SubPageContent>
              <ContentSectionTitle>{t('Request Details')}</ContentSectionTitle>
              <GapVerticalContainerStyled width="100%">
                <ContentSection>
                  <GapVerticalContainerStyled width="100%">
                    <ContentGap>
                      <InputTitle title={t('Topic')}>
                        <Title>{t('Update Containers Details')}</Title>
                      </InputTitle>
                    </ContentGap>
                    <ContentSectionContainer />
                    <ContentGap>
                      <ContentSectionContainer>
                        <InputTitle title={t('Description')}>
                          <TextContainer />
                        </InputTitle>
                      </ContentSectionContainer>
                    </ContentGap>
                  </GapVerticalContainerStyled>
                </ContentSection>
                <ContentSection>
                  <DocumentsSection
                    key="attachedDocuments"
                    sectionName="Attached Documents"
                    documents={[]}
                    onDownload={() => {}}
                    onRemove={() => {}}
                    openFileUploaderDrawer={handleOpenFileUploaderDrawer}
                  />
                </ContentSection>
              </GapVerticalContainerStyled>
            </SubPageContent>
          </GapVerticalContainerStyled>
        </Wrapper>
        <Footer>
          <FooterContainer>
            <Button
              htmlType="submit"
              disabled={false}
              loading={isSubmitting}
              onClick={handleSaveContainer}
            >
              {t('Save')}
            </Button>
          </FooterContainer>
        </Footer>
      </SubPage>
    </CustomModal>
  );
};

export { ChangeRequestDrawerComponent };
