import React, { FC, useCallback } from 'react';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import DatePicker from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import type { RangePickerProps } from 'antd/es/date-picker';

import { MissingTextValue } from 'app-wrapper/view/guideline';
import { hasReadAccess } from 'app-wrapper/utils';
import { StepsDTM } from 'shipment-operations/models/dtm';
import {
  IN_PROGRESS, NOT_ACHIEVED, PASSED, FREE_DATE, EXPECTED, ACTUAL, PermissionAttributePolicy,
} from 'shipment-operations/constants';
import Tooltip from 'antd/es/tooltip';
import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  StyledStepIcon, StyledActiveStepIcon, NonActiveStepIcon, Wrapper, StepItem, IconsWrapper, Line,
  InfoBlock, DescriptionWrapper, Title, Location, DetailsKey, EstimatedDateWrapper, ActualDateWrapper,
  Header, HeaderItemEvent, HeaderItemActual, HeaderItemEstimated, DetailsValue, WarningTitle,
  WarningTooltipIcon,
} from './InnerSteps.styled';

const TIME_FORMAT = 'D MMM YYYY, HH:mm';
const ACTUAL_DATEPICKER_ID = 'actual-datepicker';

interface IInnerStepsProps {
  data: StepsDTM[]
  editable: boolean
  onChangeDate: (id: string, type: string, date: Moment) => void
  trackerWarningsAvailability?: PermissionAttributePolicy
  isCommon?: boolean
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
};

const InnerSteps: FC<IInnerStepsProps> = ({
  data,
  editable,
  onChangeDate,
  trackerWarningsAvailability,
  isCommon,
}) => {
  const { t } = useTranslation();

  const mappedIcon = {
    [PASSED]: (
      <StyledStepIcon>
        <CheckOutlined style={{ color: 'white', fontSize: '10px' }} />
      </StyledStepIcon>
    ),
    [IN_PROGRESS]: (
      <StyledActiveStepIcon />
    ),
    [NOT_ACHIEVED]: (
      <NonActiveStepIcon />
    ),
  };

  const colors = {
    [PASSED]: '#202C3C',
    [IN_PROGRESS]: '#6C79DE',
    [NOT_ACHIEVED]: '#73819B',
  };

  const handleChangeDate = useCallback((event, type, date) => {
    onChangeDate(event, type, date);
  }, [data]);

  const isAbleToViewWarning = hasReadAccess(trackerWarningsAvailability);

  return (
    <Wrapper>
      <Header>
        <HeaderItemEvent>{t('events')}</HeaderItemEvent>
        <HeaderItemEstimated>{t('estimated')}</HeaderItemEstimated>
        <HeaderItemActual>{t('actual')}</HeaderItemActual>
        <div>{t('notes')}</div>
      </Header>
      {data.map((item, index) => {
        const disabledDate: RangePickerProps['disabledDate'] = (current) => {
          const inputDate = item.estimatedDate?.getDateAsMomentWithOffset();
          const timezone = inputDate?.format('ZZ');
          if (timezone) {
            return current && current > moment().utcOffset(timezone);
          }
          return current && current > moment().utcOffset('+0000');
        };
        const disableTime = () => {
          const inputDate = item.estimatedDate?.getDateAsMomentWithOffset();
          const timezone = inputDate?.format('ZZ');
          if (timezone) {
            return {
              disabledHours: () => range((moment().utcOffset(timezone).hours()), 23),
            };
          }
          return {};
        };
        return (
          <StepItem key={item.id}>
            <IconsWrapper>
              {mappedIcon[item.status as keyof typeof mappedIcon]}
              {index + 1 !== data.length && (
                <Line active={item.status === PASSED} />
              )}
            </IconsWrapper>
            <InfoBlock lastItem={index + 1 === data.length}>
              <DescriptionWrapper>
                <Title style={{ color: colors[item.status as keyof typeof colors] }}>
                  {t(`eventsCodes.${item.title}`)}
                </Title>
                <div>
                  <Location>
                    {item.locationName && `${item.locationName}, `}
                    {item.stateCode && (item.countryCode === 'US') && `${item.stateCode}, `}
                    {item.countryCode}
                  </Location>
                </div>
                {(item.vessel || item.voyage) && (
                  <div>
                    <DetailsKey>Vessel </DetailsKey>
                    <DetailsValue>{item.vessel} </DetailsValue>
                    <DetailsKey>Voyage </DetailsKey>
                    <DetailsValue>{item.voyage}</DetailsValue>
                  </div>
                )}
              </DescriptionWrapper>
              <EstimatedDateWrapper>
                {isCommon && (item.estimatedDate?.getDateDMMMYYYYHHmm() || <MissingTextValue />)}
                {!isCommon && (item.estimatedDateType && (item.estimatedDateType === FREE_DATE ? (
                  <DatePicker
                    allowClear={false}
                    format={TIME_FORMAT}
                    showTime
                    disabled={!editable || (item.actualDateType !== FREE_DATE)}
                    onChange={(date) => handleChangeDate(item.id, EXPECTED, date)}
                  />
                ) : (
                  <DatePicker
                    allowClear={false}
                    format={TIME_FORMAT}
                    value={item.estimatedDate?.getDateAsMomentWithOffset()}
                    showTime
                    disabled={!editable || (item.originalActualDateType !== FREE_DATE)}
                    onChange={(date) => handleChangeDate(item.id, EXPECTED, date)}
                  />
                )))}
              </EstimatedDateWrapper>
              <ActualDateWrapper>
                {isCommon && (item.actualDate?.getDateDMMMYYYYHHmm() || <MissingTextValue />)}
                {!isCommon && (item.actualDateType && (item.actualDateType === FREE_DATE ? (
                  <>
                    <DatePicker
                      id={`${ACTUAL_DATEPICKER_ID}${index}`}
                      style={{ borderColor: item.withWarning && isAbleToViewWarning ? themesColors.characterBrandingWarning : '' }}
                      allowClear
                      format={TIME_FORMAT}
                      showTime
                      disabled={!editable}
                      disabledDate={disabledDate}
                      disabledTime={disableTime}
                      onChange={(date) => handleChangeDate(item.id, ACTUAL, date)}
                    />
                    {item.withWarning && isAbleToViewWarning && (
                      <WarningTitle>
                        {t('requiredAfterConfirmation')}
                        <Tooltip title={t('dateWarningTooltip')}>
                          <WarningTooltipIcon />
                        </Tooltip>
                      </WarningTitle>
                    )}
                  </>
                ) : (
                  <DatePicker
                    id={`${ACTUAL_DATEPICKER_ID}${index}`}
                    allowClear
                    format={TIME_FORMAT}
                    value={item.actualDate?.getDateAsMomentWithOffset()}
                    showTime
                    disabled={!editable}
                    disabledDate={disabledDate}
                    disabledTime={disableTime}
                    onChange={(date) => handleChangeDate(item.id, ACTUAL, date)}
                  />
                )))}
              </ActualDateWrapper>
            </InfoBlock>
          </StepItem>
        );
      })}
    </Wrapper>
  );
};

export { InnerSteps };
