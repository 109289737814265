import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  MissingTextValue,
  GapVerticalContainerStyled,
  SubPageContent,
  GapHorizontalContainerStyled,
  InfoCardComponent,
  DatePriority,
  ContentSectionContainer,
  GreenTag,
  YellowTag,
} from 'app-wrapper/view/guideline';
import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { ENotificationCriticality, ENotificationStatus, RouteNames } from 'app-wrapper/constants';
import { Link } from 'react-router-dom';
import { CollapseBodyOnly, CollapseButton } from 'app-wrapper/view/components';

interface ITaskInfoProps {
  notificationTask: NotificationTaskContentDTM;
}

export const TaskInfo: FC<ITaskInfoProps> = ({
  notificationTask,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);

  const status = useMemo(() => (notificationTask.status === ENotificationStatus.TODO
    ? <YellowTag>{t('Pending')}</YellowTag>
    : <GreenTag>{notificationTask.status}</GreenTag>), [notificationTask.status]);

  const onClick = useCallback((_show: boolean) => {
    setShow(_show);
  }, [show]);

  return (
    <SubPageContent>
      <GapHorizontalContainerStyled width="90%">
        <GapVerticalContainerStyled width="100%">
          <GapHorizontalContainerStyled>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Status')}
                value={status}
              />
            </ContentSectionContainer>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Due Date')}
                value={
                  notificationTask.dueDate
                    ? (
                      <DatePriority
                        mode={ENotificationCriticality.HIGH}
                        date={notificationTask.dueDate.getFormatDMMMHHmm()}
                        isWarning
                      />
                    )
                    : <MissingTextValue />
                }
              />
            </ContentSectionContainer>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Assignee')}
                value={notificationTask.assignee?.getFullName() || <MissingTextValue />}
              />
            </ContentSectionContainer>
            <ContentSectionContainer style={{ width: '5%' }}>
              <CollapseButton show={show} onClick={onClick} />
            </ContentSectionContainer>
          </GapHorizontalContainerStyled>
          <CollapseBodyOnly
            activeKey={show ? '1' : ''}
          >
            <GapHorizontalContainerStyled>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Created on')}
                  value={notificationTask.createdAt ? notificationTask.createdAt?.getFormatDMMMHHmm() : <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Created by by')}
                  value={notificationTask.createdBy || <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Shipment')}
                  value={(
                    <Link to={RouteNames.SHIPMENT_OVERVIEW(notificationTask.objectReference?.shipmentId)}>
                      {notificationTask.metadata?.shipmentName}
                    </Link>
                  ) || <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer style={{ width: '5%' }} />
            </GapHorizontalContainerStyled>
          </CollapseBodyOnly>
        </GapVerticalContainerStyled>
      </GapHorizontalContainerStyled>
    </SubPageContent>
  );
};
