export const MAX_LENGTH_10 = 10;
export const MAX_LENGTH_20 = 20;
export const MAX_LENGTH_25 = 25;
export const MAX_LENGTH_35 = 35;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_75 = 75;

export const SHIPPING_PARTY_MAX_LENGTHS = {
  COMPANY_NAME: MAX_LENGTH_75,
  TAX_ID: MAX_LENGTH_35,
  ADDRESS_1: MAX_LENGTH_50,
  ADDRESS_2: MAX_LENGTH_50,
  CITY: MAX_LENGTH_35,
  POSTCODE: MAX_LENGTH_10,
  FULL_NAME: MAX_LENGTH_35,
  EMAIL: 254,
};
