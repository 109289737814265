import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { BaseController } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';

export class InputMobxUseCase<T extends InputStore<any>> extends BaseUseCase {
  storeMobx: T

  validate: (useCase: InputMobxUseCase<T>) => void

  constructor(
    controller: BaseController,
    storeMobx: T,
    validate?: (useCase: InputMobxUseCase<T>) => void,
  ) {
    super(controller);
    this.storeMobx = storeMobx;
    this.validate = validate || (() => {});
  }

  onChange(value: string) {
    const validValue = value.slice(0, 50);
    this.storeMobx.setValue(validValue);

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate(this);
  }

  onClear() {
    this.storeMobx.setValue('');

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate(this);
  }

  public onBlur() {
    this.storeMobx.setStates({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    });
    this.validate(this);
  }

  public setError(error: string | null) {
    this.storeMobx.setError(error);
  }
}
