import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentsCollapseItemDTM } from 'shipment-operations/models/dtm';
import { ExpandIcon } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  UploadIcon,
} from 'shipment-operations/view/pages/ShipmentDocumentsAll/ShipmentDocumentsAll.styled';
import { ContentGap } from 'app-wrapper/view/guideline/ContentGap';
import {
  DocumentCollapse,
  CollapsePanel,
  CollapseIcon,
  CollapseIconActive,
  CollapseLabel,
  CollapseLabelText,
  TableCell,
  TableDocuments,
  BoldText,
} from './DocumentsSection.styled';
import { ActionMenu } from './components';

interface DocumentsSectionProps {
  sectionName: string
  isCollapsed?: boolean
  documents: DocumentsCollapseItemDTM[]
  onDownload?: (documentId: number, documentName: string) => void
  onRemove?: (document: DocumentsCollapseItemDTM) => void
  openFileUploaderDrawer: () => void
}

export const DocumentsSection: FC<DocumentsSectionProps> = ({
  sectionName,
  documents,
  isCollapsed,
  onDownload,
  onRemove,
  openFileUploaderDrawer,
}) => {
  const { t } = useTranslation();
  const handleOpenFileUploaderDrawer = useCallback(() => {
    openFileUploaderDrawer();
  }, []);

  const columns = useMemo(() => [
    {
      title: t('Document Name'),
      width: '25%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <BoldText>
            {document.name}
          </BoldText>
        );
      },
    },
    {
      title: t('Uploaded Date'),
      width: '25%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <TableCell>
            {document.uploadedDate ? document.uploadedDate.getDateDMMMYYYYHHmmWithOffset() : '-'}
          </TableCell>
        );
      },
    },
    {
      title: t('Uploaded By'),
      width: '25%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <TableCell>
            {document.createdBy}
          </TableCell>
        );
      },
    },
    {
      title: t('Action'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        const handleDelete = onRemove ? () => onRemove(document) : undefined;
        const handleDownload = onDownload ? () => onDownload(document.id, document.name) : undefined;

        return (
          <TableCell>
            <ActionMenu
              onDownload={handleDownload}
              onDelete={handleDelete}
            />
          </TableCell>
        );
      },
    },
  ], []);

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  return (
    <DocumentCollapse expandIcon={onExpandIcon}>
      <CollapsePanel
        key={sectionName}
        header={(
          <ContentGap justifyContent="space-between">
            <CollapseLabel>
              {isCollapsed ? (
                <CollapseIconActive />
              ) : (
                <CollapseIcon />
              )}

              <CollapseLabelText $isCollapsed={isCollapsed}>
                {sectionName}
              </CollapseLabelText>

            </CollapseLabel>
            <UploadIcon onClick={handleOpenFileUploaderDrawer} />
          </ContentGap>
        )}
      >
        <TableDocuments
          pagination={false}
          columns={columns}
          dataSource={documents}
        />
      </CollapsePanel>
    </DocumentCollapse>
  );
};
