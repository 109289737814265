import moment from 'moment';
import { AirVentDTM } from 'shipment-operations/models/dtm/AirVent.dtm';
import { v4 as uuidv4 } from 'uuid';
import { AxiosError, CanceledError } from 'axios';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { CommandCenterGetTasksDTM, DateDtm, IDocumentDTM } from 'app-wrapper/models/dtm';
import { ServerError } from 'app-wrapper/types/ServerError';
import { EPageSortGeneral } from 'app-wrapper/models/contracts';
import { ELocationType } from 'app-wrapper/types/LocationType';
import { ReferenceDTM } from 'app-wrapper/types';

import {
  CargoBaseDTM,
  CargoDTM,
  ContainerDocumentDTM,
  ContainerDTM,
  FullShipmentDTM,
  MSDSDocumentDTM,
  ShipmentPreviewDTM,
  ShipmentPreviewTransactionStatusDTM,
  ShipmentPreviewGetServiceDTM,
  TemperatureControlDTM,
  FullShipmentLocationDTM,
  ShipmentConfirmationDTM,
  DocumentDTM,
  IShipmentConfirmationDTM,
  ShipmentStatsCustomer,
  ShipmentAccountStatsCustomer,
  ShipmentAllStatsDTM,
} from 'shipment-operations/models/dtm';
import {
  TGetShipmentListResponse,
  GetShipmentResponse,
  IGetShortShipmentResponse,
  IGetFiltersShipmentList,
  TGetShipmentListRequestParams,
  GetFullShipmentResponse,
  TGetShipmentConfirmationsContract,
  TPostShipmentConfirmationsContract, GetShipmentStatsCustomerContract, GetOrganizationAccountStatsContract, GetShipmentAllStatsContract,
} from 'shipment-operations/models/contracts';
import { NetworkErrorProto } from 'app-wrapper/models/errors';
import { ShipmentListFiltersDTM } from 'shipment-operations/models/dtm/ShipmentListFilters.dtm';
import {
  AirVentUnitsType,
  ContainerAllTypesArraySort,
  ContainerAllTypesNamesLongConst,
  ContainerReeferTypes,
  ContainerUsualTypes,
  ShipmentFiltersCompaniesTypeEnum,
  ShipmentRoutesType,
  ShipmentStatusEnum,
  ShipmentTransactionStatusNames,
  isContainerAllTypes, EContainerReferenceType,
} from 'shipment-operations/constants';

export class ShipmentService {
  private base = '/shipment-service/api/v1/shipments';

  private organizationAccount = '/billing-service/api/v1/accounts/current'

  private generateStatusFilter = (page: string) => {
    if (page === 'active') {
      return [ShipmentStatusEnum.PENDING_CONFIRMATION, ShipmentStatusEnum.CONFIRMED, ShipmentStatusEnum.IN_TRANSIT, ShipmentStatusEnum.DELIVERED];
    }
    if (page === 'completed') {
      return [ShipmentStatusEnum.COMPLETED];
    }
    if (page === 'cancelled') {
      return [ShipmentStatusEnum.CANCELLED];
    }
    if (page === 'waiting-approval') {
      return [ShipmentStatusEnum.AWAITING_APPROVAL];
    }
    return [];
  }

  public getShipmentById = async (shipmentId: number) => {
    let shipment: GetShipmentResponse | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<GetShipmentResponse>(`${this.base}/${shipmentId}`);

      shipment = rawResponse.data;
    } catch (e) {
      const error = e as AxiosError<ServerError>;
      const defaultMessage = 'getShipmentById error';

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: defaultMessage,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          status: error.status,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: defaultMessage,
        status: error.status,
        code: error?.response?.data?.code,
      });
    }

    return shipment;
  }

  public getFullShipment = async (shipmentId: number | string) => {
    let fullShipment: FullShipmentDTM | null = null;

    const response = await apiWorker.requestGet<GetFullShipmentResponse>(`${this.base}/${shipmentId}/full`);

    if (response.status === 200) {
      const body = response.data;

      fullShipment = FullShipmentDTM.fromPlain({
        id: body.id,
        createdBy: body.createdBy,
        status: body.status,
        earliestEmptyReleaseDate: DateDtm.fromPlain({
          date: body.earliestEmptyReleaseDate,
          offset: moment.parseZone(body.earliestEmptyReleaseDate).utcOffset(),
        }),
        organization: body.organization,
        transportationPlans: body.transportationPlans,
        locations: body.locations.map((location) => FullShipmentLocationDTM.fromPlain({
          id: String(location.id),
          type: location.type as ShipmentRoutesType,
          terminalName: location.terminalName,
          passCode: location.passCode,
          address1: location.address1,
          address2: location.address2,
          address3: location.address3,
          address4: location.address4,
          city: location.city,
          state: location.state,
          postcode: location.postcode,
          country: location.country,
          time: location.time ? DateDtm.fromPlain({
            date: location.time,
            offset: moment.parseZone(location.time).utcOffset(),
          }) : undefined,
        })),
        cargos: this.convertFromGetCargosResponse(body.cargos),
        containers: this.convertContainersToDTM(body.containers),
        temperatureControl: body.temperatureControl ? TemperatureControlDTM.fromPlain({
          id: body.temperatureControl.id,
          temperature: body.temperatureControl.temperature ? `${body.temperatureControl.temperature}` : '',
          airVent: AirVentDTM.fromPlain({
            flowRate: body.temperatureControl.airVent ? `${body.temperatureControl.airVent.flowRate}` : undefined,
            flowRateUnit: body.temperatureControl.airVent ? body.temperatureControl.airVent.flowRateUnit as AirVentUnitsType : undefined,
          }),
          genset: body.temperatureControl.genset,
        }) : null,
        carrierName: body.carrier ? body.carrier.name : '',
        bookingDetails: body.bookingDetails,
        siDetails: body.siDetails,
        exportCustoms: {
          entryNumber: body?.exportCustoms?.entryNumber || 0,
        },
        paymentTerms: body.paymentTerms,
        shipmentReference: body.shipmentReference,
        mainVesselName: body.mainVesselName,
      });
    }

    return fullShipment;
  }

  public getShipmentShortById = async (shipmentId: number, removeSignal?: boolean) => {
    let shipmentData: ShipmentPreviewDTM | null = null;

    const response = await apiWorker.requestGet<IGetShortShipmentResponse>(`${this.base}/${shipmentId}/short`, {}, removeSignal);

    const body = response.data;

    // ETD, ATD, ETA, ATA time should not be changed for current timezone
    const fromETD = this.getShiftedDate(body.from.dates.etd);
    const fromATD = this.getShiftedDate(body.from.dates.atd);
    const toETA = this.getShiftedDate(body.to.dates.eta || body.to.dates.etd);
    const toATA = this.getShiftedDate(body.to.dates.ata || body.to.dates.atd);

    shipmentData = ShipmentPreviewDTM.fromPlain({
      id: body.id,
      status: body.status,
      transactionStatus: body.transactionStatus ? ShipmentPreviewTransactionStatusDTM.fromPlain({
        displayValue: ShipmentTransactionStatusNames[body.transactionStatus],
        enumValue: body.transactionStatus,
      }) : undefined,
      bookingStatus: body.bookingDetails.status,
      shipmentName: `${body.shipmentName}`,
      mainVesselName: body.mainVesselName || undefined,
      reference: body.shipmentReference,
      carrier: body.carrier.name,
      scac: body.carrier.scac,
      deliveryType: body.deliveryType,
      oceanBookingId: body.bookingDetails.oceanBookingId,
      isRequestTheSameWithShipment: body.bookingDetails.requestTheSameWithShipment,
      siStatus: body.siDetails.status,
      mblDraftId: body.siDetails.mblDraftId,
      mblFinalId: body.siDetails.mblFinalId,
      siRequestTheSameWithShipment: body.siDetails.requestTheSameWithShipment,
      rollPlanId: body.rollPlanId,
      manualConfirmationDocument: body.bookingDetails.manualConfirmationDocument ? DocumentDTM.fromPlain({
        ...body.bookingDetails.manualConfirmationDocument,
        createdAt: DateDtm.fromPlain({
          date: body.bookingDetails.manualConfirmationDocument.createdAt,
          offset: moment.parseZone(body.bookingDetails.manualConfirmationDocument.createdAt).utcOffset(),
        }),
      }) : null,
      siSentAt: body.siDetails.sentAt ? DateDtm.fromPlain({
        date: body.siDetails.sentAt,
        offset: moment.parseZone(body.siDetails.sentAt).utcOffset(),
      }) : null,
      createdAt: body.createdAt ? DateDtm.fromPlain({
        date: body.createdAt,
        offset: moment.parseZone(body.createdAt).utcOffset(),
      }) : null,
      confirmedAt: body.confirmedAt ? DateDtm.fromPlain({
        date: body.confirmedAt,
        offset: moment.parseZone(body.confirmedAt).utcOffset(),
      }) : null,
      containerCount: body.containerCount,
      isSelfService: body.isSelfService,
      origin: {
        name: body.from.companyName,
        code: body.from.unloco,
        address: this.addressToString(body.from.city, body.from.country?.code),
        countryCode: body.from.country?.code,
        estimatedDate: fromETD,
        realDate: fromATD,
        type: body.from.type as ELocationType,
        city: body.from.city,
        countryName: body.from.country?.name,
      },
      destination: {
        name: body.to.companyName,
        code: body.to.unloco,
        address: this.addressToString(body.to.city, body.to.country?.code),
        countryCode: body.to.country?.code,
        estimatedDate: toETA,
        realDate: toATA,
        type: body.to.type as ELocationType,
        city: body.from.city,
        countryName: body.from.country?.name,
      },
      customer: body.customer ? {
        companyName: body.customer.company.name,
        name: body.customer.contact.name,
        email: body.customer.contact.email,
        phone: body.customer.contact.phone,
        id: body.customer.company.id,
      } : null,
      carrierReferenceNumber: body.bookingDetails.carrierReferenceNumber,
      contractOwnerOrgId: body.contractOwnerOrgId || 0,
      destinationPartnerOrgId: body.destinationPartnerOrgId || 0,
      destinationPartnerAgentOrgId: body.destinationPartnerAgentOrgId || 0,
      originPartnerOrgId: body.originPartnerOrgId || 0,
      customerOrgId: body.customerOrgId || 0,
      organizationId: body.organizationId || 0,
      accountHolderOrgId: body.accountHolderOrgId || 0,
      bookingAgentOrgId: body.bookingAgentOrgId || 0,
      originPartnerAgentOrgId: body.originPartnerAgentOrgId || 0,
      earliestEmptyReleaseDate: body.earliestEmptyReleaseDate,
      hasReeferContainer: body.hasReeferContainer,
      hasSelfOwnedContainer: body.hasSelfOwnedContainer,
      cancelReasonDto: body.cancelReasonDto && {
        type: body.cancelReasonDto.type,
        message: body.cancelReasonDto.message,
      },
    });

    return shipmentData;
  }

  public getConfirmations = async (shipmentId: string | number) => {
    let confirmations: ShipmentConfirmationDTM[] = [];

    const response = await apiWorker.requestGet<TGetShipmentConfirmationsContract>(`${this.base}/${shipmentId}/confirmations`);

    confirmations = response.data ? response.data.map(({
      id,
      type,
      createdAt,
      createdBy,
      createdByFirstName,
      createdByLastName,
    }) => ShipmentConfirmationDTM.fromPlain({
      id,
      type,
      createdBy,
      createdAt,
      createdByFirstName,
      createdByLastName,
    })) : [];

    return confirmations;
  }

  public postConfirmations = async (shipmentId: string | number, confirmation: IShipmentConfirmationDTM): Promise<ShipmentConfirmationDTM | null> => {
    let responseConfirmation: ShipmentConfirmationDTM | null = null;

    const response = await apiWorker.requestPost<TPostShipmentConfirmationsContract>(`${this.base}/${shipmentId}/confirmations`, confirmation);

    responseConfirmation = response.data ? ShipmentConfirmationDTM.fromPlain({
      id: response.data.id, type: response.data.type, createdAt: response.data.createdAt, createdBy: response.data.createdBy,
    }) : null;

    return responseConfirmation;
  }

  public getHasOwnBookedShipments = async (): Promise<boolean> => {
    const postParams: TGetShipmentListRequestParams = {
      pageRequest: {
        page: 0,
        size: 20,
        sort: EPageSortGeneral.ARRIVAL,
      },
    };

    try {
      const response = await apiWorker.requestPost<TGetShipmentListResponse>(`${this.base}/search`, postParams);

      if (response.data) {
        return response.data.length !== 0;
      }
    } catch (e) {
      console.error('Shipment Service ERROR: getHasOwnBookedShipments');
    }

    return false;
  };

  public getList = async () => {
    let list: ShipmentPreviewDTM[] | null = null;
    let isEnd: boolean = false;

    try {
      const rawResponse = await apiWorker.requestGet<TGetShipmentListResponse>(`${this.base}/list`);
      const response = rawResponse.data;

      if (response.length > 0) {
        const parsedResponse = response.map((item) => {
          // ETD, ATD, ETA, ATA time should not be changed for current timezone
          const fromETD = this.getShiftedDate(item.from.dates.etd);
          const fromATD = this.getShiftedDate(item.from.dates.atd);
          const toETA = this.getShiftedDate(item.to.dates.eta || item.to.dates.etd);
          const toATA = this.getShiftedDate(item.to.dates.ata || item.to.dates.atd);

          const parsedItem = ShipmentPreviewDTM.fromPlain({
            id: item.id,
            status: item.status,
            bookingStatus: item.bookingDetails.status,
            isRequestTheSameWithShipment: item.bookingDetails.requestTheSameWithShipment,
            carrierReferenceNumber: item.bookingDetails.carrierReferenceNumber,
            shipmentName: `${item.shipmentName}`,
            reference: '',
            carrier: item.carrier.name,
            scac: item.carrier.scac,
            mainVesselName: item.mainVesselName || undefined,
            oceanBookingId: item.bookingDetails.oceanBookingId,
            siStatus: item.siDetails.status,
            mblDraftId: item.siDetails.mblDraftId,
            mblFinalId: item.siDetails.mblFinalId,
            manualConfirmationDocument: null,
            createdAt: null,
            rollPlanId: null,
            siSentAt: item.siDetails.sentAt ? DateDtm.fromPlain({
              date: item.siDetails.sentAt,
              offset: moment.parseZone(item.siDetails.sentAt).utcOffset(),
            }) : null,
            containerCount: item.containerCount,
            origin: {
              name: item.from.companyName,
              address: this.addressToString(item.from.city, item.from.country?.code),
              estimatedDate: fromETD,
              realDate: fromATD,
              type: item.from.type as ELocationType,
            },
            destination: {
              name: item.to.companyName,
              address: this.addressToString(item.to.city, item.to.country?.code),
              estimatedDate: toETA,
              realDate: toATA,
              type: item.to.type as ELocationType,
            },
            customer: item.customer ? {
              companyName: item.customer.company.name,
              name: item.customer.contact.name || '',
              email: item.customer.contact.email,
              phone: item.customer.contact.phone,
            } : null,
            confirmedAt: null,
            earliestEmptyReleaseDate: item.earliestEmptyReleaseDate,
            hasReeferContainer: item.hasReeferContainer,
            hasSelfOwnedContainer: item.hasSelfOwnedContainer,
          });

          if (!parsedItem.isValid()) {
            console.error('Data from API does not match with contract', parsedItem.validate());
          }
          return parsedItem;
        });
        list = parsedResponse.filter((el) => el !== null) as ShipmentPreviewDTM[];
      }

      list?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid Shipment: getList', errors);
        }
      });

      if (!response.length) {
        isEnd = true;
      }
    } catch (e) {
      throw new Error('Shipment list getting error');
    }

    return ShipmentPreviewGetServiceDTM.fromPlain({
      isEnd,
      shipmentList: list || undefined,
    });
  }

  public postShipmentSearch = async (params?: TGetShipmentListRequestParams) => {
    let list: ShipmentPreviewDTM[] | null = null;
    let isEnd: boolean = false;

    const postParams: TGetShipmentListRequestParams = {
      ...params,
      pageRequest: {
        page: 0,
        size: 20,
        sort: EPageSortGeneral.ARRIVAL,
        ...params?.pageRequest,
      },
    };

    try {
      const rawResponse = await apiWorker.requestPost<TGetShipmentListResponse>(`${this.base}/search`, postParams);
      const response = rawResponse.data;

      if (response.length > 0) {
        const parsedResponse = response.map((item) => {
          // ETD, ATD, ETA, ATA time should not be changed for current timezone
          const fromETD = this.getShiftedDate(item.from.dates.etd);
          const fromATD = this.getShiftedDate(item.from.dates.atd);
          const toETA = this.getShiftedDate(item.to.dates.eta || item.to.dates.etd);
          const toATA = this.getShiftedDate(item.to.dates.ata || item.to.dates.atd);

          const parsedItem = ShipmentPreviewDTM.fromPlain({
            id: item.id,
            status: item.status,
            transactionStatus: item.transactionStatus ? ShipmentPreviewTransactionStatusDTM.fromPlain({
              displayValue: ShipmentTransactionStatusNames[item.transactionStatus],
              enumValue: item.transactionStatus,
            }) : undefined,
            bookingStatus: item.bookingDetails.status,
            isRequestTheSameWithShipment: item.bookingDetails.requestTheSameWithShipment,
            carrierReferenceNumber: item.bookingDetails.carrierReferenceNumber,
            shipmentName: `${item.shipmentName}`,
            reference: item.shipmentReference,
            carrier: item.carrier.name,
            scac: item.carrier.scac,
            mainVesselName: item.mainVesselName || undefined,
            oceanBookingId: item.bookingDetails.oceanBookingId,
            siStatus: item.siDetails.status,
            mblDraftId: item.siDetails.mblDraftId,
            mblFinalId: item.siDetails.mblFinalId,
            manualConfirmationDocument: null,
            createdAt: null,
            rollPlanId: null,
            siSentAt: item.siDetails.sentAt ? DateDtm.fromPlain({
              date: item.siDetails.sentAt,
              offset: moment.parseZone(item.siDetails.sentAt).utcOffset(),
            }) : null,
            containerCount: item.containerCount,
            origin: {
              name: item.from.companyName,
              address: this.addressToString(item.from.city, item.from.country?.code),
              estimatedDate: fromETD,
              realDate: fromATD,
              type: item.from.type as ELocationType,
            },
            destination: {
              name: item.to.companyName,
              address: this.addressToString(item.to.city, item.to.country?.code),
              estimatedDate: toETA,
              realDate: toATA,
              type: item.to.type as ELocationType,
            },
            customer: item.customer ? {
              companyName: item.customer.company.name,
              name: item.customer.contact.name,
              email: item.customer.contact.email,
              phone: item.customer.contact.phone,
            } : null,
            confirmedAt: null,
            earliestEmptyReleaseDate: item.earliestEmptyReleaseDate,
            hasReeferContainer: item.hasReeferContainer,
            hasSelfOwnedContainer: item.hasSelfOwnedContainer,
            shipmentAllTasks: '',
            shipmentAllAlerts: '',
          });

          if (!parsedItem.isValid()) {
            console.error('Data from API does not match with contract', parsedItem.validate());
          }
          return parsedItem;
        });
        list = parsedResponse.filter((el) => el !== null) as ShipmentPreviewDTM[];
      }

      list?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid Shipment: postShipmentSearch', errors);
        }
      });

      if (!response.length) {
        isEnd = true;
      }
    } catch (e) {
      const error = e as Error;

      if (error instanceof CanceledError) {
        throw error;
      }

      throw new Error('Shipment list (post search) getting error');
    }

    return ShipmentPreviewGetServiceDTM.fromPlain({
      isEnd,
      shipmentList: list || undefined,
    });
  }

  public getFilters = async (page: string) => {
    let result: ShipmentListFiltersDTM | null = null;

    const statuses = this.generateStatusFilter(page);

    const rawResponse = await apiWorker.requestGet<IGetFiltersShipmentList>(`${this.base}/filters`);

    const response = rawResponse.data;
    if (response) {
      const groupLocation = response.locations?.map((itemLocation) => ({
        name: this.getLocation((itemLocation.name || itemLocation?.city) || '', itemLocation?.country?.code),
        checked: false,
        value: (itemLocation.name || itemLocation?.city) || '',
        value2: itemLocation.country?.code,
        id: uuidv4(),
      })) || [];
      const groupShippingParty = response.companies?.filter((item) => item.type !== ShipmentFiltersCompaniesTypeEnum.OCEAN_CARRIER)?.map((itemLocation) => ({
        name: this.getLocation(itemLocation.name || ''),
        checked: false,
        value: this.getLocation(itemLocation.name || ''),
        id: uuidv4(),
      })) || [];
      const groupShippingCarrier = response.companies?.filter((item) => item.type === ShipmentFiltersCompaniesTypeEnum.OCEAN_CARRIER)?.map((itemLocation) => ({
        name: this.getLocation(itemLocation.name || ''),
        checked: false,
        value: this.getLocation(itemLocation.name || ''),
        id: uuidv4(),
      })) || [];
      const groupContainerType = response.containerTypes?.map((itemLocation) => {
        const containerName = isContainerAllTypes(itemLocation) && ContainerAllTypesNamesLongConst[itemLocation];

        return {
          name: containerName || '',
          checked: false,
          value: itemLocation,
          id: uuidv4(),
        };
      })?.sort((a, b) => (ContainerAllTypesArraySort.indexOf(a?.value || '') - ContainerAllTypesArraySort.indexOf(b?.value || ''))) || [];
      const groupStatus = response.statuses?.map((itemLocation) => ({
        name: ShipmentStatusEnum?.[itemLocation]?.toLowerCase()?.replace(/_/g, ' ') || '',
        checked: false,
        value: itemLocation,
        id: uuidv4(),
      })) || [];

      result = ShipmentListFiltersDTM.fromPlain({
        filterLocation: {
          values: {
            group: [...groupLocation],
            groupDefault: [...groupLocation],
            disableReset: true,
          },
          response: response.locations?.map((item) => ({
            id: item.id || undefined,
            customId: uuidv4(),
            country: item.country ? {
              code: item.country.code,
              name: item.country.name,
            } : undefined,
            state: item.state ? {
              code: item.state.code,
              name: item.state.name,
            } : undefined,
            coordinates: item.coordinates ? {
              lat: item.coordinates.lat,
              lng: item.coordinates.lng,
            } : undefined,
            timezoneId: item.timezoneId || undefined,
            code: item.code || undefined,
            name: item.name || '',
            city: item.city,
            type: item.type || '',
            placeId: item.placeId,
          })) || [],
        },
        filterShippingParty: {
          values: {
            group: [...groupShippingParty],
            groupDefault: [...groupShippingParty],
            disableReset: true,
          },
          response: response.companies?.filter((item) => item.type !== ShipmentFiltersCompaniesTypeEnum.OCEAN_CARRIER)?.map((item) => ({
            type: item.type || undefined,
            companyType: item.companyType || undefined,
            organizationId: item.organizationId || undefined,
            isPrimary: item.isPrimary || undefined,
            id: item.id || undefined,
            customId: uuidv4(),
            name: item.name || undefined,
            phone: item.phone || undefined,
            phone2: item.phone2 || undefined,
            email: item.email || undefined,
            taxId: item.taxId || undefined,
            contacts: item.contacts?.map((itemContract) => ({
              id: itemContract.id || undefined,
              customId: uuidv4(),
              fullName: itemContract.fullName || undefined,
              email: itemContract.email || undefined,
              phone: itemContract.phone || undefined,
              phone2: itemContract.phone2 || undefined,
            })),
            addresses: item.addresses?.map((itemAddress) => ({
              id: itemAddress.id || undefined,
              customId: uuidv4(),
              country: itemAddress.country || undefined,
              state: itemAddress.state || undefined,
              city: itemAddress.city || undefined,
              address1: itemAddress.address1 || undefined,
              address2: itemAddress.address2 || undefined,
              postalCode: itemAddress.postalCode || undefined,
              closestPort: itemAddress.closestPort || undefined,
            })),
          })) || [],
        },
        filterShippingCarrier: {
          values: {
            group: [...groupShippingCarrier],
            groupDefault: [...groupShippingCarrier],
            disableReset: true,
          },
          response: response.companies?.filter((item) => item.type === ShipmentFiltersCompaniesTypeEnum.OCEAN_CARRIER)?.map((item) => ({
            type: item.type || undefined,
            companyType: item.companyType || undefined,
            organizationId: item.organizationId || undefined,
            isPrimary: item.isPrimary || undefined,
            id: item.id || undefined,
            customId: uuidv4(),
            name: item.name || undefined,
            phone: item.phone || undefined,
            phone2: item.phone2 || undefined,
            email: item.email || undefined,
            taxId: item.taxId || undefined,
            contacts: item.contacts?.map((itemContract) => ({
              id: itemContract.id || undefined,
              customId: uuidv4(),
              fullName: itemContract.fullName || undefined,
              email: itemContract.email || undefined,
              phone: itemContract.phone || undefined,
              phone2: itemContract.phone2 || undefined,
            })),
            addresses: item.addresses?.map((itemAddress) => ({
              id: itemAddress.id || undefined,
              customId: uuidv4(),
              country: itemAddress.country || undefined,
              state: itemAddress.state || undefined,
              city: itemAddress.city || undefined,
              address1: itemAddress.address1 || undefined,
              address2: itemAddress.address2 || undefined,
              postalCode: itemAddress.postalCode || undefined,
              closestPort: itemAddress.closestPort || undefined,
            })),
          })) || [],
        },
        filterContainerType: {
          values: {
            group: [...groupContainerType],
            groupDefault: [...groupContainerType],
            disableReset: true,
          },
          response: [...response.containerTypes || []],
        },
        filterStatus: {
          values: {
            group: [...groupStatus],
            groupDefault: [...groupStatus],
            disableReset: true,
          },
          response: [...statuses || []],
        },
        filterSortBy: {
          values: {
            isUpdate: false,
          },
          response: {},
        },
      });

      const errors = result.validate();

      if (errors.length) {
        console.error('DTM valid Shipment: getFilters', errors);
      }
    }

    return result;
  }

  public updateEmptyReleaseDate = async (shipmentId: string, emptyReleaseDate: string) => {
    try {
      await apiWorker.requestPatch(`${this.base}/${shipmentId}`, [
        {
          op: 'replace',
          path: '/earliestEmptyReleaseDate',
          value: emptyReleaseDate,
        },
      ]);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;

      if (error && error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      }
    }
  }

  public rollShipment = async (shipmentId: string, quotaId: number, quotaScheduleId: number, quotaRequestId: number) => {
    await apiWorker.requestPost(`${this.base}/${shipmentId}/roll`, {
      quotaId,
      quotaRequestId,
      quotaScheduleId,
    });
  }

  public resubmitShipmentBooking = async (shipmentId: string) => {
    await apiWorker.requestPost(`${this.base}/${shipmentId}/booking/re-submit`);
  }

  public getShipmentStatsCustomer = async (shipmentId: string, category: string) => {
    let result: ShipmentStatsCustomer | null;
    try {
      const response = await apiWorker.requestGet<GetShipmentStatsCustomerContract>(`/billing-service/api/v1/shipments/${shipmentId}/${category}/stats`);
      result = ShipmentStatsCustomer.fromPlain({
        totalInvoiced: response.data.totalInvoiced,
        totalPaid: response.data.totalPaid,
        totalUnInvoiced: response.data.totalUnInvoiced,
      });
    } catch (e) {
      throw new Error('ShipmentService: shipmentStats');
    }

    return result;
  }

  public getShipmentAllStats = async (params: CommandCenterGetTasksDTM) => {
    let result: ShipmentAllStatsDTM[] | undefined;

    if (params.dueDateTo === 'undefined' || params.dueDateFrom === 'undefined') {
      return result;
    }

    try {
      const response = await apiWorker.requestGet<GetShipmentAllStatsContract[]>('/task-service/api/v1/shipments/stats', {
        params: {
          page: params.page,
          size: params.size,
          status: params.query,
          criticality: params.criticality?.join(','),
          sort: params.sort,
          includeEmptyDueDate: params.includeEmptyDueDate,
          'dueDate.to': params.dueDateTo,
          'dueDate.from': params.dueDateFrom,
          'completedAt.from': params.completedAtFrom,
          'completedAt.to': params.completedAtTo,
          'metadata[shipmentId]': params.shipmentId,
          domains: params.domain?.trim(),
          'targetAudience.organizationIds': params?.targetAudienceOrganizationIds?.join(',') || undefined,
          'targetAudience.userEmails': params?.targetAudienceUserEmails?.join(',') || undefined,
          'objectReference.organizationIds': params?.objectReferenceOrganizationIds?.join(',') || undefined,
          'objectReference.paymentIds': params?.objectReferencePaymentIds?.join(',') || undefined,
          'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds?.join(',') || undefined,
          'objectReference.shipmentIds': params?.objectReferenceShipmentIds?.join(',') || undefined,
          'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
          'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
        },
      });

      result = response.data.map((item) => ShipmentAllStatsDTM.fromPlain({
        objectReference: {
          id: item.objectReference.id,
          type: item.objectReference.type,
          shipmentId: item.objectReference.shipmentId,
        },
        taskStats: {
          todo: item.taskStats.todo,
          done: item.taskStats.done,
          expiring: item.taskStats.expiring,
          overdue: item.taskStats.overdue,
          alerts: item.taskStats.alerts,
          highTodo: item.taskStats.highTodo,
          highExpiring: item.taskStats.highExpiring,
          highOverdue: item.taskStats.highOverdue,
        },
        notificationStats: {
          unread: item.notificationStats.unread,
          read: item.notificationStats.read,
          unreadCritical: item.notificationStats.unreadCritical,
          unreadRegular: item.notificationStats.unreadRegular,
        },
      }));
    } catch (e) {
      throw new Error('ShipmentService: getShipmentAllStats');
    }

    return result;
  }

  public getShipmentStatsById = async (params: CommandCenterGetTasksDTM, shipmentId?: string) => {
    let result: ShipmentAllStatsDTM | undefined;

    if (!shipmentId) {
      return result;
    }

    if (params.dueDateTo === 'undefined' || params.dueDateFrom === 'undefined') {
      return result;
    }

    try {
      const response = await apiWorker.requestGet<GetShipmentAllStatsContract>(`/task-service/api/v1/shipments/${shipmentId}/stats`, {
        params: {
          page: params.page,
          size: params.size,
          status: params.query,
          criticality: params.criticality?.join(','),
          sort: params.sort,
          includeEmptyDueDate: params.includeEmptyDueDate,
          'dueDate.to': params.dueDateTo,
          'dueDate.from': params.dueDateFrom,
          'completedAt.from': params.completedAtFrom,
          'completedAt.to': params.completedAtTo,
          'metadata[shipmentId]': params.shipmentId,
          domains: params.domain?.trim(),
          'targetAudience.organizationIds': params?.targetAudienceOrganizationIds?.join(',') || undefined,
          'targetAudience.userEmails': params?.targetAudienceUserEmails?.join(',') || undefined,
          'objectReference.organizationIds': params?.objectReferenceOrganizationIds?.join(',') || undefined,
          'objectReference.paymentIds': params?.objectReferencePaymentIds?.join(',') || undefined,
          'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds?.join(',') || undefined,
          'objectReference.shipmentIds': params?.objectReferenceShipmentIds?.join(',') || undefined,
          'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
          'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
        },
      });

      result = ShipmentAllStatsDTM.fromPlain({
        taskStats: {
          todo: response.data.taskStats.todo,
          done: response.data.taskStats.done,
          expiring: response.data.taskStats.expiring,
          overdue: response.data.taskStats.overdue,
          alerts: response.data.taskStats.alerts,
          highTodo: response.data.taskStats.highTodo,
          highExpiring: response.data.taskStats.highExpiring,
          highOverdue: response.data.taskStats.highOverdue,
        },
        notificationStats: {
          unread: response.data.notificationStats.unread,
          read: response.data.notificationStats.read,
          unreadCritical: response.data.notificationStats.unreadCritical,
          unreadRegular: response.data.notificationStats.unreadRegular,
        },
      });
    } catch (e) {
      throw new Error('ShipmentService: getShipmentStatsById');
    }

    return result;
  }

  public getOrganizationAccountStats = async (category: string) => {
    const response = await apiWorker.requestGet<GetOrganizationAccountStatsContract>(`${this.organizationAccount}/${category?.toLowerCase()}/stats`);
    const result = ShipmentAccountStatsCustomer.fromPlain({
      totalPaid: response.data.totalPaid,
      totalInvoiced: response.data.totalInvoiced,
      totalUnInvoiced: response.data.totalUnInvoiced,
      totalTransferred: response.data.totalTransferred,
      totalCredited: response.data.totalCredited,
      accountBalance: response.data.accountBalance,
    });

    return result;
  }

  private convertContainersToDTM = (data: GetFullShipmentResponse['containers']): ContainerDTM[] => {
    const containers = data.map((item) => {
      const cargoItems = item.cargoItems.map((cargoItem) => ({
        id: `${cargoItem.id}`,
        cargoId: `${cargoItem.cargoId}`,
        packagesNumber: `${cargoItem.packagesNumber}`,
        weight: `${cargoItem.weight}`,
        volume: `${cargoItem.volume}`,
      }));

      const references = item.references.map((ref) => (ReferenceDTM.fromPlain({
        id: `${ref.id}`,
        type: ref.type as EContainerReferenceType,
        value: ref.value,
      })));

      const parsedItem = ContainerDTM.fromPlain({
        cargoItems,
        references,
        id: `${item.id}`,
        type: item.type as ContainerReeferTypes | ContainerUsualTypes,
        number: item.number || undefined,
        sealNumber: item.sealNumber || '',
        ownContainer: item.ownContainer,
        rateId: `${item.rateId}`,
        planId: `${item.planId}`,
        name: item.number || '',
        seaworthyCertificate: this.convertDocument(item.seaworthyCertificate),
        isInDraft: false,
        isVirtual: false,
        estimatedVolume: 0,
        estimatedWeight: 0,
      });
      if (!parsedItem.isValid()) {
        console.error('Data from API does not match with contract');
      }
      return parsedItem;
    }).filter((container) => !!container) as ContainerDTM[];

    return containers;
  }

  private convertDocument = (document: IDocumentDTM | null) => {
    if (!document) {
      return null;
    }

    return ContainerDocumentDTM.fromPlain({
      uid: document.id.toString(),
      name: document.name,
      status: 'done',
      response: document,
      url: '/',
    });
  }

  private convertFromDocumentResponse = (document?: IDocumentDTM) => (
    document ? ([MSDSDocumentDTM.fromPlain({
      uid: document.id.toString(),
      name: document.name,
      status: 'done',
      response: document,
      url: '/',
    })]) : []
  );

  private convertFromGetCargosResponse = (cargos: GetFullShipmentResponse['cargos']): CargoDTM[] => cargos.map((cargo) => {
    const {
      id,
      code = '',
      name = '',
      description = '',
      value,
      packageType,
      packagesNumber,
      weight,
      volume,
      marks = '',
      loadSummary,
    } = cargo;
    const { references = [], hazmat } = cargo;

    const baseFields = CargoBaseDTM.fromPlain({
      code,
      name,
      description: description || '',
      packageType,
      marks: marks || '',
      loadSummary,
      references: references.length ? references.map((reference) => ({
        ...reference,
        id: uuidv4(),
      })) : [{ id: uuidv4() }],
      packagesNumber: packagesNumber ? packagesNumber.toString() : '',
      value: value ? value.toString() : '',
      weight: weight ? weight.toString() : '',
      volume: volume ? volume.toString() : '',
      unNumber: hazmat?.unNumber || '',
      imoClass: hazmat?.imoClass,
      shippingName: hazmat?.shippingName || '',
      packingGroup: hazmat?.packingGroup,
      msdsDocument: this.convertFromDocumentResponse(hazmat?.msdsDocument),
      contactName: hazmat?.emergencyContact?.name || '',
      contactNumber: hazmat?.emergencyContact?.phone || '',
    });

    const msdsStatus = (
      baseFields.msdsDocument
      && !!baseFields.msdsDocument.length
      && baseFields.msdsDocument[0].status === 'done'
      && !!baseFields.msdsDocument[0].response.id
      && !!baseFields.msdsDocument[0].response.name
      && !!baseFields.msdsDocument[0].response.type
    );

    const isHazmat = (
      msdsStatus
      || !!baseFields.imoClass
      || !!baseFields.unNumber
      || !!baseFields.packingGroup
      || !!baseFields.shippingName
      || !!baseFields.contactName
      || !!baseFields.contactNumber
    );

    return CargoDTM.fromPlain({
      renderId: `${id}`,
      touchedFields: {},
      errors: {},
      initialState: { ...baseFields },
      wasUpdateAttempted: false,
      hsCodeValidationStatus: code ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT',
      isHazmatCollapseOpen: isHazmat,
      isHazmat,
      id,
      ...baseFields,
    });
  });

  private addressToString = (city: string, countryCode?: string, stateName?: string) => {
    let originAddress = city;
    if (stateName) {
      originAddress += `, ${stateName}`;
    }
    if (countryCode) {
      originAddress += `, ${countryCode}`;
    }

    return originAddress;
  }

  private getShiftedDate = (date?: string | null): DateDtm | undefined => (
    date
      ? DateDtm.fromPlain({
        date,
        offset: moment.parseZone(date).utcOffset(),
      })
      : undefined)

  private getLocation = (city?: string, country?: string) => `${city || ''}${(city && country) ? `, ${country}` : (country || '')}`
}
